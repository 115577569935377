<template>
    <article>
        <v-tooltip bottom>
            <template #activator="{ on, attrs }">
                <article class="status-chip" dense :style="chipStyle" v-bind="attrs" v-on="on">
                    <span
                        :style="{
                            fontSize: isBig ? '12px' : '12px',
                        }"
                        :cy-caseStatus="state.value"
                        class="status-text"
                    >
                        {{ resize ? getName(state.value) : getName(state.value).slice(0, 1) }}
                    </span>
                </article>
            </template>
            <span>{{ getName(state.value) }}</span>
        </v-tooltip>
    </article>
</template>
<script>
    import { mapState } from 'vuex';
    import { filterObjectsByKeys, getActiveFromSettings } from '@/utils/index';
    import { statusTypes } from '@/templates/cases.template';

    export default {
        props: {
            state: {
                type: Object,
                default: () => ({}),
            },

            resize: {
                type: Boolean,
                default: false,
            },

            small: {
                type: Boolean,
                default: false,
            },

            rounded: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                variable: false,
                colors: this.$vuetify.theme.themes.light,
            };
        },

        computed: {
            ...mapState({
                statusTypesConfig: (state) => state.System.userSettings.system.statuses,
            }),
            statusTypes() {
                const formattedConfig = getActiveFromSettings(this.statusTypesConfig);
                return filterObjectsByKeys(formattedConfig, statusTypes, 'value');
            },
            getWidth() {
                if (this.rounded) {
                    return '26px';
                }
                return '100%';
            },
            isBig() {
                if (this.small) {
                    return false;
                }
                return this.listLayout.value === 'big';
            },
            listLayout() {
                return this.$store.state.Cases.listLayout;
            },

            chipStyle() {
                return {
                    height: '26px',

                    backgroundColor: this.getColor(this.state.value),
                };
            },
        },
        watch: {
            selectedMenuItem(input) {
                this.$emit('input', input);
            },
        },

        methods: {
            getColor(state) {
                const type = statusTypes.find((t) => t.value === state)?.color;
                if (this.state.value === 'New') {
                    return this.colors.primary;
                }
                const snoozeColor = statusTypes.find((t) => t.value === 'Snoozed')?.color;
                if (this.state.value === 'JustSnoozed' && snoozeColor) {
                    return snoozeColor;
                }
                if (state === undefined || state === '') {
                    return 'grey';
                }
                if (type === undefined) {
                    return 'grey';
                }
                return type;
            },

            getName(state) {
                if (this.state.value === 'JustSnoozed') {
                    return this.$t('global.recentlySnoozed') + '!';
                }
                if (this.state.value === 'New') {
                    return this.$t('global.new') + '!';
                }
                if (state === undefined || state === '') {
                    return '';
                }
                const type = this.statusTypes.find((t) => t.value === state);
                if (type?.singularTranslate) {
                    return type.singularTranslate[this.$i18n.locale];
                }
                if (type?.nameTranslate) {
                    return type.nameTranslate[this.$i18n.locale];
                }
                if (type === undefined) {
                    return this.$t('global.unknown');
                }
                return type;
            },
        },
    };
</script>
<style scoped>
    @media (max-width: 820px) {
        .status-chip {
            padding: 16px 10px;
            max-width: 90%;
            width: auto;
        }
        .status-text {
            white-space: nowrap;
        }
    }

    :deep(.removeWidth .v-chip__content) {
        max-width: unset !important;
    }

    .chip-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
    }

    .status-chip {
        color: white;
        overflow: hidden;
        align-items: center;
        border-radius: 36px;
        display: flex;
        justify-content: center;
        padding: 16px;
        width: auto;
    }

    .status-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
