<template>
    <CommonAvatar :userId="userId" :size="24" />
</template>
<script lang="ts">
    import { defineComponent } from 'vue';

    import CommonAvatar from '@/components/Global/CommonAvatar.vue';
    export default defineComponent({
        components: {
            CommonAvatar,
        },
        props: {
            userId: {
                type: Number,
                required: true,
            },
        },
    });
</script>
