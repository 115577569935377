<template>
    <label class="radio-btn-container">
        <input
            type="radio"
            name="mode"
            :value="value"
            :checked="isChecked"
            v-bind="$attrs"
            @change="$emit('input', radioValue)"
        />
        <div class="selector-text">
            <span class="title">{{ title }}</span>
            <span class="sub-title">{{ subTitle }}</span>
        </div>
    </label>
</template>
<script lang="ts">
    import SelectorText from './SelectorText.vue';
    import { defineComponent, PropType } from 'vue';

    export default defineComponent({
        inheritAttrs: false,
        components: {
            SelectorText,
        },
        props: {
            value: {
                type: Number,
                default: null,
            },
            radioValue: {
                type: Number,
                required: true,
            },
            title: {
                type: String,
                required: true,
            },
            subTitle: {
                type: String,
                default: '',
            },
        },
        data() {
            return {};
        },
        computed: {
            isChecked(): boolean {
                return this.value === this.radioValue;
            },
        },
    });
</script>
<style scoped lang="scss">
    label.radio-btn-container {
        display: flex;
        padding: 16px;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        text-transform: inherit;

        border-radius: var(--Corner-Radius-Corner-Radius-Medium, 8px);
        border: 1px solid var(--Color-Neutrals-60, #bfbfbf);

        &:hover:not(:has(input[type='radio']:checked)) {
            border-radius: var(--Corner-Radius-Corner-Radius-Medium, 8px);
            border: 1px solid var(--Color-Neutrals-60, #bfbfbf);
            background: var(--Color-Neutrals-80, #f2f2f2);
        }

        &:has(input[type='radio']:checked) {
            border-radius: var(--Corner-Radius-Corner-Radius-Medium, 8px);
            border: 1px solid var(--Color-Primary-Base, #00c4ad);
        }

        div.selector-text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 2px;
            flex: 1 0 0;

            .title {
                color: var(--Color-Neutrals-0, #000);

                /* Body/Body M 400 */
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
            }

            .sub-title {
                color: var(--Color-Neutrals-10, #3f3f3f);

                /* Body/Body S 400 */
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 142.857% */
            }
        }
    }
</style>
<i18n lang="json">
{
    "en": {},
    "sv": {}
}
</i18n>
