<template>
    <section class="bank-id-container">
        <div>
            <v-menu :disabled="!isActive" offset-y :close-on-content-click="true" transition="scale-transition">
                <template #activator="{ on, attrs }">
                    <div v-on="on" v-bind="attrs">
                        <secondary-action-button
                            :dense="true"
                            id="bankid-button"
                            @click="getIdentityProvidersByContext"
                            :disabled="!isActive"
                            class="bank-verication-btn"
                        >
                            <div class="bank-id-button-inner-container">
                                <img :width="btnSize * 0.9" :src="bankIdLogo" :style="bankIdStyle" />
                                <v-icon v-if="isAuthenticated" size="24" color="var(--v-success-base)">
                                    mdi-account-check-outline
                                </v-icon>
                            </div>
                        </secondary-action-button>
                    </div>
                </template>
                <v-list
                    style="position: static"
                    v-if="identityProviderInstances.length > 1 && selectedInstanceId === null"
                >
                    <v-list-item
                        v-for="item of identityProviderInstances"
                        class="settings-list-item"
                        @click="selectedInstanceId = item.id"
                    >
                        {{ item.name === null ? $t('bankIdButton.instance') : item.name }}
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-dialog v-model="dialog" width="50%" max-width="500px">
                <v-card>
                    <v-card-text v-if="!data" class="d-flex flex-column align-center">
                        <div>
                            <v-img width="300" src="@/assets/General/BankID_logo.svg" />
                        </div>
                        <div v-if="!loading" class="d-flex">
                            <v-text-field
                                v-model="pnr"
                                :disabled="loading"
                                :rules="ssnRules"
                                :label="$t('bankIdButton.ssn')"
                                placeholder="YYYYMMDDXXXX"
                                dense
                                @keyup.enter="() => isValidSwedishSSN(pnr) && bankIdAuthenticate()"
                            >
                                <template #append>
                                    <v-tooltip top>
                                        <template #activator="{ on, attrs }">
                                            <v-btn
                                                :disabled="!isValidSwedishSSN(pnr)"
                                                :color="bankIdColor"
                                                v-bind="attrs"
                                                icon
                                                v-on="on"
                                                @click="bankIdAuthenticate"
                                            >
                                                <v-icon>mdi-account-check-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        {{ $t('bankIdButton.verify') }}
                                    </v-tooltip>
                                </template>
                            </v-text-field>
                        </div>
                        <div v-else>
                            <v-progress-linear indeterminate :color="bankIdColor" />
                            <div class="text-caption">{{ $t('bankIdButton.verCustomer') }}</div>
                        </div>
                    </v-card-text>
                    <v-card-text v-else class="text-center">
                        <v-icon size="200" :color="bankIdColor">mdi-check-circle-outline</v-icon>
                        <v-sheet class="text-h5">{{ $t('bankIdButton.verSuccess') }}</v-sheet>
                        <div>{{ $t('global.name') }}: {{ data.user.name }}</div>
                        <div class="text-caption">{{ $t('bankIdButton.ssn') }}: {{ data.user.personalNumber }}</div>
                        <div class="text-caption">{{ $t('global.time') }}: {{ data.dateTime }}</div>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-menu :close-on-content-click="false">
                <template #activator="{ on }">
                    <section v-on="on"></section>
                </template>
            </v-menu>
        </div>
    </section>
</template>

<script>
    import { mapActions } from 'vuex';
    import { isValidSwedishSSN } from '@/utils/SocialSecurityValidationSwedish';
    import bankIdLogo from '@/assets/General/BankID_logo.svg';
    import IdentityProvider from '../Admin/QueueManager/IdentityProvider.vue';
    import integration from '@/api/integrations/integration';
    import queueManager from '@/api/queueManager/queueManager';

    export default {
        components: { IdentityProvider },
        props: {
            ssn: {
                type: String,
                default: '',
            },
            caseId: {
                type: String,
                default: '',
            },
            clientId: {
                type: String,
                default: '',
            },
            btnSize: {
                type: String,
                default: '50',
            },
            queueId: {
                type: Number,
                default: null,
            },
        },
        data() {
            return {
                pnr: '',
                bankIdColor: '#283d4d',
                dialog: false,
                loading: false,
                data: null,
                isActive: true,
                ssnRules: [(v) => isValidSwedishSSN(v) || this.$t('bankIdButton.ssnNotValid')],
                isAuthenticated: false,
                bankIdLogo,
                selectedInstanceId: null,
                identityProviderInstances: [],
            };
        },

        computed: {
            bankIdStyle() {
                return {
                    filter: this.isActive ? 'none' : 'grayscale(100%)',
                    opacity: this.isActive ? '1' : '0.5',
                };
            },
        },

        watch: {
            ssn: {
                immediate: true,
                handler(val) {
                    this.pnr = val;
                },
            },

            selectedInstanceId: {
                handler(val) {
                    if (val) {
                        this.dialog = true;
                    }
                },
            },
        },

        created() {
            this.checkActiveState();
        },

        methods: {
            ...mapActions('Integrations', {
                storeIsBankIdActive: 'isBankIdActive',
                storeBankIdAuthenticate: 'bankIdAuthenticate',
            }),

            async getIdentityProvidersByContext() {
                // if we dont null it it keeps its value between changing subcases
                // can be done in multiple ways, but this is the most simple way
                this.selectedInstanceId = null;

                if (this.queueId) {
                    const { data } = await queueManager.getQueueIdentityProvider(this.queueId);
                    this.selectedInstanceId = data.IdentityProvider;
                }

                if (!this.selectedInstanceId) {
                    const bankIdInstances = await integration.getIdentityProviderInstances('AUTHENTICATION');
                    this.identityProviderInstances = bankIdInstances.data;
                }

                if (this.identityProviderInstances.length === 1 && !this.selectedInstanceId) {
                    this.selectedInstanceId = this.identityProviderInstances[0].id;
                }
            },

            isValidSwedishSSN,

            async checkActiveState() {
                this.isActive = await this.storeIsBankIdActive();
            },

            async bankIdAuthenticate() {
                if (!isValidSwedishSSN(this.pnr)) {
                    this.$toasted.show(this.$t('bankIdButton.ssnNotValid'), { type: 'error' });
                    return;
                }

                this.loading = true;

                try {
                    const result = await this.storeBankIdAuthenticate({
                        ssn: this.pnr,
                        caseId: this.caseId,
                        clientId: this.clientId,
                        instanceId: this.selectedInstanceId,
                    });
                    this.data = result;

                    this.isAuthenticated = true;
                } catch (error) {
                    // more vague error message, cant show the error message from bankid due to axiosClient middleware currently modifies the error message
                    this.$toasted.show(this.$t('bankIdButton.somethingWentWrong'), {
                        type: 'error',
                    });
                } finally {
                    this.loading = false;
                }
            },
        },
    };
</script>

<style scoped>
    .v-menu__content {
        box-shadow:
            rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
            rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
        padding: 0px !important;
    }

    .bank-id-container {
        display: flex;
        flex: 1;
        align-items: stretch;
    }

    .settings-list-item {
        height: 48px;
        width: 200px;
    }

    .bank-id-button-inner-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 4px;
    }
</style>
<i18n lang="json">
{
    "en": {
        "bankIdButton": {
            "bankID": "BankID",
            "verCustomer": "Waiting for confirmation...",
            "verSuccess": "Customer authentication succeeded!",
            "ssn": "Social security number",
            "ssnNotValid": "Social security number not valid",
            "errMess": "Something went wrong. BankID response",
            "somethingWentWrong": "Something went wrong, try again in a few minutes!",
            "verify": "Verify",
            "instance": "Instance 1"
        }
    },
    "sv": {
        "bankIdButton": {
            "bankID": "BankID",
            "verCustomer": "Väntar på bekräftelse...",
            "verSuccess": "Kundautentisering lyckades!",
            "ssn": "Personnummer",
            "ssnNotValid": "Personnumret är inte giltigt",
            "errMess": "Något gick fel, BankID svar",
            "somethingWentWrong": "Något gick fel, testa igen om några minuter!",
            "verify": "Verifiera",
            "instance": "Instans 1"
        }
    }
}
</i18n>
