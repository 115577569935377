<template>
    <article
        v-if="item.valid"
        ref="case-item"
        class="flex-row mb-3 d-flex case softShadow invertedShadow"
        :class="preventSelect && 'disabled-overlay'"
        :style="{
            backgroundColor: computedBackgroundColor,
            maxHeight: itemHeight,
        }"
        @click="(e) => goToCase(item, e)"
        @mouseover="mouseOver = true"
        @mouseleave="mouseOver = false"
    >
        <ConfirmationDialog
            v-model="confirmDialog"
            :dialogStatus="confirmModal"
            :title="$t('listView.assign')"
            :body="confirmBody"
            @ConfirmResult="(v) => (v ? addCaseToYouAccept() : (confirmModal = false))"
        />
        <ActivityLine :item="item" />
        <Checkmark :show="showSelectableIcon" :checked="isChecked" disabled />
        <div class="header-grid" :style="headerStyle">
            <div v-for="(column, index) in listHeaders" :id="column.id" :key="index" class="list-grid-item">
                <InternalType
                    v-if="column.id === 'internaltype'"
                    :absolute="true"
                    :item="item"
                    :position="getListItemPosition(index)"
                />
                <TextBox
                    v-else-if="
                        column.id === 'created' ||
                        column.id === 'datetime' ||
                        column.id === 'genericdatetime1' ||
                        column.id === 'genericdatetime2' ||
                        column.id === 'lastUpdated' ||
                        column.id === 'lastIncoming'
                    "
                    :item="item"
                    :header="$t(column.textTranslate[$i18n.locale])"
                    :value="
                        getDateFormat(item[column.value], 'date') + ', ' + getDateFormat(item[column.value], 'time')
                    "
                    :position="getListItemPosition(index)"
                />

                <SLA v-else-if="column.id === 'sla'" :item="item" />

                <ListBrandLogo v-else-if="column.id === 'brand'" :item="item" :position="getListItemPosition(index)" />
                <QueueChip v-else-if="column.id === 'group'" :item="item" :position="getListItemPosition(index)" />

                <ListCategory v-else-if="column.id === 'category'" :item="item" />

                <TextBox
                    v-else-if="['Topic', 'Ämne'].includes(column.textTranslate[$i18n.locale])"
                    :value="item[column.value] ? item[column.value] : $t('listView.noTopic')"
                    :item="item"
                    :position="getListItemPosition(index)"
                />

                <Attachment
                    v-else-if="column.id === 'attachment'"
                    :item="item"
                    :position="getListItemPosition(index)"
                />

                <Flag v-else-if="column.id === 'flag'" :item="item" :position="getListItemPosition(index)" />

                <ProfilePicture
                    v-else-if="column.id === 'profilepicture'"
                    :key="confirmModal"
                    :item="item"
                    :position="getListItemPosition(index)"
                    @addCaseToYou="addCaseToYou(item)"
                />
                <TextBox
                    v-else-if="column.id === 'toYou'"
                    :backgroundClr="colors.color1"
                    :value="item.userId == loggedInUser.userId ? $t('listView.forYou') : ' '"
                    :item="item"
                    :position="getListItemPosition(index)"
                />
                <Status v-else-if="column.id === 'status'" :item="item" :position="getListItemPosition(index)" />
                <Tries v-else-if="column.id === 'tries'" :item="item" :position="getListItemPosition(index)" />

                <TextBox
                    v-else-if="column.id === 'result'"
                    :backgroundClr="colors.color1"
                    :header="column.textTranslate[$i18n.locale]"
                    :value="item[column.value] || $t('listView.noOutcome')"
                    :item="item"
                    :position="getListItemPosition(index)"
                />
                <TextBox
                    v-else-if="column.id === 'text'"
                    :backgroundClr="colors.color1"
                    :value="item[column.value] || ' '"
                    :item="item"
                    :position="getListItemPosition(index)"
                />

                <Client
                    v-else-if="column.id === 'client' && !callbackActive"
                    :header="item.contactName"
                    :value="item.clientName || item.clientId"
                    :item="item"
                    :position="getListItemPosition(index)"
                />

                <TextBox
                    v-else-if="(column.id === 'client') & callbackActive"
                    :header="item.ClientPersonNr ? $t('listView.ssn') : $t('listView.phoneNr')"
                    :value="item.ClientPersonNr"
                    :item="item"
                    :position="getListItemPosition(index)"
                />

                <SubCasesCount
                    v-else-if="column.id === 'subCasesCount'"
                    :header="column.textTranslate[$i18n.locale]"
                    :value="item.subCasesCount"
                    :item="item"
                    :position="getListItemPosition(index)"
                />

                <TextBox
                    v-else
                    :header="column.textTranslate[$i18n.locale]"
                    :value="item[column.value]"
                    :item="item"
                    :position="getListItemPosition(index)"
                />
            </div>
        </div>
    </article>
    <article
        v-else
        class="flex-row mb-3 d-flex case not-valid"
        :style="{
            backgroundColor: computedBackgroundColor,
            maxHeight: itemHeight,
        }"
    >
        <span class="error-chip">
            {{ $t('listView.unableToProcess') }}
        </span>
        <span class="error-chip-case-id">{{ item.caseId }}</span>
    </article>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import { getDateFormat } from '@/utils/Design';
    import ActivityLine from '@/components/Cases/List/ActivityLine.vue';
    import ConfirmationDialog from '@/components/Global/ConfirmationDialog.vue';
    import Checkmark from '@/components/Global/Checkmark.vue';
    import InternalType from '@/components/Cases/List/InternalType.vue';
    import TextBox from '@/components/Cases/List/TextBox.vue';
    import SLA from '@/components/Cases/List/SLA.vue';
    import QueueChip from '@/components/Cases/List/QueueChip.vue';
    import ListBrandLogo from '@/components/Cases/List/ListBrandLogo.vue';
    import ListCategory from '@/components/Cases/List/Category/ListCategory.vue';
    import Attachment from '@/components/Cases/List/Attachment.vue';
    import ProfilePicture from '@/components/Cases/List/ProfilePicture.vue';
    import Status from '@/components/Cases/List/Status.vue';
    import Tries from '@/components/Cases/List/Tries.vue';
    import Flag from '@/components/Cases/List/Flag.vue';
    import Client from '@/components/Cases/List/Client.vue';
    import SubCasesCount from '@/components/Cases/List/SubCasesCount.vue';
    import { positionTypes, caseStatuses } from '@/enums/cases.enums';
    import { channelTypes } from '@/constants/enums/queues.enums';
    import { httpStatusCode } from '@/enums/response.enums';

    export default {
        components: {
            ActivityLine,
            ConfirmationDialog,
            Checkmark,
            InternalType,
            TextBox,
            SLA,
            QueueChip,
            ListBrandLogo,
            ListCategory,
            Attachment,
            ProfilePicture,
            Status,
            Tries,
            Flag,
            Client,
            SubCasesCount,
        },

        props: {
            item: {
                type: Object,
                default: () => {},
            },
            index: {
                type: Number,
                default: 0,
            },
        },
        data() {
            return {
                channelTypes,
                colors: this.$vuetify.theme.themes.light,
                caseToAdd: null,
                confirmModal: false,
                confirmDialog: false,
                confirmBody: this.$t('listView.assignCase'),
                mouseOver: false,
                positionTypes,
            };
        },

        computed: {
            ...mapState({
                listAddAnimation: (state) => state.Cases.listAddAnimation,
                selectedCases: (state) => state.Cases.selectedCases,
                showSelect: (state) => state.Cases.showSelect,
                selectedCaseId: (state) => state.Cases.selectedCaseId,
                cases: (state) => state.Cases.cases,
                totalCases: (state) => state.Cases.totalCases,
                listLayout: (state) => state.Cases.listLayout,
                loggedInUser: (state) => state.Auth.userObject,
                dateNow: (state) => state.Cases.dateNow,
                animationGrid: (state) => state.Cases.animationGrid,
                headersConfig: (state) => state.System.userSettings.cases.headers,
                callbackActive: (state) => state.System.userSettings.cases.callbackView.active,
                listHeaders: (state) => state.Cases.listHeaders.headers,
            }),
            ...mapGetters('Cases', ['isAllowedToAssignCase']),

            showSelectableIcon() {
                if (this.item.status === caseStatuses.IN_QUEUE) {
                    return this.showSelect === 'category';
                }
                return Boolean(this.showSelect);
            },

            preventSelect() {
                if (this.showSelect) {
                    if (this.showSelect === 'category') {
                        return false;
                    }
                    return this.item.status === caseStatuses.IN_QUEUE;
                }
                return false;
            },

            headerStyle() {
                // Calculating the total length of all items in the list and creating fractions of them to use in the grid
                // This is used to make the grid responsive

                const totalWidth = this.listHeaders.reduce((acc, item) => acc + Number.parseInt(item.width), 0);

                const columnFractions = this.listHeaders.map((item) => `${Number.parseInt(item.width) / totalWidth}fr`);

                return {
                    'grid-template-columns': columnFractions.join(' '),
                };
            },

            isChecked: {
                get() {
                    return this.selectedCases.includes(this.item.caseId);
                },
                set(value) {
                    this.$store.dispatch('Cases/setCaseCheckbox', { isChecked: value, item: this.item });
                },
            },

            computedBackgroundColor() {
                if (this.isChecked) {
                    return this.showSelect ? 'white !important' : 'var(--v-gray5-base) !important';
                }
                if (this.showSelect) {
                    return this.mouseOver ? 'white !important' : 'var(--v-gray5-base) !important';
                }
                return 'white';
            },

            animateState() {
                const motion = this.animationGrid[this.index];
                if (motion === undefined) {
                    return { from: this.index, to: this.index };
                }
                return motion;
            },

            itemHeight() {
                switch (this.listLayout.value) {
                    case 'big': {
                        return '80px';
                    }
                    case 'small': {
                        return '50px';
                    }
                    default: {
                        return '80px';
                    }
                }
            },
        },

        watch: {
            showSelect(newVal) {
                if (newVal !== 'category' && this.item.status === caseStatuses.IN_QUEUE) {
                    this.isChecked = false;
                }
            },
        },
        methods: {
            getDateFormat,
            ...mapActions({
                storeGoToCase: 'Cases/goToCase',
            }),

            addCaseToYou(item) {
                let canAssignCase = this.isAllowedToAssignCase(item);

                if (this.showSelect) {
                    this.isChecked = !this.isChecked;
                    canAssignCase = false;
                }

                const handledLaterAssigns = ['callInQueue', 'chatInQueue'];
                // the "callInQueue" added here since that is handled after the divertConversation function
                if (canAssignCase !== true && !handledLaterAssigns.includes(canAssignCase)) {
                    this.showNotAllowedToast(canAssignCase);
                    return;
                }

                this.confirmDialog = true;
                this.caseToAdd = item;
            },

            // *** Should be in the store ***
            showNotAllowedToast(reason) {
                let message = this.$t('listView.notAllowedToAssign');
                switch (reason) {
                    case 'automaticCallback': {
                        message = this.$t('listView.canNotAssignAutomaticCallback');
                        break;
                    }

                    default: {
                        message = this.$t('listView.notAllowedToAssign');
                    }
                }
                this.$toasted.show(message, {
                    icon: 'alert-circle',
                    type: 'warning',
                    duration: 5000,
                });
            },

            async addCaseToYouAccept() {
                // this should be moved to the store and be a mutation
                const id = this.caseToAdd.caseId;
                const allCases = this.cases;
                const caseToAdd = allCases.find((item) => item.caseId === id);

                if (!caseToAdd) {
                    console.error('Could not find case to add to you');
                    this.$toasted.show(this.$t('listView.couldNotAdd'), {
                        icon: 'alert-circle',
                        type: 'warning',
                        duration: 5000,
                    });
                    return;
                }

                const divertConversationPayload = {
                    userId: this.loggedInUser.userId,
                    caseId: id,
                    internalType: caseToAdd.internalType,
                };

                try {
                    //* Divert conversation AKA cherryPicking straight into queueManager
                    const distributedTypes = new Set([
                        this.channelTypes.CALL,
                        this.channelTypes.CHAT,
                        this.channelTypes.EMAIL,
                        this.channelTypes.SMS,
                    ]);
                    if (distributedTypes.has(this.caseToAdd.internalType) && caseToAdd.status === 'InQueue') {
                        const res = await this.$store.dispatch('Cases/divertConversation', divertConversationPayload);
                        if (res.status === httpStatusCode.FORBIDDEN) {
                            this.$toasted.show(this.$t('listView.cantCherrypickAutoDivert'), {
                                icon: 'alert-circle',
                                type: 'warning',
                                duration: 5000,
                            });
                            return;
                        }

                        // if the divertConversation fails, we don't want to add the case to the user
                        if (!res) {
                            throw new Error('Could not divert conversation');
                        }
                    }

                    const payload = {
                        caseId: id,
                        changed: {
                            userId: this.loggedInUser.userId,
                        },
                    };

                    const item = {
                        Name: this.loggedInUser.userName,
                    };

                    await this.$store.dispatch('Cases/updateCase', { payload, item });
                    if (this.caseToAdd.caseId === this.selectedCaseId) {
                        await this.$store.dispatch('Cases/resetUnreadMessages', this.selectedCaseId);
                    } else {
                        await this.goToCase(this.caseToAdd);
                    }
                } catch (error) {
                    console.error('Could not assign case to you', error);
                    this.$toasted.show(this.$t('listView.couldNotOpen'), {
                        icon: 'alert-circle',
                        type: 'warning',
                        duration: 5000,
                    });
                } finally {
                    this.confirmModal = false;
                    this.caseToAdd = null;
                }
            },

            returnDateNow() {
                return this.dateNow;
            },

            goToCase(payload) {
                if (this.showSelect) {
                    this.isChecked = !this.isChecked;
                    return;
                }
                this.storeGoToCase(payload);
            },

            getListItemPosition(index) {
                if (index === this.listHeaders.length - 1) {
                    return positionTypes.RIGHT;
                }
                return positionTypes.LEFT;
            },
        },
    };
</script>

<style scoped>
    @media (max-width: 820px) {
        .header-grid {
            gap: 8px;
        }
    }

    .error-chip {
        user-select: none;
        color: var(--v-gray2-base);
    }

    .error-chip-case-id {
        user-select: none;
        color: var(--v-gray4-base);
    }
    .not-valid {
        background-color: transparent !important;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--v-gray2-base);
        user-select: none;
        border: 1px solid rgb(0, 0, 0, 0.15);
        gap: 4px;
    }
    .header-grid {
        display: grid;
        grid-template-rows: 1fr;
        width: 100%;
        height: 100%;
        gap: 16px;
        padding: 0px 16px;
    }

    .list-grid-item {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: stretch;
        flex: 1;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .list-grid-item > * {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        flex: 1;
    }

    .list-grid-item:first-child {
        padding-left: 16px;
    }

    .list-grid-item:last-child {
        justify-content: end;
        padding-right: 16px;
    }

    .case:hover {
        background-color: var(--v-gray5-base) !important;
    }

    .case {
        width: 100%;
        border-radius: 6px;
        height: 80px;
        background-color: white;
        cursor: pointer;
        position: relative;
        margin-bottom: 4px;
        background-color: white;
        transition: background-color 0.1s ease-in-out;
        overflow: hidden;
    }

    .list-grid-item:last-child {
        justify-self: end;
    }

    [v-cloak] {
        display: none;
    }
</style>

<i18n lang="json">
{
    "en": {
        "listView": {
            "noCasesTitle": "There are currently no cases to display with the current filters.",
            "noCasesText": "Please go up to the left to change the filters to see if you can find the case you are looking for.",
            "assign": "Assign",
            "recieved": "Recieved",
            "dateTime": "Date / Time",
            "forYou": "For You",
            "noOutcome": "No outcome",
            "assignCase": "Do you want to assign the case to yourself and open it?",
            "couldNotOpen": "Could not open case",
            "updated": "Updated",
            "inQueue": "The case is in the queue waiting to be picked up by automatic assignment",
            "phoneNr": "Phone number",
            "ssn": "Social security number",
            "waitUntilCaseIsLoaded": "Wait until case is loaded before opening another case",
            "unableToProcess": "Failed to fetch case.",
            "couldNotAdd": "Could not add case to you",
            "cantCherrypickAutoDivert": "You can't cherry pick a case that is in the queue, it will be automatically diverted to you",
            "canNotOpenAutomaticCallbackWhileInQueue": "This is an automatic callback and it can not be cherry picked",
            "canNotAssignAutomaticCallback": "This is an automatic callback and it can not be assigned while in queue",
            "notAllowedToAssign": "You are not allowed to assign this case",
            "caseInQueue": "The case is in queue and can not be assigned",
            "enterCaseConfirmationTitle": "Enter case?",
            "enterCaseConfirmationBody": "Do you want to enter the case? Since you are assigned this case this action will put current agent {agentName} in read only state.",
            "noTopic": "No topic"
        }
    },
    "sv": {
        "listView": {
            "noCasesTitle": "Det finns för närvarande inga ärenden att visa med de aktuella filtren.",
            "noCasesText": "Gå upp till vänster för att ändra filtren för att se om du kan hitta ärendet du letar efter.",
            "assign": "Tilldela",
            "recieved": "Inkommet",
            "dateTime": "Datum / Tid",
            "forYou": "Till dig",
            "noOutcome": "Inget utfall",
            "assignCase": "Vill du tilldela ärendet till dig och öppna det?",
            "couldNotOpen": "Kunde inte öppna ärende",
            "updated": "Uppdaterad",
            "inQueue": "Ärendet är i kön och väntar på att plockas upp av automatisk tilldelning",
            "ssn": "Personnummer",
            "phoneNr": "Telefonnummer",
            "waitUntilCaseIsLoaded": "Vänta tills ärendet är laddat innan du öppnar ett annat ärende",
            "unableToProcess": "Misslyckades med att hämta ärende.",
            "couldNotAdd": "Kunde inte lägga till ärende till dig",
            "cantCherrypickAutoDivert": "Du kan inte cherrypicka ett ärende som är i kön, det kommer automatiskt att dirigeras till dig",
            "canNotOpenAutomaticCallbackWhileInQueue": "Detta är en automatisk callback och den kan ej cherrypickas",
            "canNotAssignAutomaticCallback": "Detta är en automatisk callback och den kan ej tilldelas medan den är i kön",
            "notAllowedToAssign": "Du har inte behörighet att tilldela detta ärende",
            "caseInQueue": "Ärendet är i kön och kan inte tilldelas",
            "enterCaseConfirmationTitle": "Gå in i ärende?",
            "enterCaseConfirmationBody": "Vill du gå in i ärendet? Eftersom du är tilldelad detta ärende kommer denna åtgärd att sätta nuvarande agent {agentName} i läsläge.",
            "noTopic": "Inget ämne"
        }
    }
}
</i18n>
