<template>
    <section :class="timelineHeaderClass" class="header">
        <section class="unread-indicator">
            <UnreadMessagesIndicator v-if="hasUnreadMessages" />
        </section>
        <section class="icon">
            <i class="icon mdi" :class="iconClass"></i>
        </section>
        <section class="heading" :class="headingClass">
            <truncate>{{ item.topic || $t('timelineItemHeader.noTopic') }}</truncate>
        </section>
        <section class="header-date">
            <truncate>{{ formatDateToText(item.dateTime) }}</truncate>
        </section>
    </section>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import UnreadMessagesIndicator from '@/components/UnreadMessagesIndicator.vue';
    import { formatDateToText } from '@/utils/DateFormatter';
    import { filterObjectsByKeys, getActiveFromSettings } from '@/utils';
    import { channelTypes } from '@/templates/cases.template';

    interface Item {
        topic: string;
        dateTime: string;
        unreadMessages: number;
        internalType: string;
        internalSubType: string;
        source: string;
        caseId: string;
        subCasesCount: number;
        isChild: boolean;
        allowed: boolean;
    }

    export default defineComponent({
        name: 'TimelineItemHeader',

        components: {
            UnreadMessagesIndicator,
        },

        props: {
            item: {
                type: Object as PropType<Item>,
                required: true,
            },
            isChild: {
                type: Boolean,
                default: true,
            },
        },

        computed: {
            channelConfig(): object {
                // @ts-ignore <This is going to be converted to Pinia store in the future>
                return this.$store.state.System.userSettings.system.channels as object;
            },

            facebookMessengerIcon(): string | undefined {
                // @ts-ignore <This is going to be converted to Pinia store in the future>
                return this.$store.state.Social.social.facebookmessenger?.icon as string | undefined;
            },

            timelineHeaderClass() {
                return this.isChild ? 'header-child' : 'header-parent';
            },

            hasUnreadMessages() {
                return this.item.unreadMessages > 0;
            },

            iconClass() {
                return this.isChild ? 'mdi-source-branch' : this.caseTypeIcon;
            },

            headingClass() {
                return this.isChild ? 'heading-child' : 'heading-parent';
            },

            filteredChannelTypes() {
                const channelBooleans = getActiveFromSettings(this.channelConfig);
                return filterObjectsByKeys(channelBooleans, channelTypes, 'value') as { value: string; icon: string }[];
            },

            caseTypeIcon() {
                if (this.item.internalType === 'social') {
                    return this.badgeIcon;
                }

                const obj = this.filteredChannelTypes.find((t) => t.value === this.item.internalType)?.icon;

                return obj || 'mdi-help-circle';
            },

            badgeIcon(): string {
                if (
                    this.item.internalSubType === 'message' &&
                    this.item.internalType === 'social' &&
                    this.item.source === 'facebook'
                ) {
                    return this.facebookMessengerIcon as string;
                }
                return this.getChannelIcon(this.item.source) as string;
            },
        },

        methods: {
            formatDateToText,

            getChannelIcon(channel: string): string {
                // @ts-ignore <This is going to be converted to Pinia store in the future>
                return (this.$store.state.Social.social[channel]?.icon || 'mdi-help-circle') as string;
            },
        },
    });
</script>

<style scoped>
    .header {
        display: grid;
        grid-template-columns: 8px auto 1fr;
        gap: 4px;
    }

    .header-child {
        grid-template-rows: 20px;
        grid-template-areas: 'unread-indicator icon heading';
    }

    .header-child .header-date {
        display: none;
    }

    .header-parent {
        grid-template-rows: 20px 12px;
        grid-template-areas:
            'unread-indicator icon heading'
            '. header-date header-date';
    }

    .header > * {
        align-self: center;
    }

    .unread-indicator {
        grid-area: unread-indicator;
    }

    .icon {
        grid-area: icon;
    }

    .heading {
        grid-area: heading;
        font-size: 14px;
        color: var(--v-gray1-darken4);
    }

    .heading-child {
        font-weight: 400;
    }

    .heading-parent {
        font-weight: 700;
    }

    .header-date {
        grid-area: header-date;
        font-size: 12px;
        font-weight: 400;
        color: var(--v-gray1-darken4);
    }
</style>

<i18n>
    {
        "en": {
            "timelineItemHeader": {
                "noTopic": "No topic",
            }
        },

        "sv": {
            "timelineItemHeader": {
                "noTopic": "Inget ämne",
            }
        }
    }
</i18n>
