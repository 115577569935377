<template>
    <div>
        <div v-if="loadingState === 'loading'">
            <v-skeleton-loader
                v-for="n in 3"
                :key="n"
                type="list-item"
                :loading="loadingState === 'loading'"
            ></v-skeleton-loader>
        </div>
        <div v-else-if="loadingState === 'error'">{{ $t('errorLoadingAnimals') }}</div>
        <div v-else>
            <div v-if="!animals.length" class="pa-3">{{ $t('noAnimals') }}</div>
            <v-list-item-group>
                <I2iAnimalListItem v-for="(animal, index) in animals" :key="index" :animal="animal" :policy="policy" />
            </v-list-item-group>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import api from '@/api/integrations/integration';
    import I2iAnimalListItem from './I2iAnimalListItem.vue';
    import { Animal } from '@/types/integrations/i2i.types';

    const states = {
        LOADING: 'loading',
        LOADED: 'loaded',
        ERROR: 'error',
    };

    export default defineComponent({
        components: {
            I2iAnimalListItem,
        },
        data() {
            return {
                loadingState: states.LOADING,
                policy: null as any,
                animals: [] as Animal[],
                selectedAnimal: null as Animal | null,
            };
        },
        computed: {
            policyId(): string {
                // @ts-ignore
                return this.$store.state.Integrations.policyId;
            },
        },
        watch: {
            policyId: {
                handler(newVal) {
                    if (!newVal) {
                        this.animals = [];
                    }
                    if (newVal) {
                        this.getPolicy(newVal);
                    }
                },
                immediate: true,
            },
        },
        methods: {
            async getPolicy(policyId: string) {
                try {
                    if (!policyId) {
                        this.loadingState = states.LOADED;
                        return;
                    }
                    const { data } = await api.getI2IPolicy(policyId);
                    this.loadingState = states.LOADED;
                    if (!data) return;
                    this.policy = data;
                    console.log('🚀 ~ getPolicy ~ data:', data);
                    this.createAnimalList(data);
                } catch (error) {
                    console.error(error);
                    this.loadingState = states.ERROR;
                }
            },
            createAnimalList(data: any) {
                // Självrisk (api-namn?)
                // Maxersättning (api-namn?)
                // Tillägg (api.namn?)

                if (data.insured.members) {
                    data.insured.members.forEach((member: any) => {
                        this.animals.push({
                            name: member.animalName,
                            birth: member.birth,
                            gender: member.gender,
                            breed: member.breed,
                            insuredFrom: data.insuredFrom,
                            insuredTo: data.insuredTo,
                        });
                    });
                } else {
                    this.animals.push({
                        name: data.insured.animalName,
                        birth: data.insured.birth,
                        gender: data.insured.gender,
                        breed: data.insured.breed,
                        insuredFrom: data.insuredFrom,
                        insuredTo: data.insuredTo,
                    });
                }
            },
        },
    });
</script>

<i18n lang="json">
{
    "en": {
        "errorLoadingAnimals": "Error loading animals",
        "noAnimals": "No animals"
    },
    "sv": {
        "errorLoadingAnimals": "Fel vid inläsning av djur",
        "noAnimals": "Inga djur"
    }
}
</i18n>
