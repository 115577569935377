<template>
    <article class="d-flex space-between case-id-gap flex-wrap">
        <v-tooltip bottom>
            <template #activator="{ on, attrs }">
                <v-chip
                    v-bind="attrs"
                    color="gray3"
                    class="grayText2 case-number"
                    v-on="on"
                    @click="copyDataToClipboard(selectedCase.case.caseId)"
                    cy-data="case-id-chip"
                >
                    {{ selectedCase.case.caseId }}
                </v-chip>
            </template>
            <span>{{ $t('caseComments.caseNumber') }}</span>
            <div>{{ $t('caseComments.clickToCopy') }}</div>
        </v-tooltip>
        <v-tooltip v-if="numericalCaseId" bottom>
            <template #activator="{ on, attrs }">
                <v-chip
                    v-bind="attrs"
                    color="gray3"
                    class="grayText2 case-number"
                    v-on="on"
                    @click="copyDataToClipboard(numericalCaseId)"
                    cy-data="numerical-case-id-chip"
                >
                    {{ numericalCaseId }}
                </v-chip>
            </template>
            <div>{{ $t('caseComments.numericalCaseId') }}</div>
            <div>{{ $t('caseComments.clickToCopy') }}</div>
        </v-tooltip>
    </article>
</template>
<script>
    import { mapState, mapGetters } from 'vuex';

    export default {
        computed: {
            numericalCaseId() {
                if (this.caseIdRelations.length === 0) {
                    return '';
                }

                const item = this.caseIdRelations.find((item) => {
                    return item.source === 'numericalcaseid';
                });

                return item?.externalId || '';
            },

            ...mapState({
                selectedCase: (state) => state.Cases.selectedCase,
            }),
            ...mapGetters({
                caseIdRelations: 'Cases/caseIdRelations',
            }),
        },
        methods: {
            async copyDataToClipboard(data) {
                if (!data) return;
                if (navigator.clipboard) {
                    try {
                        await navigator.clipboard.writeText(data);
                        this.$toasted.show(this.$t('caseComments.copiedToClipboard'), {
                            icon: 'check',
                            type: 'success',
                            duration: 2000,
                        });
                    } catch {
                        this.$toasted.show(this.$t('caseComments.failedToCopy'), {
                            icon: 'alert',
                            type: 'error',
                            duration: 2000,
                        });
                    }
                } else {
                    this.$toasted.show(this.$t('caseComments.copyNotSupported'), {
                        icon: 'alert',
                        type: 'error',
                        duration: 2000,
                    });
                }
            },
        },
    };
</script>
<style scoped>
    .case-number {
        cursor: pointer;
    }
    .case-id-gap {
        gap: 4px;
    }
</style>
<i18n lang="json">
{
    "en": {
        "caseComments": {
            "caseNumber": "Case number",
            "numericalCaseId": "Numerical case Id",
            "clickToCopy": "Click to copy",
            "copiedToClipboard": "Copied to clipboard",
            "failedToCopy": "Failed to copy",
            "copyNotSupported": "Copy is not supported on this device"
        }
    },
    "sv": {
        "caseComments": {
            "caseNumber": "Ärendenummer",
            "numericalCaseId": "Numeriskt ärendenummer",
            "clickToCopy": "Klicka för att kopiera",
            "copiedToClipboard": "Kopierat till urklipp",
            "failedToCopy": "Misslyckades med att kopiera",
            "copyNotSupported": "Kopiering stöds inte på denna enhet"
        }
    }
}
</i18n>
