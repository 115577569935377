<template>
    <section v-if="value > 0" class="sub-cases-icon-container" :class="positionClass">
        <i class="mdi mdi-source-branch sub-cases-icon"></i>
        {{ value }}
    </section>
</template>

<script lang="ts">
    import { positionTypes } from '@/enums/cases.enums';
    import { getPositionClass } from '@/helpers/cases/cases.helper';
    import { defineComponent, computed } from 'vue';

    export default defineComponent({
        name: 'SubCasesIcon',
        props: {
            value: {
                type: Number,
                default: 0,
            },
            header: {
                type: String,
                default: '',
            },
            item: {
                type: Object,
                default: () => ({}),
            },
            position: {
                type: String,
                default: positionTypes.LEFT,
                validator(value: (typeof positionTypes)[keyof typeof positionTypes]) {
                    return Object.values(positionTypes).includes(value);
                },
            },
        },
        setup(props) {
            const positionClass = computed(() => getPositionClass(props.position));

            return {
                positionClass,
            };
        },
    });
</script>

<style scoped>
    .sub-cases-icon-container {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    .sub-cases-icon {
        font-size: 16px;
    }
</style>
