import axios, { type AxiosResponse } from 'axios';
import { type IClientFields, clientFieldsSchema } from '../../types/client.fields.types';

export async function getClientFieldsList(useCache = true): Promise<AxiosResponse<IClientFields[]>> {
    const headers = useCache ? {} : { 'Cache-Control': 'no-cache' };
    const response = await axios.get<IClientFields[]>('/client/fields', {
        headers,
    });
    response.data = clientFieldsSchema.parse(response.data);
    return response;
}

export async function updateClientFieldsList(
    id: number,
    keys: Partial<{
        visible: IClientFields['visible'];
        editable: IClientFields['editable'];
        position: IClientFields['position'];
    }>,
): Promise<AxiosResponse<{ message?: string }>> {
    const response = await axios.put<{ message?: string }>(`/client/fields/${id}`, keys);
    return response;
}

export async function updateClientFieldsPosition(
    items: { id: number; position: number }[],
): Promise<AxiosResponse<{ message?: string }>> {
    const response = await axios.patch<{ message?: string }>('/client/fields/position', items);
    return response;
}
