import axios from 'axios';

export default {
    /* C1 STATISTICS  */
    getAllStatistics({ from, to, queues, user, categories, channels, format }) {
        return axios.get(
            `/statistics?from=${from}&to=${to}&queues=${queues}&user=${user}&categories=${categories}&channels=${channels}&format=${format}`,
        );
    },

    /* CMOT STATISTICS  */
    getCMOTStatistics({ from, to, queueIds, timeFormat }) {
        return axios.get(`/statistics/cmot?from=${from}&to=${to}&queueIds=${queueIds}&timeFormat=${timeFormat}`);
    },

    /* Intervals  */
    getAllDateIntervals() {
        return axios.get('/statistics/intervals');
    },

    /* SWYX STATISTICS  */
    getActiveCalls() {
        return axios.get('/swyx/statistics/activecalls');
    },
    getTimelineStatistics({ type, groupId, page, search, excludeEmptyValues, date }) {
        return axios.get(
            `statistics/timeline?type=${type}&groupId=${groupId}&page=${page}&search=${search}&excludeEmptyValues=${excludeEmptyValues}&date=${date}`,
        );
    },

    aggregatedActivityStatistics({ type, id, from, to, dataType, format }) {
        return axios.get(
            `statistics/calls/presence/column?type=${type}&id=${id}&from=${from}&to=${to}&datatype=${dataType}&format=${format}`,
        );
    },

    getCallPerformanceStatistics({ id, type }) {
        return axios.get(`statistics/performance/calls?id=${id}&type=${type}`);
    },
    getBigTableCallPerformanceStatistics() {
        return axios.get('statistics/performance/calls/table');
    },
    callCDRStatistics({ from, to, channel, timeFormat, queueIds }) {
        return axios.get(
            `statistics/calls/cdr?from=${from}&to=${to}&channel=${channel}&format=${timeFormat}&queueIds=${queueIds}`,
        );
    },

    exportStatistics(payload) {
        return axios.post('statistics/export', payload);
    },

    getExportJobs() {
        return axios.get('statistics/export/jobs');
    },

    deleteJob(id) {
        return axios.delete(`statistics/export/jobs/${id}`);
    },

    getUserStatisticsByGroup(payload) {
        return axios.get('statistics/user/group', {
            params: payload,
        });
    },
    getUserGroupCompare({ from, to, userIds, groupId, dataType }) {
        return axios.get(
            `statistics/user/group/compare?from=${from}&to=${to}&groupId=${groupId}&userIds=${userIds}&type=${dataType}`,
        );
    },
    getClientsCallStatistics({ from, to, landingNumbers, name }) {
        return axios.get(
            `statistics/client/calls/?from=${from}&to=${to}&name=${name}&landingNumbers=${landingNumbers}`,
        );
    },
    getJobData(jobId) {
        return axios.get(`statistics/jobs/${jobId}/data`);
    },
    getC1QueueCallsStats({ groupId, queueId, from, to, format, period }) {
        return axios.get(
            `statistics/calls/group/${groupId}/queue/${queueId}?from=${from}&to=${to}&format=${format}&periodStart=${period.from}&periodEnd=${period.to}`,
        );
    },
    getUserC1LogIns(userId) {
        return axios.get(`statistics/timeline/c1queue/logins?userId=${userId}`);
    },

    getC1QueueServiceStatistics({ queueIds, channelType, from, to }) {
        return axios.get(
            `/statistics/service/queue?queueIds=${queueIds}&channelType=${channelType}&from=${from}&to=${to}`,
        );
    },
    getC1WorkingTimeStatistics(payload) {
        return axios.get(
            `/statistics/service/cases?type=${payload.type}&typeId=${payload.typeId}&compareType=${payload.compareType}&compareTypeIds=${payload.compareTypeIds}&channelType=${payload.channelType}&from=${payload.from}&to=${payload.to}&accuracyMs=${payload.accuracyMs}`,
        );
    },
    getQueuesAdvanced() {
        return axios.get('/queues?IsSystemQueue=1');
    },

    /** DYNAMIC EXPORTS */

    /**
     * Fetches a list of dynamic export statistics.
     */
    getDynamicExports() {
        return axios.get('/statistics/dynamic-export');
    },

    /**
     * Updates a dynamic export with the given `id` and fields to update.
     *
     * @param {Object} payload - The payload for the update.
     * @param {number} payload.id - The ID of the dynamic export to update (must be a valid number).
     * @param {Object} payload.body - The fields to update.
     * @param {string} [payload.body.name] - The new name for the dynamic export (optional).
     * @param {string} [payload.body.description] - The new description for the dynamic export (optional).
     */
    updateDynamicExport({ id, body }) {
        return axios.patch(`/statistics/dynamic-export/${id}`, body);
    },

    /**
     * Runs a dynamic export for the given `id` and specified date range.
     *
     * @param {Object} payload - The payload for running the export.
     * @param {number} payload.id - The ID of the dynamic export to run (must be a valid number).
     * @param {Object} payload.body - The date range for the export.
     * @param {Date} payload.body.start - The start date of the export (must be a valid date).
     * @param {Date} payload.body.end - The end date of the export (must be a valid date).
     * @param {string} [payload.headers.accept='application/json'] - The format of the export ('application/json' or 'text/csv').
     */
    runDynamicExport({ id, body }) {
        return axios.post(`/statistics/dynamic-export/${id}/run`, body, {
            headers: { accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
            responseType: 'blob',
        });
    },
};
