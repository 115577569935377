import DedupeRequest from '@/entity/DedupeRequest';
import { jwtParser } from '@/utils';
import axios from 'axios';

// change url to /users/quick for faster result
function allUsers() {
    return axios.get('/users?type=extended');
}

function getUserNotification(userId) {
    return axios.get(`/users/${userId}/notification`);
}
async function getUsers(payload) {
    const result = await axios.get(`/userslist/${payload}`);
    const roles = jwtParser(result.config.headers.Authorization).Roles;
    // sort roles by largest to smallest
    roles.sort((a, b) => b - a);
    result.data.push(roles[0]);
    return result;
}

function getSpecificUserInfo(payload) {
    return axios.get(`/users/${payload.userId}/info/${payload.data}/${payload.searchType || '*'}`);
}
function updateUserPresence(payload) {
    return axios.put(`/users/${payload.userId}/presence`, payload.data);
}
function getIntegrationLink(payload) {
    return axios.get(`/soap/user/${payload}`);
}
function setIntegrationLink(payload) {
    return axios.put('/soap/user', payload);
}
function setLastHangup(query, userId, lastHangup) {
    return axios.put(`/users/${userId}/lastHangup?${query}`, { lastHangup });
}

function getUsersAndRoles(payload) {
    return axios.get(
        `/user-role-list?page=${payload?.page || 1}&search=${payload?.search || ''}&roles=${
            payload?.roleIdFilter || ''
        }&selfFirst=${payload?.selfFirst || false}`,
    );
}

function getUserCount() {
    return axios.get('/users/info/count');
}

//= ===================================================//
//                     Swyx   Users                    //
//= ===================================================//

function togglePasswordChange(data) {
    return axios.put(`/users/${data.userId}/credential/change`, { value: data.value });
}

// Store profile pictures for 30 seconds
const getUserProfileImageByUserIdDedupe = new DedupeRequest(({ userId, resolution }) => {
    return axios.get(`/users/${userId}/profileimage?resolution=${resolution}`);
}, 30 * 1000);

function getUserProfileImageByUserId(userId, resolution) {
    return getUserProfileImageByUserIdDedupe.send({ userId, resolution });
}
function setUserProfileImage(userId, image) {
    return axios.post(`/users/${userId}/profileimage`, { image });
}

function getCurrentCommunications() {
    return axios.get('/queues/communications/user');
}

function getUser(userId) {
    return axios.get(`/users/${userId}`);
}

function updateUserFields({ userId, fields }) {
    return axios.patch(`/users/${userId}`, fields);
}

function getUserSettings(userId) {
    return axios.get(`/users/${userId}/settings`);
}

function setUserSettings(userId, settings) {
    return axios.put(`/users/${userId}/settings`, settings);
}

function getAllUsersV2(payload) {
    return axios.get('/v2/users', { params: payload });
}

function updateUser(userId, data) {
    return axios.patch(`/v2/users/${userId}`, data);
}

export default {
    allUsers,
    getUserNotification,
    getUsers,
    getSpecificUserInfo,
    getUsersAndRoles,
    updateUserPresence,
    setIntegrationLink,
    getIntegrationLink,
    togglePasswordChange,
    getUserProfileImageByUserId,
    setUserProfileImage,
    getCurrentCommunications,
    setLastHangup,
    getUser,
    updateUserFields,
    getUserCount,
    getUserSettings,
    setUserSettings,
    getAllUsersV2,
    updateUser,
};
