<template>
    <button class="chevron" @click.stop="$emit('click')" @keydown.enter="$emit('click')">
        <i class="mdi" :class="chevronIcon"></i>
    </button>
</template>

<script lang="ts">
    export default {
        name: 'TimelineItemChevron',

        props: {
            active: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            chevronIcon() {
                return this.active ? 'mdi-chevron-down' : 'mdi-chevron-up';
            },
        },
    };
</script>

<style scoped>
    .chevron {
        font-size: 16px;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 0.2s ease-in;
        border-radius: 4px;
    }

    .chevron:hover {
        cursor: pointer;
        background-color: var(--v-gray5-darken1);
    }

    .chevron:active {
        background-color: var(--v-gray5-darken2);
    }
</style>
