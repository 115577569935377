<template>
    <div class="comments" :style="commentStyle" :class="commentClass">
        <header class="comment-header comment-section">
            <div class="comment-avatar">
                <InternalType :absolute="false" :item="{ internalType: 'callback' }" />
                <v-tooltip bottom>
                    <template #activator="{ on }">
                        <div v-on="on">
                            <CommonAvatar v-if="item.userId" :userId="item.userId" :size="32" />
                            <SystemAvatar v-else :size="32" />
                        </div>
                    </template>
                    <span>{{ username }}</span>
                </v-tooltip>
            </div>
            <section>
                <div class="comment-info">
                    <div class="comment-from text-truncate">{{ item.from }}</div>

                    <secondary-action-button class="tries-button">{{ item.tries }}</secondary-action-button>

                    <div>
                        <div class="comment-top-field end comment-date text-truncate">
                            <v-progress-circular
                                v-if="!item.sent"
                                color="var(-v-gray2-base)"
                                indeterminate
                                size="10"
                                width="2"
                            />
                            {{ $t('comment.try') }}
                        </div>

                        <div class="comment-top-field end comment-date text-truncate">
                            {{ formatDate(item.dateTime) }}
                        </div>
                    </div>
                </div>
            </section>
        </header>
        <CallRecording :item="item" />
    </div>
</template>
<script>
    import { format } from 'date-fns';
    import { mapState } from 'vuex';
    import { getDay } from '@/utils/DateFormatter';

    import config from '@/app/config';

    import InternalType from '@/components/Cases/List/InternalType.vue';
    import SystemAvatar from '@/components/Global/SystemAvatar.vue';
    import CommonAvatar from '@/components/Global/CommonAvatar.vue';
    import CallRecording from './CallRecording.vue';

    export default {
        components: {
            InternalType,
            SystemAvatar,
            CommonAvatar,
            CallRecording,
        },

        props: {
            item: {
                type: Object,
                default: () => {},
            },
        },

        data() {
            return {
                config,
            };
        },
        computed: {
            ...mapState({
                userSettings: (state) => state.System.userSettings,
                loggedInUser: (state) => state.Auth.userObject,
            }),
            username() {
                if (!this.item.userId) {
                    return this.$t('comment.system');
                }
                if (this.item?.agentDeleted) {
                    return this.$t('global.deletedUser');
                }
                return this.item.userName;
            },
            commentStyle() {
                return {
                    justifySelf: this.item.outgoing ? 'end' : 'start',
                };
            },

            commentClass() {
                return {
                    outgoing: this.item.outgoing,
                    incoming: !this.item.outgoing,
                };
            },

            commentDirection() {
                // handle sent
                if (!this.item.sent) {
                    return this.$t('comment.sending');
                }
                return this.item.outgoing ? this.$t('comment.outgoing') : this.$t('comment.incoming');
            },
        },

        methods: {
            formatDate(dateTime) {
                if (!this.userSettings.cases.showDate.active) {
                    return getDay(dateTime);
                }

                return getDay(dateTime) + ' (' + format(new Date(dateTime), 'yyyy-MM-dd') + ')';
            },

            getDay,
            format,
        },
    };
</script>
<style scoped lang="scss">
    .end {
        justify-content: end;
    }
    .comment-action-buttons {
        padding-top: 16px;
    }
    .comment-attachment {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        padding-top: 16px;
        padding-bottom: 16px;
    }
    .comment-top-field {
        font-size: 12px;
        gap: 4px;
        display: flex;
        align-items: center;
    }
    .comments {
        padding: 32px;
        border-radius: 12px;
        width: 100%;
        max-width: 800px;
        gap: 4px;
        border: 1px solid #ccc;
        font-size: 1rem;
    }

    .comments-grid {
        display: grid;
        grid-template-columns: 1fr 1.4fr;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        padding: 8px 0px;
    }

    .comments-grid-header {
        text-align: left;
        font-size: 12px;
        color: var(--v-gray2-base);
    }

    .comments-grid-text {
        font-size: 12px;
        color: var(--v-gray4-base);
    }

    .medium-heading {
        font-size: 14px;
        font-weight: 500;
        color: var(--v-gray4-base);
        text-align: center;
        margin-bottom: 16px;
    }

    .comment-avatar {
        color: white;
        border: 0px solid var(--v-accent2-base);
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 40px;
        gap: 8px;
    }

    .comment-header {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 8px;
    }

    .avatar-image {
        background-position: center;
        object-fit: cover;
    }

    .comment-info {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        font-size: 0.8rem;
        color: var(--v-gray2-base);
    }

    .comment-date {
        justify-self: end;
    }

    .comment-from {
        color: var(--v-gray4-base);
        font-size: 1rem;
    }

    .comment-content {
        padding-top: 16px;
        padding-bottom: 16px;
        font-size: 1rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .callback-content {
        padding-top: 16px;
        padding-bottom: 16px;
        font-size: 1rem;
    }

    .comment-title {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 1.3rem;
        font-weight: 300;
    }

    .callback-button {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .tries-button {
        cursor: default;
    }
</style>

<i18n lang="json">
{
    "en": {
        "comment": {
            "to": "To:",
            "outgoing": "Outgoing",
            "incoming": "Incoming",
            "sending": "Sending",
            "created": "Created",
            "callback": "Callback",
            "try": "Try"
        }
    },
    "sv": {
        "comment": {
            "to": "Till:",
            "outgoing": "Utgående",
            "incoming": "Inkommande",
            "sending": "Skickar",
            "created": "Skapad",
            "callback": "Återkoppla",
            "try": "Försök"
        }
    }
}
</i18n>
