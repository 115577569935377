<template>
    <section>
        <v-tooltip v-for="subCase in subCases" :key="subCase.caseId" left>
            <template #activator="{ on }">
                <div v-on="on" class="status-icon-wrapper">
                    <StatusIcon
                        :status="subCase.status"
                        :size="32"
                        :hasNotification="Boolean(subCase.unreadMessages)"
                        button
                        @click="$emit('goToCase', subCase.caseId)"
                    />
                </div>
            </template>
            {{ subCase.topic }}
        </v-tooltip>
    </section>
</template>
<script lang="ts">
    import { defineComponent, type PropType } from 'vue';
    import type { Case } from '@/types/cases.types';

    import StatusIcon from './StatusIcon.vue';

    export default defineComponent({
        components: {
            StatusIcon,
        },
        props: {
            subCases: {
                type: Array as PropType<Case[]>,
                default: () => [],
            },
        },
    });
</script>
<style scoped>
    section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        padding-block: 2px; /* Added to make sure unread messages-indicator isn't hidden due to overflowing */
        overflow-y: scroll;

        /* Hide scrollbar for WebKit browsers */
        &::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for other browsers */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
</style>
