<template>
    <span>{{ formatDateToText(date, $i18n.locale) }}</span>
</template>
<script>
    import { formatDateToText } from '@/utils/DateFormatter';
    import { defineComponent } from 'vue';

    export default defineComponent({
        props: {
            date: {
                type: [String, Date],
                required: true,
            },
        },
        methods: {
            formatDateToText,
        },
    });
</script>
<style scoped>
    span {
        color: var(--Color-Neutrals-20, #545454);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
    }
</style>
