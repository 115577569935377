<template>
    <section class="button-section">
        <button v-if="expanded" class="expanded-button" @click="createSubCase">
            <i class="mdi mdi-plus"></i>
            <span>
                {{ $t('create') }}
            </span>
        </button>

        <tooltip v-else :content="$t('create')">
            <button class="minimized-button" @click="createSubCase">
                <i class="mdi mdi-plus"></i>
            </button>
        </tooltip>
    </section>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { formTypes } from '@/enums/creator.enums';
    import { caseTypes } from '@/enums/cases.enums';

    export default defineComponent({
        props: {
            expanded: {
                type: Boolean,
                default: false,
            },
            caseId: {
                type: String,
                required: true,
            },
        },

        methods: {
            async createSubCase() {
                // @ts-ignore - Vuex store is not typed
                const caseId = this.$store.state.Cases.selectedCase.case.caseId;

                if (!caseId) {
                    return;
                }

                const payload = {
                    caseId: caseId,
                    load: false,
                    commentId: null,
                    search: null,
                    itemsPerPage: 100,
                };

                // @ts-ignore - Vuex store is not typed
                const caseData = await this.$store.dispatch('Cases/getCaseContent', payload);

                const latestIncomingComment = caseData.comments
                    .sort(
                        (a: { dateTime: string }, b: { dateTime: string }) =>
                            new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
                    )
                    .find(
                        (comment: { typeOfMessage: string; outgoing: number }) =>
                            comment.typeOfMessage === caseTypes.EMAIL && comment.outgoing === 0
                    );

                const reciever = latestIncomingComment?.receiver;

                // @ts-ignore - Vuex store is not typed
                this.$store.dispatch(
                    'Creator/openForm',
                    {
                        id: formTypes.CREATE_SUBCASE,
                        keys: {
                            caseId: { value: this.caseId },
                            status: { value: 'Closed' },
                            from: { value: reciever },
                        },
                    },
                    { root: true }
                );
            },
        },
    });
</script>

<style scoped>
    i {
        font-size: 20px;
    }

    button {
        background-color: transparent;
        border: none;
        color: #000;
        cursor: pointer;
        min-height: 32px;
        transition: background-color 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    span {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-wrap: nowrap;
    }

    .minimized-button {
        border-radius: 50%;
        width: 32px;
    }

    .expanded-button {
        border-radius: 16px;
        padding: 0 8px;
        gap: 4px;
    }

    button:hover {
        background-color: var(--Color-Neutrals-60, #d9d9d9);
    }

    button:active {
        background-color: var(--Color-Neutrals-70, #c0c0c0);
    }

    .button-section {
        display: flex;
        justify-content: center;
    }
</style>

<i18n lang="json">
{
    "en": {
        "create": "Create subcase"
    },
    "sv": {
        "create": "Skapa underärende"
    }
}
</i18n>
