<template>
    <section class="single-select">
        <WidgetQueueSelect v-model="selectedChannelIdentifier" :placeholder="$t('selectQueue')" />
    </section>
</template>
<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import WidgetQueueSelect from './WidgetQueueSelect.vue';
    import { WidgetIdentifier, WidgetQueue } from '@/types/widgets.types';

    export default defineComponent({
        components: { WidgetQueueSelect },
        props: {
            value: {
                type: Array as PropType<WidgetIdentifier[]>,
                required: true,
            },
        },
        computed: {
            values: {
                get(): WidgetIdentifier {
                    return this.value[0] || {};
                },
                set(newVal: WidgetIdentifier) {
                    this.$emit('input', [newVal]);
                },
            },
            selectedChannelIdentifier: {
                get() {
                    return this.values.queueId;
                },
                set(queue: WidgetQueue) {
                    this.values = {
                        channelIdentifier: queue.RelationId,
                        queueId: queue.ID,
                    };
                },
            },
        },
    });
</script>
<style scoped lang="scss">
    .single-select {
        padding: 32px 24px;
    }
</style>
