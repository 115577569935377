<template>
    <button :class="{ notification: Boolean(item.unreadMessages) }">
        <section class="text-container">
            <truncate v-if="item.topic">
                <SubCaseCardTitle :title="item.topic" />
            </truncate>
            <SubCaseCardDate :date="item.created" />
        </section>

        <section class="status-container">
            <StatusIcon :status="item.status" />
            <UserAvatar :userId="item.userId" />
        </section>
    </button>
</template>
<script lang="ts">
    import SubCaseCardDate from './SubCaseCardDate.vue';
    import StatusIcon from './StatusIcon.vue';
    import SubCaseCardTitle from './SubCaseCardTitle.vue';
    import UserAvatar from './UserAvatar.vue';

    import { defineComponent, type PropType } from 'vue';
    import type { Case } from '@/types/cases.types';

    export default defineComponent({
        components: {
            SubCaseCardTitle,
            SubCaseCardDate,
            StatusIcon,
            UserAvatar,
        },
        props: {
            item: {
                type: Object as PropType<Case>,
                required: true,
            },
        },
    });
</script>
<style scoped lang="scss">
    button {
        overflow: hidden;
        white-space: nowrap;

        position: relative;
        display: flex;
        flex-direction: column;
        padding: 12px 16px 12px 12px;
        gap: 12px;
        align-self: stretch;

        border-radius: var(--Corner-Radius-Corner-Radius-Medium, 8px);
        background: var(--Color-Neutrals-100, #fff);

        /* Shadows/Small */
        box-shadow:
            0px 0px 2px 0px rgba(0, 0, 0, 0.15),
            0px 2px 4px 0px rgba(0, 0, 0, 0.1);

        transition: background 0.05s ease-in-out;

        &:hover {
            background: var(--Color-Neutrals-70, #d9d9d9);
        }

        &:focus {
            background: var(--Color-Neutrals-70, #d9d9d9);
            outline-offset: 2px;
        }

        &:active {
            background: var(--Color-Neutrals-80, #c0c0c0);
            box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.2);
        }

        &.notification::after {
            position: absolute;
            content: '';
            width: 8px;
            height: 8px;
            top: 8px;
            right: 8px;
            border-radius: 50%;
            background: var(--Color-Danger-50, #e61a1a);
        }

        .text-container {
            display: flex;
            flex-direction: column;
            gap: 2px;
            flex: 1;
            text-align: start;
            align-self: stretch;
            text-align: start;
        }

        .status-container {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }
</style>
