<template>
    <div
        class="status-icon"
        :class="{ notification: hasNotification, button }"
        :style="{
            '--status-icon-size': `${size}px`,
            '--status-icon-bg-color': statusColor,
        }"
        @click="$emit('click')"
        :aria-label="`Status: ${status}`"
    >
        <i class="mdi icon" :class="statusIcon"></i>
    </div>
</template>

<script lang="ts">
    import { statusIcons, statusColors } from '@/enums/cases.enums';
    import { defineComponent, type PropType } from 'vue';
    import type { Case } from '@/types/cases.types';

    export default defineComponent({
        props: {
            status: {
                type: String as PropType<Case['status']>,
                required: true,
            },
            size: {
                type: Number,
                default: 24,
            },
            hasNotification: {
                type: Boolean,
                default: false,
            },
            button: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            statusIcon(): string {
                return statusIcons[this.status] || 'mdi-alert';
            },
            statusColor(): string {
                return (
                    //@ts-ignore
                    this.$vuetify.theme.themes.light[statusColors[this.status]] ||
                    //@ts-ignore
                    this.$vuetify.theme.themes.light.gray1
                );
            },
        },
    });
</script>

<style scoped lang="scss">
    .status-icon {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: var(--Corner-Radius-Corner-Radius-Small, 4px);
        width: var(--status-icon-size);
        height: var(--status-icon-size);
        background-color: var(--status-icon-bg-color);
        transition:
            background-color 0.3s ease,
            transform 0.2s ease;

        &.button {
            cursor: pointer;

            &:hover {
                filter: brightness(1.1);
            }

            &:active {
                transform: scale(0.95);
            }
        }

        &.notification::after {
            position: absolute;
            content: '';
            right: 0;
            top: 0;
            transform: translate(25%, -25%);
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 2px solid #fff;
            background: var(--Color-Danger-50, #e61a1a);
        }
    }
</style>
