<template>
    <div class="client-integration-button-wrapper">
        <v-icon v-show="shouldBeVisible('left')" class="pagination-button left" @click="paginate('left')">
            mdi-chevron-left
        </v-icon>
        <div class="integration-grid">
            <template v-if="state === states.LOADED">
                <template v-if="visibleIntegrations.length">
                    <component
                        :is="item.component"
                        v-for="item in visibleIntegrations"
                        :key="item.component"
                        :integration="item"
                        :clientCard="clientCard"
                        :clientId="clientId"
                    />
                </template>
                <div v-else class="integrations-text-container">{{ $t('integrations.noIntegrations') }}</div>
            </template>

            <template v-else>
                <div class="placeholder"></div>
            </template>
        </div>
        <v-icon v-if="shouldBeVisible('right')" class="pagination-button right" @click="paginate('right')">
            mdi-chevron-right
        </v-icon>
    </div>
</template>

<script>
    import IntegrationRedirectButton from './IntegrationRedirectButton.vue';
    import IntegrationRecaptchaButton from './IntegrationRecaptchaButton.vue';
    import IntegrationDeduButton from './IntegrationDeduButton.vue';
    import IntegrationLimeButton from './IntegrationLimeButton.vue';
    import IntegrationPindeliveryButton from './IntegrationPindeliveryButton.vue';
    import IntegrationSAGetMemberButton from './IntegrationSAGetMemberButton.vue';
    import IntegrationSAOpenMemberButton from './IntegrationSAOpenMemberButton.vue';
    import KuralinkIntegrationButton from './KuralinkIntegrationButton.vue';
    import I2IButton from './I2I/I2IButton.vue';

    import { mapState } from 'vuex';

    export default {
        components: {
            IntegrationRedirectButton,
            IntegrationRecaptchaButton,
            IntegrationDeduButton,
            IntegrationLimeButton,
            IntegrationPindeliveryButton,
            IntegrationSAGetMemberButton,
            IntegrationSAOpenMemberButton,
            KuralinkIntegrationButton,
            I2IButton,
        },
        data() {
            return {
                state: 'init',
                states: Object.freeze({
                    INIT: 'init',
                    LOADING: 'loading',
                    LOADED: 'loaded',
                    ERROR: 'error',
                }),
                totalIntegrations: [],
                visibleIntegrations: [],
                firstIndex: 0,
                lastIndex: 4,
                paginationPageSize: 4,
            };
        },
        computed: {
            ...mapState({
                clientCard: (state) => state.Client.currentSelectedClient,
            }),
            clientId() {
                return this.$store.state.Client.currentSelectedClient.clientId;
            },
        },

        created() {
            this.init();
        },

        methods: {
            async init() {
                try {
                    this.state = this.states.LOADING;
                    this.totalIntegrations = await this.$store.dispatch('Integrations/getCaseButtonIntegrations');

                    this.visibleIntegrations = this.totalIntegrations.slice(0, 4);
                    this.state = this.visibleIntegrations ? this.states.LOADED : this.states.ERROR;
                } catch {
                    this.state = this.states.ERROR;
                }
            },
            paginate(direction) {
                const { length: totalLength } = this.totalIntegrations;
                const { length: visibleLength } = this.visibleIntegrations;

                if (totalLength < visibleLength) return;

                if (direction === 'left' && this.firstIndex >= 0) {
                    this.firstIndex -= this.paginationPageSize;
                    this.lastIndex -= this.paginationPageSize;
                    this.visibleIntegrations = this.totalIntegrations.slice(this.firstIndex, this.lastIndex);
                } else if (direction === 'right' && this.lastIndex <= totalLength - 1) {
                    this.firstIndex += this.paginationPageSize;
                    this.lastIndex += this.paginationPageSize;
                    this.visibleIntegrations = this.totalIntegrations.slice(this.firstIndex, this.lastIndex);
                }
            },
            shouldBeVisible(direction) {
                const { length: totalLength } = this.totalIntegrations;

                if (direction === 'left' && this.firstIndex >= this.paginationPageSize) {
                    return true;
                }

                return !!(direction === 'right' && this.lastIndex <= totalLength - 1);
            },
        },
    };
</script>

<style scoped lang="scss">
    .client-integration-button-wrapper {
        position: relative;
        width: 100%;

        .pagination-button {
            position: absolute;
            height: 100%;
            z-index: 2;
        }

        .left {
            left: 0;
            top: 0%;
        }

        .right {
            right: 0;
            top: 0%;
        }
    }

    .integration-grid {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        width: 100%;
        border-bottom: 1px solid rgb(0, 0, 0, 0.15);
        min-height: 85px;
        max-height: 85px;
        z-index: 1;
    }

    .integration-grid > * {
        height: 100%;
    }

    .integration-grid > :first-child {
        border-radius: 16px 0 0 0;
    }

    .integration-grid > :last-child {
        border-radius: 0 16px 0 0;
    }

    .placeholder {
        grid-column: span 4;
        height: 85px;
        overflow: hidden;
    }

    .integrations-text-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        grid-column: span 4;
        color: var(--v-gray1-base);
        font-size: 0.9rem;
    }
</style>

<i18n lang="json">
{
    "en": {
        "integrations": {
            "noIntegrations": "No integrations available"
        }
    },

    "sv": {
        "integrations": {
            "noIntegrations": "Inga integrationer tillgängliga"
        }
    }
}
</i18n>
