<template>
    <div>
        <section v-if="recordingsExists" class="comment-recordings mt-4">
            <div class="recordings-container">
                <primary-action-button :dense="true" @click="play()">
                    {{ $t('comment.play') }}
                </primary-action-button>
                <secondary-action-button :dense="true" :disabled="true">
                    {{ $t('comment.summary') }}
                </secondary-action-button>
                <secondary-action-button :dense="true" :disabled="true">
                    {{ $t('comment.dialog') }}
                </secondary-action-button>
            </div>
            <div class="recordings-container">
                <audio ref="audio" controls="controls" autobuffer="autobuffer">
                    <source type="audio/wav" />
                    {{ $t('comment.noSupport') }}
                </audio>
            </div>
        </section>
        <section v-if="result.transcript" class="comment-transcript">
            <div>
                {{ result.transcript }}
            </div>
        </section>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';

    export default {
        props: {
            item: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {
                recordingsExists: false,
                result: {},
            };
        },
        created() {
            this.loadRecordingData(this.item.msgId);
        },
        methods: {
            ...mapActions({
                getRecording: 'Comments/getRecording',
                getRecordingsMetaData: 'Comments/getRecordingsMetaData',
            }),

            async loadRecordingData(id) {
                this.loaded = false;
                await this.getRecordingMetaData(id);
                this.loaded = true;
            },

            async play() {
                try {
                    this.$refs.audio.src = await this.getRecording(this.item.msgId);
                    this.$refs.audio.play();
                } catch (error) {
                    console.log('error', error);
                }
            },

            async getRecordingMetaData(id) {
                try {
                    const result = await this.getRecordingsMetaData(id);
                    const { data, status } = result;
                    if (status === 200) {
                        this.recordingsExists = true;
                        this.result = data;
                    }
                } catch (error) {
                    this.recordingsExists = false;
                }
            },
        },
    };
</script>
<style>
    .recordings-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }

    .comment-recordings {
        display: grid;
        padding: 16px 0px;
        margin-bottom: -16px;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        grid-template-columns: 1fr 1fr;
    }

    .comment-transcript {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        padding: 16px 0px;
    }
</style>
<i18n lang="json">
{
    "en": {
        "comment": {
            "play": "Play",
            "summary": "Summary",
            "dialog": "Dialog",
            "noSupport": "Your browser does not support the audio element."
        }
    },
    "sv": {
        "comment": {
            "play": "Spela upp",
            "summary": "Summering",
            "dialog": "Dialog",
            "noSupport": "Din webbläsare stödjer inte ljudfiler."
        }
    }
}
</i18n>
