<template>
    <section class="red-dot"></section>
</template>

<script lang="ts">
    export default {
        name: 'UnreadMessagesIndicator',
    };
</script>

<style scoped>
    .red-dot {
        width: 8px;
        height: 8px;
        background-color: red;
        border-radius: 50%;
    }
</style>
