<template>
    <div class="selector-text">
        <span class="title">THE TITLEEE</span>
        <span class="sub-title">THE SUB TITLTEEE</span>
    </div>
</template>
<script>
    export default {};
</script>
<style scoped lang="scss">
    div.selector-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
        flex: 1 0 0;

        .title {
            color: var(--Color-Neutrals-0, #000);

            /* Body/Body M 400 */
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
        }

        .sub-title {
            color: var(--Color-Neutrals-10, #3f3f3f);

            /* Body/Body S 400 */
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
        }
    }
</style>
<i18n lang="json">
{
    "en": {},
    "sv": {}
}
</i18n>
