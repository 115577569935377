export type WidgetIdentifier = {
    queueId: number;
    channelIdentifier: string;
    categoryName?: string | null;
};

export type WidgetQueue = {
    ID: number; // queueid
    Name: string; // queue name
    RelationId: string; // chat identifier
};

export enum IdentifierModalStep {
    MODE_SELECT = 0,
    SINGLE_SELECT = 1,
    MULTIPLE_SELECT = 2,
}
