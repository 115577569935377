/*
    CASE MODEL FROM DB:

    ID
    CaseID
    ClientID
    Status
    Topic
    Description
    UserId
    AgentName
    SLA
    Category
    CategoryIds
    Answered
    Responded
    InternalCreated
    MsgID
    CaseRespondant
    AssignedTo
    HasAttachment
    InternalType
    Language
    GroupId
    Group
    Result
    Tries
    Text
    UnreadMessages
    DateTime
    Created
    LastUpdated
    LastUpdatedBy
    Resolved
    ResolvedAt
    ResolvedBy
    SurveySent
    ToEmail
    CopyToEmail
    HiddenCopyToEmail
    GdprCleaned
    JustSnoozed
    AgentCommentTime

*/

export function formatFilters(filters) {
    const mappedFilters = {
        Status: filters.statuses.map((q) => q.value).sort((a, b) => a - b),
        Limit: filters.items,
        Page: filters.page,
        UserId: filters.users.value,
        GroupId: filters.queues.map((item) => item.value).sort((a, b) => a - b),
        InternalType: filters.channels.map((item) => item.value).sort(),
        IncludeSubCases: filters.includeSubCases,
        CategoryIds: filters.categories.map((item) => item.value).sort((a, b) => a - b),
        SortBy: filters.sortField,
        Direction: filters.sortDirection,
        Query: filters.searchQuery,
    };

    if (filters.showUnansweredOnly) {
        mappedFilters.Answered = 0;
    }

    return mappedFilters;
}

export function getChartOptionsQueues() {
    return {
        selection: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
            animations: {
                enabled: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        yaxis: {
            type: 'numeric',
        },
        xaxis: {
            type: 'category',
            categories: [''],
        },
        fill: {
            opacity: 1,
        },
        dataLabels: {
            enabled: false,
        },
        colors: [
            'var(--v-color1-base)',
            'var(--v-color2-base)',
            'var(--v-color3-base)',
            'var(--v-color4-base)',
            'var(--v-color5-base)',
        ],
    };
}

export function getChartOptionsBig() {
    return {
        selection: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        series: [
            {
                name: 'series1',
                data: [31, 40, 28, 51, 42, 109, 100],
            },
            {
                name: 'series2',
                data: [11, 32, 45, 32, 34, 52, 41],
            },
        ],
        chart: {
            height: 350,
            type: 'area',
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
            animations: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
            colors: ['var(--v-primary-base)'],
        },
        xaxis: {
            type: 'datetime',
            labels: {
                style: {
                    colors: [],
                    fontSize: '10px',
                    fontWeight: 400,
                },
                datetimeUTC: false,
                format: 'HH:mm',
            },
        },

        yaxis: {
            labels: {
                style: {
                    colors: [],
                    fontSize: '10px',
                    fontWeight: 400,
                },
                formatter(val) {
                    return Math.round(val);
                },
            },
        },
        tooltip: {
            x: {
                format: 'dd/MM/yy HH:mm',
            },
        },
    };
}
