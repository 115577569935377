<template>
    <multi-select
        solo
        nudgeY="50"
        :items="clientTypes"
        @change="handleChange"
        textKey="name"
        valueKey="id"
        :error="error"
    />
</template>
<script>
    import { getClientTypes } from '@/api/clientTypes/clientTypes';

    export default {
        props: {
            value: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                clientTypes: [],
                error: false,
            };
        },
        created() {
            this.error = false;
            this.fetchclientTypes();
        },
        methods: {
            async fetchclientTypes() {
                try {
                    const { data } = await getClientTypes();
                    this.clientTypes = data;
                } catch (error) {
                    console.error('Error fetching clientTypes');
                    this.error = true;
                }
            },
            handleChange(clientTypesArr) {
                const clientTypeIds = clientTypesArr.map((clientType) => clientType.id);
                this.$emit('input', clientTypeIds);
            },
        },
    };
</script>

<i18n lang="json">
{
    "en": {
        "select": {
            "couldNotFetch": "Could not fetch client types"
        }
    },
    "sv": {
        "select": {
            "couldNotFetch": "Kunde inte hämta ärendetyper"
        }
    }
}
</i18n>
