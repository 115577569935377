export function convertToString(...args) {
    const isArray = (value) => Array.isArray(value);

    const convertToCommaSeparatedString = (value) => {
        if (isArray(value)) {
            return value.join(', ');
        }
        if (typeof value === 'string' && value) {
            return value;
        }
        return '';
    };

    let result = '';
    let isFirst = true;

    for (const item of args) {
        const itemAsString = convertToCommaSeparatedString(item);

        if (itemAsString) {
            if (!isFirst) {
                result += ', ';
            } else {
                isFirst = false;
            }
            result += itemAsString;
        }
    }
    return result;
}

const getSenderFromEmail = (email) => {
    return email.outgoing ? email.from : email.receiver;
};

/**
 * Get recipients from an email object.
 *
 * @param {{ email: { outgoing: boolean, to: Array<string>, from: string }, answerAll: boolean, systemEmails: Array<string> }} options - The options for retrieving recipients.
 * @returns {Array<string>} An array of new recipients, without duplicates.
 */
const getRecipientsFromEmail = ({ email, answerAll = false, systemEmails }) => {
    const { outgoing, to } = email;
    const incoming = !outgoing;
    let newReceivers = [];

    const fromEmail = getFromByReplyTo(email);

    switch (true) {
        case outgoing && answerAll: {
            newReceivers = to.filter((r) => !systemEmails.includes(r));
            break;
        }
        case outgoing && !answerAll: {
            newReceivers = to.filter((r) => !systemEmails.includes(r));
            break;
        }
        case incoming && answerAll: {
            newReceivers = [...to, fromEmail].filter((r) => !systemEmails.includes(r));
            break;
        }
        case incoming && !answerAll: {
            newReceivers = [fromEmail];
            break;
        }
        default: {
            newReceivers = [];
            break;
        }
    }

    return [...new Set(newReceivers)];
};

function validateEmail(mail) {
    const re = /^\S+@\S+\.\S+$/;
    return re.test(mail) && mail.match(/@/g).length === 1;
}

function getFromByReplyTo({ replyTo, from }) {
    // If comments has an replyTo, we want to use that as the sender (Email standard)
    if (replyTo && validateEmail(replyTo)) {
        return replyTo;
    }
    return from;
}

/**
 *
 * @param {string} html
 */
export function changeImgSrcToDataSrc(html) {
    const parser = new DOMParser();
    const tree = parser.parseFromString(html, 'text/html');
    for (const element of tree.body.querySelectorAll('img[src]')) {
        const src = element.getAttribute('src');
        if (!src.includes('cid:')) continue;
        element.setAttribute('data-src', src);
        element.setAttribute('src', '');
    }
    return tree.body.innerHTML;
}

export default {
    convertToString,
    getSenderFromEmail,
    getRecipientsFromEmail,
    validateEmail,
};
