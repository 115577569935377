<template>
    <section>
        <v-select
            v-model="selectedInstanceId"
            :items="identityProviderInstances"
            item-value="id"
            item-text="name"
            :label="$t('IdentityProvider')"
            outlined
        >
            <template #item="{ item }">
                <v-list-item-content>
                    <v-list-item-title v-text="item.name || $t('instanceDefault')"></v-list-item-title>
                    <v-list-item-subtitle
                        v-if="item.integrationName"
                        v-text="item.integrationName"
                    ></v-list-item-subtitle>
                </v-list-item-content>
            </template>

            <template #selection="{ item }">
                <v-list-item-content>
                    <v-list-item-title>
                        <span>{{ item.name || $t('instanceDefault') }}</span>
                        <v-chip
                            v-if="item.integrationName"
                            small
                            color="primary"
                            v-text="item.integrationName"
                        ></v-chip>
                    </v-list-item-title>
                </v-list-item-content>
            </template>
        </v-select>
    </section>
</template>

<script lang="ts">
    import integration from '@/api/integrations/integration';

    export default {
        props: {
            value: {
                default: null,
                required: false,
            },
            instances: {
                type: Array,
                required: false,
                default: () => [],
            },
        },
        data(): {
            selectedInstanceId: number | null;
            identityProviderInstances: { id: number | null; name: string }[];
        } {
            return {
                selectedInstanceId: this.value,
                identityProviderInstances: [],
            };
        },
        async created() {
            const request = await integration.getIdentityProviderInstances('AUTHENTICATION');
            this.identityProviderInstances = request.data;

            this.identityProviderInstances.unshift({
                id: null,
                name: String(this.$t('None')),
            });
        },
        watch: {
            value(instanceId) {
                this.selectedInstanceId = instanceId;
            },
            selectedInstanceId(instanceId) {
                this.$emit('input', instanceId);
            },
        },
        methods: {},
    };
</script>

<i18n lang="json">
{
    "en": {
        "instanceDefault": "Instance 1",
        "None": "None",
        "IdentityProvider": "Identityprovider"
    },
    "sv": {
        "instanceDefault": "Instans 1",
        "None": "Ingen",
        "IdentityProvider": "Identitetsleverantör"
    }
}
</i18n>
