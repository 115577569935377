<template>
    <div>
        <IntegrationButton :integrationName="integration.name" :active="active" @click="openUrl" />
    </div>
</template>
<script lang="ts">
    import IntegrationButton from '../IntegrationButton.vue';
    import api from '@/api/integrations/integration';

    export default {
        components: {
            IntegrationButton,
        },
        props: {
            integration: {
                type: Object as () => { name: string },
                default: () => ({}),
            },
            clientId: {
                type: String,
                default: null,
            },
        },
        data(): { url: string; active: boolean } {
            return {
                url: '',
                active: false,
            };
        },
        mounted() {
            this.initI2i();
        },
        methods: {
            async initI2i() {
                if (!this.clientId) return;
                try {
                    const { data } = await api.getI2IResourceUrl(this.clientId);
                    this.url = data;
                    this.active = true;
                } catch (error) {
                    console.error(error);
                }
            },
            openUrl() {
                if (!this.url) return;
                window.open(this.url, '_blank');
            },
        },
    };
</script>
