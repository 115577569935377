<template>
    <v-menu v-model="showMenu" offset-x>
        <template v-slot:activator="{ on, attrs }">
            <v-list-item v-on="on" v-bind="attrs" id="animal-list-item">
                <v-list-item-content>
                    <v-list-item-title>{{ animal.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ animal.breed }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>
        <v-card class="pa-3">
            <v-card-title class="text-h6 font-weight-bold">{{ $t('animal.name') }}: {{ animal.name }}</v-card-title>
            <v-card-text>
                <v-row dense>
                    <v-col cols="12" sm="6">
                        <strong>{{ $t('animal.breed') }}:</strong>
                        {{ animal.breed }}
                    </v-col>
                    <v-col cols="12" sm="6">
                        <strong>{{ $t('animal.birth') }}:</strong>
                        {{ animal.birth }}
                    </v-col>
                    <v-col cols="12" sm="6">
                        <strong>{{ $t('animal.gender') }}:</strong>
                        {{ animal.gender }}
                    </v-col>
                </v-row>
                <v-divider class="my-3"></v-divider>
                <v-row dense>
                    <v-col cols="12" v-if="policy.deductible">
                        <strong>{{ $t('policy.deductible') }}:</strong>
                        {{ policy.deductible.variable }}%, {{ policy.deductible.fixed }} kr
                    </v-col>
                    <v-col cols="12">
                        <strong>{{ $t('policy.vetrinaryCareCompensation') }}:</strong>
                        {{ policy.vetrinaryCareCompensation.maxAmount }}
                    </v-col>
                    <v-col cols="12" v-if="policy.coverages?.length">
                        <strong>{{ $t('policy.coverages') }}:</strong>
                        <v-list dense>
                            <v-list-item v-for="(coverage, index) in policy.coverages" :key="index">
                                <v-list-item-content>{{ coverage.name }}</v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script lang="ts">
    import { Animal } from '@/types/integrations/i2i.types';
    export default {
        components: {},
        props: {
            animal: {
                type: Object as () => Animal,
                required: true,
            },
            policy: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                showMenu: false,
            };
        },
    };
</script>

<style scoped></style>

<i18n lang="json">
{
    "en": {
        "animal": {
            "name": "Name",
            "breed": "Breed",
            "birth": "Birth",
            "gender": "Gender"
        },
        "policy": {
            "deductible": "Deductible",
            "vetrinaryCareCompensation": "Max compensation",
            "coverages": "Coverages"
        }
    },
    "sv": {
        "animal": {
            "name": "Namn",
            "breed": "Ras",
            "birth": "Födelse",
            "gender": "Kön"
        },
        "policy": {
            "deductible": "Självrisk",
            "vetrinaryCareCompensation": "Maxersättning",
            "coverages": "Tillägg "
        }
    }
}
</i18n>
