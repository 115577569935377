<template>
    <article id="cases-bar" :class="{ expanded: expanded }">
        <SubCasesCountIcon :count="subCases.length" :expanded="expanded" />

        <SubCasesCardList v-if="expanded" :subCases="subCases" @goToCase="navigateToCase" />
        <SubCasesStatusIconList v-else-if="!expanded" :subCases="subCases" @goToCase="navigateToCase" />

        <SubCasesCreateButton :expanded="expanded" :caseId="caseId" />
    </article>
</template>
<script lang="ts">
    import SubCasesCountIcon from './SubCasesCountIcon.vue';
    import SubCasesCardList from './SubCasesCardList.vue';
    import ExpandButton from './ExpandButton.vue';
    import SubCasesStatusIconList from './SubCasesStatusIconList.vue';
    import SubCasesCreateButton from './SubCasesCreateButton.vue';

    import { defineComponent, type PropType } from 'vue';
    import type { Case } from '@/types/cases.types';

    export default defineComponent({
        components: {
            SubCasesCountIcon,
            SubCasesCardList,
            ExpandButton,
            SubCasesStatusIconList,
            SubCasesCreateButton,
        },
        props: {
            caseId: {
                type: String,
                required: true,
            },
            subCases: {
                type: Array as PropType<Case[]>,
                default: () => [],
            },
            expanded: {
                type: Boolean,
                default: false,
            },
        },
        methods: {
            navigateToCase(id: string) {
                this.$router.push({ name: 'Case', params: { caseId: id } });
            },
        },
    });
</script>
<style scoped lang="scss">
    article#cases-bar {
        /* Layout */
        position: relative;
        width: 80px;
        display: flex;
        flex-direction: column;
        padding: 32px 0px;
        gap: 32px;
        overflow: hidden;

        /* Style*/
        border-radius: 0px var(--Corner-Radius-Corner-Radius-Large, 16px) var(--Corner-Radius-Corner-Radius-Large, 16px)
            0px;
        border-left: 1px solid var(--Color-Neutrals-70, #d9d9d9);
        background: var(--Color-Neutrals-100, #fff);

        transition: width 0.25s ease-in-out;

        &.expanded {
            width: 240px;
        }
    }
</style>
