<!-- eslint-disable @intlify/vue-i18n/no-unused-keys -->
<template>
    <div class="container">
        <div v-show="currentState === states.loaded" class="iframe-wrapper">
            <iframe ref="myIframe" width="100%" height="100%" class="border-none"></iframe>
        </div>
        <div v-show="currentState === states.error">
            <div class="ma-3">
                <v-icon class="mr-2">mdi-alert-circle</v-icon>
                <span class="iframe-error-message">{{ errorMessage }}</span>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions, mapState } from 'vuex';

    export default {
        data() {
            return {
                states: {
                    loading: 'loading',
                    loaded: 'loaded',
                    error: 'error',
                },
                currentState: null,
                iframe: null,
                retries: 0,
                errorMessage: '',
            };
        },
        computed: {
            ...mapState('Cases', ['selectedIframe', 'caseToLoad']),
        },
        watch: {
            selectedIframe() {
                this.initIframe();
            },
        },
        mounted() {
            this.initIframe();
        },
        methods: {
            ...mapActions('Cases', ['getIframe']),
            async initIframe() {
                try {
                    this.setLoadingState(this.states.loading);
                    this.iframe = this.$refs.myIframe;

                    const response = await this.getIframe(this.iframeRequestPayload());

                    if (this.isError(response)) {
                        this.errorMessage = response.translationKey
                            ? this.$t('integrationIframe.' + response.translationKey)
                            : this.$t('integrationIframe.error');
                        this.setLoadingState(this.states.error);
                        return;
                    }

                    if (this.selectedIframe === 'INET') {
                        this.setIframeContent(response);
                        await this.addOverridingAnchorClick();
                    } else if (this.selectedIframe === 'MADHAT') {
                        if (!response) {
                            this.errorMessage = this.$t('integrationIframe.iframeNotFound');
                            this.setLoadingState(this.states.error);
                            return;
                        }
                        this.setIframeUrl(response);
                    }

                    this.setLoadingState(this.states.loaded);
                } catch (error) {
                    console.error('Request failed:', error);
                    this.setLoadingState(this.states.error);
                    this.errorMessage = this.$t('integrationIframe.error');
                }
            },

            async handleAnchorClick(url) {
                try {
                    this.setLoadingState(this.states.loading);
                    const urlQuery = this.getQueryParams(url);
                    const responseData = await this.getIframe(this.iframeRequestPayload(urlQuery));

                    this.setIframeContent(responseData);

                    await this.addOverridingAnchorClick();

                    this.setLoadingState(this.states.loaded);
                } catch (error) {
                    console.error('Request failed:', error);
                    this.setLoadingState(this.states.error);
                }
            },
            getQueryParams(url) {
                // Create a URLSearchParams object
                const queryParams = new URLSearchParams(new URL(url).search);

                // Convert the URLSearchParams object into a regular object using reduce
                return Array.from(queryParams).reduce((acc, [key, value]) => {
                    acc[key] = value;
                    return acc;
                }, {});
            },
            // * TODO: If we for some reason stick with this "solution", look into  only having one mouseup event listener
            addOverridingAnchorClick() {
                return new Promise((resolve) => {
                    // We need to wait for the iframe to load before we can access its content
                    this.$nextTick(async () => {
                        try {
                            const HUNDRED_MILLISECONDS = 100;
                            await new Promise((resolve) => setTimeout(resolve, HUNDRED_MILLISECONDS));
                            const iframeDocument = this.iframe.contentWindow.document;

                            if (!iframeDocument) {
                                console.error('iframeDocument is null');

                                if (this.retries < 10) {
                                    this.retries++;
                                    await this.addOverridingAnchorClick();
                                } else {
                                    this.setLoadingState(this.states.error);
                                }

                                return;
                            }

                            const anchors = iframeDocument.querySelectorAll('a');

                            for (const anchor of anchors) {
                                anchor.addEventListener('click', (event) => {
                                    if (this.selectedIframe === 'INET') {
                                        event.preventDefault(); // Prevent default navigation
                                        this.handleAnchorClick(event.currentTarget.href);
                                    }
                                });
                            }
                        } catch (error) {
                            console.error('Error accessing iframe content:', error);
                        } finally {
                            resolve();
                        }
                    });
                });
            },
            setLoadingState(state) {
                this.$emit('loading-state', state);
                this.currentState = state;
            },
            setIframeContent(response) {
                this.iframe.srcdoc = response.iframeHtml;
            },
            setIframeUrl(url) {
                this.iframe.src = url;
            },
            isError(response) {
                return !!response.message; // if we have a message, we have an error
            },
            iframeRequestPayload(additionalQueryParams = {}) {
                return {
                    type: this.selectedIframe,
                    query: {
                        ...additionalQueryParams,
                        caseId: this.caseToLoad,
                    },
                };
            },
        },
    };
</script>
<style scoped lang="scss">
    .container {
        width: 100%;
        height: 100%;
        position: relative;

        .iframe-wrapper {
            height: 100%;
            width: 100%;
            .border-none {
                border: none;
            }
        }
    }
</style>

<i18n lang="json">
{
    "en": {
        "integrationIframe": {
            "error": "Something went wrong, please try again later.",
            "integrationNotActive": "Integration is not active.",
            "integrationNotFound": "Integration not found.",
            "clientNotFound": "Client not found.",
            "iframeNotFound": "Iframe not found."
        }
    },
    "sv": {
        "integrationIframe": {
            "error": "Något gick fel, vänligen försök igen senare.",
            "integrationNotActive": "Integrationen är inte aktiv.",
            "integrationNotFound": "Integrationen hittades inte.",
            "clientNotFound": "Klienten hittades inte.",
            "iframeNotFound": "Iframe hittades inte."
        }
    }
}
</i18n>
