<template>
    <section class="tiptap-container">
        <section v-if="external && !isBasic" class="from-to">
            <div class="first-row">
                <v-select
                    v-if="systemEmails.length != 0"
                    :key="caseToLoad"
                    v-model="emailFrom"
                    :menu-props="{ offsetY: true }"
                    v-bind="selectProps"
                    :items="systemEmails"
                >
                    <template #selection="{ attrs, item }">
                        <v-chip v-bind="attrs" color="primary" label small class="text-truncate">
                            <span class="pr-2 text-truncate">
                                {{ item }}
                            </span>
                        </v-chip>
                    </template>
                    <template slot="prepend-inner">
                        <span class="prepend-text">{{ $t('global.from') }}</span>
                    </template>
                </v-select>

                <div class="action-buttons-top">
                    <v-tooltip top>
                        <template #activator="{ on }">
                            <v-btn
                                :style="colorOfFieldExternal"
                                small
                                icon
                                v-on="on"
                                @click="setCurrentExternalState(!external)"
                            >
                                <v-icon size="13">
                                    {{ getStateIcon }}
                                </v-icon>
                            </v-btn>
                        </template>
                        {{ getStateText }}
                    </v-tooltip>

                    <div class="action-buttons-top-right">
                        <div class="vertical-line"></div>
                        <div :style="toolbarStyle" class="function-buttons">
                            <v-btn
                                v-if="tiptapState !== 'full'"
                                icon
                                small
                                class="round-btn-style"
                                color="white"
                                @click="fullTiptap"
                            >
                                <v-icon size="13">mdi-arrow-expand</v-icon>
                            </v-btn>

                            <v-btn
                                v-if="tiptapState !== 'open'"
                                icon
                                small
                                class="round-btn-style"
                                color="white"
                                @click="openTiptap"
                            >
                                <v-icon size="13">mdi-window-minimize</v-icon>
                            </v-btn>

                            <v-btn icon small class="round-btn-style close-btn" color="white" @click="close">
                                <v-icon size="13">mdi-close</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
            <div class="second-row">
                <div v-if="!showCcs" class="to-container">
                    <EmailMulti
                        v-model="toRecipients"
                        :dense="true"
                        :bind="comboProps"
                        :validate="true"
                        :queueId="selectedQueueId"
                    >
                        <template slot="prepend-inner">
                            <span class="prepend-text">{{ $t('global.to') }}</span>
                        </template>
                    </EmailMulti>
                </div>

                <div v-else class="to-cc-bcc-container">
                    <EmailMulti
                        v-model="toRecipients"
                        :dense="true"
                        :bind="comboProps"
                        :validate="true"
                        :queueId="selectedQueueId"
                    >
                        <template slot="prepend-inner">
                            <span class="prepend-text">{{ $t('global.to') }}</span>
                        </template>
                    </EmailMulti>
                    <EmailMulti
                        v-model="ccRecipients"
                        :dense="true"
                        :bind="comboProps"
                        :validate="true"
                        :queueId="selectedQueueId"
                    >
                        <template slot="prepend-inner">
                            <span class="prepend-text">{{ $t('createComments.copy') }}</span>
                        </template>
                    </EmailMulti>

                    <EmailMulti
                        v-model="bccRecipients"
                        :dense="true"
                        :bind="comboProps"
                        :validate="true"
                        :queueId="selectedQueueId"
                    >
                        <template slot="prepend-inner">
                            <span class="prepend-text blind-copy">{{ $t('createComments.blindCopy') }}</span>
                        </template>
                    </EmailMulti>
                </div>
                <div>
                    <v-tooltip top>
                        <template #activator="{ on }">
                            <v-btn
                                :style="colorOfFieldExternal"
                                icon
                                small
                                :disabled="ccButtonDisabled"
                                v-on="on"
                                @click="toggleShowCss()"
                            >
                                <v-icon size="15">mdi-file-send-outline</v-icon>
                            </v-btn>
                        </template>
                        {{ $t('createComments.ccAndBcc') }}
                    </v-tooltip>
                </div>
            </div>
        </section>
        <section class="subject">
            <v-text-field
                v-model="subject"
                v-bind="textFieldProps"
                :disabled="isBasic || !userSettings.cases.editSubject.active || isAnswering"
                dense
            />
            <div v-if="!external || isSocial" class="action-buttons-top">
                <v-tooltip top>
                    <template #activator="{ on }">
                        <v-btn
                            :style="colorOfFieldExternal"
                            small
                            icon
                            v-on="on"
                            @click="setCurrentExternalState(!external)"
                        >
                            <v-icon size="13">
                                {{ getStateIcon }}
                            </v-icon>
                        </v-btn>
                    </template>
                    {{ getStateText }}
                </v-tooltip>

                <div class="action-buttons-top-right">
                    <div class="vertical-line"></div>
                    <div :style="toolbarStyle" class="function-buttons">
                        <v-btn
                            v-if="tiptapState !== 'full'"
                            icon
                            small
                            class="round-btn-style"
                            color="white"
                            @click="fullTiptap"
                        >
                            <v-icon size="13">mdi-arrow-expand</v-icon>
                        </v-btn>

                        <v-btn
                            v-if="tiptapState !== 'open'"
                            icon
                            small
                            class="round-btn-style"
                            color="white"
                            @click="openTiptap"
                        >
                            <v-icon size="13">mdi-window-minimize</v-icon>
                        </v-btn>

                        <v-btn icon small class="round-btn-style close-btn" color="white" @click="close">
                            <v-icon size="13">mdi-close</v-icon>
                        </v-btn>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import commentsHelper from '@/helpers/comments/comments.helper';

    export default {
        components: {
            EmailMulti: () => import('@/components/Main/Selects/EmailMulti.vue'),
        },
        data() {
            return {
                clientEmails: [],

                textFieldProps: {
                    elevation: 0,
                    ripple: false,
                    color: 'white',
                    solo: true,
                    'hide-details': true,
                    outlined: true,
                    dense: true,
                    flat: true,
                },

                comboProps: {
                    elevation: 0,
                    ripple: false,
                    color: 'white',
                    solo: true,
                    'hide-details': true,
                    outlined: true,
                    dense: true,
                    multiple: true,
                    chips: true,
                    flat: true,
                },
                selectProps: {
                    elevation: 0,
                    ripple: false,
                    color: 'white',
                    solo: true,
                    'hide-details': true,
                    outlined: true,
                    dense: true,
                    chips: true,
                    flat: true,
                },
            };
        },

        computed: {
            ...mapState({
                userSettings: (state) => state.System.userSettings,
                caseToLoad: (state) => state.Cases.caseToLoad,
                systemEmails: (state) => state.Cases.systemEmails.map((email) => email.toLowerCase()),
                external: (state) => state.Comments.currentExternalState,
                showCcs: (state) => state.Comments.showCcs,
                tiptapState: (state) => state.Comments.tiptapState,
                currentExternalState: (state) => state.Comments.currentExternalState,
                tiptapHeight: (state) => state.Comments.tiptapHeight,
                selectedCase: (state) => state.Cases.selectedCase,
                queues: (state) => state.Cases.caseFilters.queues,

                // Emails
                emailToRecipients: (state) => state.Comments.emailToRecipients,
                emailCcRecipients: (state) => state.Comments.emailCcRecipients,
                emailBccRecipients: (state) => state.Comments.emailBccRecipients,
                subjectStore: (state) => state.Comments.subject,
                isForwarding: (state) => state.Comments.isForwarding,
                repliedComment: (state) => state.Comments.repliedComment,
            }),

            ...mapGetters({
                isBasic: 'Cases/isBasic',
                getLatestEmailComment: 'Comments/getLatestEmailComment',
            }),

            selectedQueueId() {
                return this.selectedCase?.case.queueId;
            },

            isSocial() {
                return this.selectedCase?.case?.internalType === 'social';
            },

            // Emails to

            subject: {
                get() {
                    return this.subjectStore;
                },
                set(value) {
                    this.setSubject(value);
                },
            },
            emailFrom: {
                get() {
                    return this.$store.state.Comments.emailFrom;
                },
                set(value) {
                    this.$store.dispatch('Comments/setEmailFrom', value);
                },
            },
            toRecipients: {
                get() {
                    return this.emailToRecipients;
                },
                set(value) {
                    this.$store.dispatch('Comments/setEmailToRecipients', value);
                },
            },
            ccRecipients: {
                get() {
                    return this.emailCcRecipients;
                },
                set(value) {
                    this.$store.dispatch('Comments/setEmailCcRecipients', value);
                },
            },
            bccRecipients: {
                get() {
                    return this.emailBccRecipients;
                },
                set(value) {
                    this.$store.dispatch('Comments/setEmailBccRecipients', value);
                },
            },
            colorOfFieldExternal() {
                return {
                    backgroundColor: this.external ? '#E1F1F5' : '#EFEFEF',
                };
            },
            getStateIcon() {
                return this.external ? 'mdi-email' : 'mdi-comment-text-outline';
            },

            getStateText() {
                return this.external ? this.$t('createComments.external') : this.$t('createComments.internal');
            },
            caseRespondent() {
                return this.selectedCase?.case.respondent;
            },
            ccButtonDisabled() {
                return !this.external || Boolean(this.ccRecipients.length) || Boolean(this.bccRecipients.length);
            },
            isAnswering() {
                return Boolean(this.repliedComment);
            },
            toolbarStyle() {
                return {
                    cursor: this.tiptapState === 'open' ? 'ns-resize' : 'default',
                    transition: 'height 0.2s ease',
                };
            },
        },

        watch: {
            //  When case is loaded
            selectedCase: {
                handler(newValue, oldValue) {
                    if (oldValue && newValue.case.caseId !== oldValue.case.caseId) {
                        this.caseChanged(newValue);
                    }
                    this.retrieveWrittenSubject();
                },
                deep: true,
                immediate: true,
            },
        },

        beforeDestroy() {
            this.ccRecipients = [];
            this.bccRecipients = [];
        },
        methods: {
            ...mapActions({
                setCurrentExternalState: 'Comments/setCurrentExternalState',
                setShowCCs: 'Comments/setShowCCs',
                setSubject: 'Comments/setSubject',
                setIsForwarding: 'Comments/setIsForwarding',
                setRepliedComment: 'Comments/setRepliedComment',
                clearRepliedComment: 'Comments/clearRepliedComment',
                openTiptap: 'Comments/openTiptap',
                closeTiptap: 'Comments/closeTiptap',
                fullTiptap: 'Comments/fullTiptap',
            }),

            toggleShowCss() {
                this.setShowCCs(!this.showCcs);
            },

            // Validation:

            validateEmail(mail) {
                const re = /\S+@\S+\.\S+/;
                return re.test(mail);
            },

            caseChanged(updatedCase) {
                // If we update the case with a event we shouldn't reset tiptap
                if (this.selectedCase?.event) {
                    return;
                }

                let newSender = null;
                let newRecipients = null;

                const latestEmailComment = this.getLatestEmailComment;

                if (latestEmailComment) {
                    newSender = commentsHelper.getSenderFromEmail(latestEmailComment);
                    newRecipients = commentsHelper.getRecipientsFromEmail({
                        email: latestEmailComment,
                        systemEmails: this.systemEmails,
                    });
                }

                /* Get the default sender email based on the current queue */
                const { queueId } = updatedCase.case;
                const currentQueue = this.queues.find((queue) => queue.ID === queueId);

                const { defaultSenderEmail } = currentQueue;

                const repliedComment = latestEmailComment?.id || null;
                this.setRepliedComment(repliedComment);

                this.ccRecipients = [];
                this.bccRecipients = [];
                this.emailFrom = newSender || defaultSenderEmail || this.systemEmails[0];
                this.toRecipients = newRecipients || this.emailToRecipients || [];
            },

            retrieveWrittenSubject() {
                if (this.selectedCase?.event) {
                    return;
                }

                // 1. Check if the user has enabled the feature
                if (!this.userSettings?.cases?.editSubject?.active) {
                    // Set the subject to the current subject of the case
                    this.subject = this.selectedCase?.case.topic;
                    return;
                }

                // 2. get the comment with the highest date time
                try {
                    const latestEmailComment = this.selectedCase?.comments.find(
                        (comment) => comment.typeOfMessage === 'email'
                    );

                    if (latestEmailComment) {
                        this.subject = latestEmailComment.subject;
                        return;
                    }
                } catch (error) {
                    console.error('Error in retrieveWrittenSubject', error, new Date());
                }

                // 3. Check if the case has a topic
                if (this.selectedCase?.case.topic) {
                    this.subject = this.selectedCase.case.topic;
                    return;
                }

                // 4. Set the subject to empty as a fallback
                this.subject = '';
            },

            close() {
                this.closeTiptap();
                this.clearRepliedComment();
            },
        },
    };
</script>

<style scoped>
    .tiptap-container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 8px;
    }
    .from-to {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .first-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }
    .subject {
        display: flex;
        align-items: center;
        gap: 8px;
    }
    .action-buttons-top {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .action-buttons-top-right {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .vertical-line {
        border-left: 0.8px solid rgba(26, 25, 25, 0.234);
        height: 25px;
        margin: 0 8px;
    }
    .function-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }
    .round-btn-style {
        background-color: var(--v-primary-base);
    }

    .close-btn {
        z-index: 100;
    }

    .prepend-text {
        font-size: 10px;
        color: var(--v-gray2-base);
    }

    .blind-copy {
        width: 26px;
    }

    .second-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }
    .to-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 8px;
        width: 100%;
    }
    .to-cc-bcc-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;
        width: 100%;
    }
</style>

<i18n lang="json">
{
    "en": {
        "createComments": {
            "external": "External",
            "internal": "Internal",
            "from": "From",
            "recipient": "Recipient",
            "subject": "Subject",
            "ccAndBcc": "CC and BCC",
            "copy": "Copy",
            "blindCopy": "Blind copy"
        }
    },
    "sv": {
        "createComments": {
            "external": "Extern",
            "internal": "Intern",
            "from": "Från",
            "recipient": "Mottagare",
            "subject": "Ämne",
            "ccAndBcc": "CC och BCC",
            "copy": "Kopia",
            "blindCopy": "Hemlig kopia"
        }
    }
}
</i18n>
