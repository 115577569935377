<template>
    <IntegrationButton
        :active="shouldDisplayIntegrationButton"
        :integration-name="integration.name"
        @click="handleClick"
    />
</template>
<script lang="ts">
    import IntegrationButton from './IntegrationButton.vue';
    import api from '@/api/integrations/integration';

    interface FormInput {
        type: string;
        name: string;
        value?: string;
    }

    interface Integration {
        name: string;
        active: boolean;
    }

    interface ClientCard {
        socialSecurityNumber?: string;
        name?: string;
        primaryTelephone?: string;
        primaryEmail?: string;
        address?: string;
        post?: string;
        city?: string;
    }

    export default {
        components: { IntegrationButton },
        props: {
            integration: {
                type: Object as () => Integration,
                required: true,
            },
            clientCard: {
                type: Object as () => ClientCard | null,
                default: null,
            },
        },
        data() {
            return {
                formInputs: [
                    { type: 'text', name: 'InjuryNumber', value: 'SK-123456' },
                    { type: 'text', name: 'InjuryDescription', value: 'Bokning från Customer First.' },
                    { type: 'hidden', name: 'PersonalInformation.SocialSecurityNumber' },
                    { type: 'hidden', name: 'PersonalInformation.Name' },
                    { type: 'hidden', name: 'PersonalInformation.Phone' },
                    { type: 'hidden', name: 'PersonalInformation.Email' },
                    { type: 'hidden', name: 'PersonalInformation.Address.Street' },
                    { type: 'hidden', name: 'PersonalInformation.Address.ZipCode' },
                    { type: 'hidden', name: 'PersonalInformation.Address.City' },
                    { type: 'hidden', name: 'PersonalInformation.AdditionalInformation[0].Key', value: 'Arbetsgivare' },
                    { type: 'hidden', name: 'PersonalInformation.AdditionalInformation[0].Value', value: 'Kuralink' },
                    { type: 'hidden', name: 'AutoSubmit', value: 'false' },
                ] as FormInput[],
                postCodes: [] as string[],
                url: '',
            };
        },
        computed: {
            active(): boolean {
                return Boolean(this.integration.active);
            },
            shouldDisplayIntegrationButton(): boolean {
                // @ts-ignore: vuex doesnt support ts - therefore we ignore.
                const clientPost = this.$store.state.Client.currentSelectedClient?.post;
                const formattedPost = clientPost?.replace(/\s/g, '') || '';
                return this.active && this.matchPostCode(formattedPost);
            },
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                if (!this.active) return;

                const result = await this.initBooking();

                if (!result) return;

                const { url, postCodes } = result.data;

                this.url = url;
                this.postCodes = postCodes.filter(Boolean); // * in case of empty strings
            },
            async initBooking() {
                try {
                    return await api.initBooking();
                } catch (error) {
                    console.error('Failed to get Kuralink settings', error);
                    return null;
                }
            },
            handleClick() {
                this.createObjectWithFormData();
            },
            createObjectWithFormData() {
                const form = document.createElement('form');
                form.action = this.url;
                form.method = 'POST';
                form.target = '_blank';

                for (const input of this.formInputs) {
                    const element = document.createElement('input');
                    element.type = input.type;
                    element.name = input.name;
                    element.value = this.getInputValue(input.name, input.value);
                    form.appendChild(element);
                }

                const submitButton = document.createElement('input');
                submitButton.type = 'submit';
                submitButton.value = 'Submit';
                form.appendChild(submitButton);

                form.style.display = 'none';
                document.body.appendChild(form);

                form.submit();
            },
            getInputValue(key: string, value?: string): string {
                const clientCardField = this.mapClientCardField(key);
                if (!clientCardField) {
                    return value || '';
                }
                return clientCardField;
            },
            mapClientCardField(key: string): string | undefined {
                switch (key) {
                    case 'PersonalInformation.SocialSecurityNumber':
                        return this.clientCard?.socialSecurityNumber;
                    case 'PersonalInformation.Name':
                        return this.clientCard?.name;
                    case 'PersonalInformation.Phone':
                        return this.clientCard?.primaryTelephone;
                    case 'PersonalInformation.Email':
                        return this.clientCard?.primaryEmail;
                    case 'PersonalInformation.Address.Street':
                        return this.clientCard?.address;
                    case 'PersonalInformation.Address.ZipCode':
                        return this.clientCard?.post;
                    case 'PersonalInformation.Address.City':
                        return this.clientCard?.city;
                    default:
                        return undefined;
                }
            },
            matchPostCode(formattedPost: string): boolean {
                return this.postCodes.some((postCode) => {
                    // Escape special regex characters in the postCode, except for '*'
                    const escapedPostCode = postCode.replace(/[-/\\^$+?.()|[\]{}]/g, '\\$&').replace(/\*/g, '.*');
                    // Create a regex from the escaped postCode
                    const regex = new RegExp(`^${escapedPostCode}$`);
                    // Test the formattedPost against the regex
                    return regex.test(formattedPost);
                });
            },
        },
    };
</script>
