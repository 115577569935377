<template>
    <section class="write-action-container">
        <section>
            <WriteContainerChevron :active="expanded" @click="toggleExpand" />
        </section>

        <section v-if="expanded" class="write-action-buttons">
            <WriteContainerType icon="mdi-form-select" :tooltip="formTooltip" @click="openCreateForm" />
        </section>

        <primary-action-button
            color="var(--v-primary-base)"
            fontColor="white"
            :hoverEffect="true"
            @click="$emit('click')"
        >
            <div class="answer-action-button">
                <i class="icon mdi mdi-pencil"></i>
                <span>{{ $t('writeContainer.write') }}</span>
            </div>
        </primary-action-button>
    </section>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import WriteContainerChevron from './WriteContainerChevron.vue';
    import WriteContainerType from './WriteContainerType.vue';

    import { useFormStore } from '@/store/pinia/form.store';
    import { mapState, mapActions } from 'pinia';

    export default defineComponent({
        name: 'WriteContainer',

        components: {
            WriteContainerChevron,
            WriteContainerType,
        },

        data() {
            return {
                expanded: false,
            };
        },

        methods: {
            ...mapActions(useFormStore, ['fetchInternalForms']),

            toggleExpand() {
                this.expanded = !this.expanded;
            },

            async openCreateForm() {
                await this.fetchInternalForms();
                this.createForm();
            },

            async createForm() {
                // @ts-ignore - This is a global method
                await this.$global.dialogs.showDialog({
                    title: this.$t('writeContainer.createForm'),
                    size: 'large',
                    component: () => import('@/components/Forms/Dialogs/FormIFrame.vue'),
                    header: () => import('@/components/Forms/Dialogs/FormIFrameHeader.vue'),
                    props: {
                        type: 'createForm',
                    },
                });
            },
        },

        computed: {
            ...mapState(useFormStore, ['selectedForm']),

            formTooltip() {
                return this.$t('writeContainer.formTooltip') as string;
            },
        },
    });
</script>

<style scoped>
    .icon {
        font-size: 20px;
    }

    .write-action-container {
        position: absolute;
        right: 32px;
        bottom: 32px;
        z-index: 10;

        height: 48px;
        border-radius: 24px;
        display: flex;
        align-items: center;
        box-shadow:
            0px 8px 8px 0px rgba(0, 0, 0, 0.1),
            0px 0px 4px 0px rgba(0, 0, 0, 0.15);
        padding: 4px 4px 4px 8px;
        backdrop-filter: blur(2px);
        gap: 4px;
    }

    .answer-action-button {
        display: grid;
        grid-template-columns: auto auto;
        gap: 8px;
    }

    .answer-action-button > * {
        align-self: center;
    }

    .write-action-buttons {
        display: flex;
        gap: 4px;
        margin-right: 8px;
    }

    .answer-action-button span {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }
</style>

<i18n lang="json">
{
    "en": {
        "writeContainer": {
            "write": "Write",
            "formTooltip": "Create a form",
            "createForm": "Create form"
        }
    },
    "sv": {
        "writeContainer": {
            "write": "Skriv",
            "formTooltip": "Skapa ett formulär",
            "createForm": "Skapa formulär"
        }
    }
}
</i18n>
