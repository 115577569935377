<template>
    <span>{{ title }}</span>
</template>
<script lang="ts">
    import { defineComponent, type PropType } from 'vue';

    export default defineComponent({
        props: {
            title: {
                type: String,
                required: true,
            },
        },
    });
</script>
<style scoped>
    span {
        color: var(--Color-Neutrals-10, #3f3f3f);

        /* Body/Body S 600 */
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }
</style>
