<template>
    <div>
        <ConfirmationPromiseDialog ref="confirmationDialog" />
        <v-divider />
        <v-sheet v-if="errored" height="200px" class="d-flex align-center justify-center">
            {{ $t('somethingWentWrong', { source }) }}
        </v-sheet>
        <v-sheet v-else-if="!hasExternalRelation" height="200px" class="d-flex flex-column align-center justify-center">
            <div class="mb-3">{{ $t('notExistingCase', { source }) }}</div>
            <v-btn color="primary" @click="createCaseInIntegration">{{ $t('createCase', { source }) }}</v-btn>
        </v-sheet>
        <v-sheet v-else-if="loading" height="200px" class="d-flex align-center justify-center">
            <v-progress-circular size="50" indeterminate color="primary" />
        </v-sheet>
        <v-list v-else height="200px" class="scroll align-center justify-center">
            <v-list-item-group v-if="!loading">
                <template v-if="!filteredQueues.length">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('noQueuesAvailable') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <template v-else>
                    <v-list-item
                        v-for="(queue, i) in filteredQueues"
                        :key="i"
                        :prepend-icon="queue.id === selectedQueue?.id ? 'mdi-check' : ''"
                        @click="selectQueue(queue)"
                        :disabled="queue.disabled"
                    >
                        <v-list-item-content>
                            <v-list-item-title>{{ queue.name }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list-item-group>
        </v-list>
        <v-divider />
        <div class="d-flex justify-end align-center pa-2">
            <span v-if="selectedQueue" class="mr-2">
                <strong>{{ $t('assignExternally') }}:</strong>
                {{ selectedQueue.name }}
            </span>
            <v-btn color="primary" :disabled="!selectedQueue" @click="save">
                <span>{{ $t('save') }}</span>
                <v-icon>mdi-content-save</v-icon>
            </v-btn>
        </div>
    </div>
</template>
<script>
    import ConfirmationPromiseDialog from '@/components/Global/ConfirmationPromiseDialog.vue';

    export default {
        components: { ConfirmationPromiseDialog },
        props: {
            source: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                loading: false,
                errored: false,
                queues: [],
                externalRelation: null,
                selectedQueue: null,
            };
        },
        computed: {
            caseId() {
                return this.$store.state.Cases.selectedCase.case.caseId;
            },
            hasExternalRelation() {
                return this.$store.state.Cases.selectedCase.case.caseIdRelations.some(
                    (relation) => relation.source.toLowerCase() === this.source.toLowerCase()
                );
            },
            caseIsInLime() {
                return this.$store.state.Cases.selectedCase.case.internalSubType === 'lime_locked';
            },
            filteredQueues() {
                const main = this.queues.find((queue) => queue.primary);

                if (!main) {
                    return this.queues;
                }

                // * if the case isnt in lime, it should already be in the primary queue
                // * therefore we disable the primary queue to prevent the user from selecting it
                if (!this.caseIsInLime) {
                    main.disabled = true;
                }
                return this.queues;
            },
        },
        mounted() {
            this.getQueues();
        },
        methods: {
            async getQueues() {
                this.loading = true;
                try {
                    this.queues = await this.$store.dispatch('Integrations/getQueues');

                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    this.errored = true;
                    console.error(error);
                }
            },
            selectQueue(queue) {
                this.selectedQueue = queue;
            },
            async save() {
                const { confirmed } = await this.$refs.confirmationDialog.show({
                    title: this.$t('assignExternallyTitle'),
                    message: this.$t('assignExternallyText', { queue: this.selectedQueue.name, source: this.source }),
                    confirmText: this.$t('assignExternallyConfirm'),
                    declineText: this.$t('assignExternallyDecline'),
                });

                if (!confirmed) return;

                this.$store.dispatch('Integrations/setSelectedQueue', {
                    queueId: this.selectedQueue.id,
                    caseId: this.caseId,
                });

                this.$emit('close');
            },
            async createCaseInIntegration() {
                this.loading = true;
                await this.$store.dispatch('Integrations/createCaseInIntegration', {
                    caseId: this.caseId,
                    source: this.source,
                });
                this.loading = false;
                this.$toasted.success(`Case created in ${this.source}`);
            },
        },
    };
</script>
<style scoped lang="scss">
    .scroll {
        overflow-y: auto;
    }
</style>
<i18n lang="json">
{
    "en": {
        "assignExternally": "Assign externally",
        "noQueuesAvailable": "No queues available",
        "save": "Save",
        "somethingWentWrong": "Something went wrong when fetching {source} queues. Please try again later.",
        "assignExternallyTitle": "Assign externally",
        "assignExternallyText": "Are you sure you want to assign the case to the queue: {queue}? Doing this will disable the case in C1 and must be handled in {source}.",
        "assignExternallyConfirm": "Assign",
        "assignExternallyDecline": "Cancel",
        "notExistingCase": "This case is not related to {source}",
        "createCase": "Create case in {source}"
    },
    "sv": {
        "assignExternally": "Tilldela externt",
        "noQueuesAvailable": "Inga köer tillgängliga",
        "save": "Spara",
        "somethingWentWrong": "Något gick fel vid hämtning av {source}-köerna. Försök igen senare.",
        "assignExternallyTitle": "Tilldela externt",
        "assignExternallyText": "Är du säker på att du vill tilldela ärendet till kön: {queue}? Detta kommer att inaktivera ärendet i C1 och måste hanteras i {source}.",
        "assignExternallyConfirm": "Tilldela",
        "assignExternallyDecline": "Avbryt",
        "notExistingCase": "Detta ärende är inte relaterat till {source}",
        "createCase": "Skapa ärende i {source}"
    }
}
</i18n>
