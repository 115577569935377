<template>
    <v-tooltip top>
        <template #activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
                <v-btn
                    fab
                    height="28"
                    width="28"
                    elevation="0"
                    :outlined="isButtonActive"
                    @mouseleave="(e) => e.target.blur()"
                    @click="action ? action() : sideBarView(state)"
                >
                    <v-icon v-if="!image" size="16">{{ mdiIcon || icon() }}</v-icon>
                    <img v-else width="16px" :src="image" />
                </v-btn>
            </div>
        </template>
        <span>{{ tooltipLabel || state }}</span>
    </v-tooltip>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        props: {
            shouldShowIframeButton: {
                type: Boolean,
                default: true,
            },
            iframeName: {
                type: String,
                default: null,
            },
            state: {
                type: String,
                default: 'default',
            },
            action: {
                type: Function,
                default: null,
            },
            image: {
                type: String,
                default: null,
            },
            tooltipLabel: {
                type: String,
                default: null,
            },
            mdiIcon: {
                type: String,
                default: null,
            },
        },
        computed: {
            ...mapState('Cases', {
                sideBarViewState: (state) => state.sideBarView,
                selectedIframe: (state) => state.selectedIframe,
            }),
            isButtonActive() {
                return this.state === 'iframe'
                    ? this.sideBarViewState === this.state && this.iframeName === this.selectedIframe
                    : this.sideBarViewState === this.state;
            },
        },
        methods: {
            ...mapActions('Cases', ['sideBarView', 'setIframe']),

            icon() {
                const stateIcons = {
                    default: 'mdi-briefcase',
                    fields: 'mdi-format-columns',
                    iframe: 'mdi-web',
                    // TODO-TEO: better icon
                    orders: 'mdi-list-status',
                };

                return stateIcons[this.state] || 'mdi-briefcase';
            },
        },
    };
</script>
