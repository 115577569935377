import { positionTypes } from '@/enums/cases.enums';
import { casesHeaders } from '@/templates/cases.template';
import { filterObjectsByKeys, getActiveFromSettings } from '@/utils';
import { format } from 'date-fns';
import i18n from '../../i18n';

export function keysAreEmpty(obj) {
    const keys = ['statuses', 'queues', 'channels', 'categories'];

    if (Object.keys(obj).length === 0) {
        return true;
    }

    for (const key of keys) {
        if (obj[key].length === 0) {
            return true;
        }
    }
    return false;
}

export function categorizeAndAddAttachments(comment, accumulated) {
    const acc = accumulated;
    if (comment.Outgoing === 1 && comment.Attachment.length > 0) {
        acc.outgoing.files.push(...comment.Attachment);
    } else {
        acc.incoming.files.push(...comment.Attachment);
    }
    return acc;
}
export function applyDateTimeToAttachments(attachments, datetime) {
    return attachments.map((attachment) => {
        return {
            ...attachment,
            date: format(new Date(datetime), 'yyyy-MM-dd HH:mm'),
        };
    });
}

export function getPositionClass(position, direction = 'row') {
    switch (true) {
        case position === positionTypes.LEFT && direction === 'row': {
            return 'justify-start';
        }
        case position === positionTypes.RIGHT && direction === 'row': {
            return 'justify-end';
        }
        case position === positionTypes.LEFT && direction === 'column': {
            return 'align-start';
        }
        case position === positionTypes.RIGHT && direction === 'column': {
            return 'align-end';
        }
        default: {
            console.log(`Position input: ${position} or direction: ${direction} is not valid:`);
            return '';
        }
    }
}

export function getFilteredHeaders(headersConfig, headersAmount) {
    const templateHeaders = casesHeaders;
    const formattedConfig = getActiveFromSettings(headersConfig);
    const activeHeaders = filterObjectsByKeys(formattedConfig, templateHeaders, 'id');
    let addedHeaders = 0;
    const filteredHeaders = activeHeaders
        .sort((a, b) => {
            return headersConfig[b.id].important - headersConfig[a.id].important;
        })
        .filter((header) => {
            if (headersConfig[header.id].important || addedHeaders < headersAmount) {
                addedHeaders++;
                return true;
            }
            return false;
        });
    return filteredHeaders.sort((a, b) => headersConfig[a.id].position - headersConfig[b.id].position);
}

export function getOptimalHeadersAmount(width) {
    return Math.floor(width / 100);
}

/**
 * Shows a toast message when the active user in a case changes.
 *
 * This function determines if a toast should be shown for a user who is currently in a case,
 * and gets either demoted to reading rights or promoted to writing rights.
 *
 * @param {Array} currentBusyCaseList - The current list of busy cases.
 * @param {Array} newBusyCaseList - The new list of busy cases.
 * @param {string} myUserId - The ID of the current user.
 * @param {Function} toastFunction - The function to call to show a toast.
 */

export function showActiveUserChangedToasts(currentBusyCaseList, newBusyCaseList, myUserId, toastFunction) {
    const myCaseId =
        currentBusyCaseList.find((caseItem) => caseItem.userId === myUserId)?.id ||
        newBusyCaseList.find((caseItem) => caseItem.userId === myUserId)?.id;

    const oldCaseUsers = currentBusyCaseList.filter((caseItem) => caseItem.id === myCaseId);
    const newCaseUsers = newBusyCaseList.filter((caseItem) => caseItem.id === myCaseId);

    const iHaveLeftCase = !newCaseUsers.some((caseItem) => caseItem.userId === myUserId);
    const iHaveEnteredCase = !oldCaseUsers.some((caseItem) => caseItem.userId === myUserId);
    const iAmStillInSameCase = !iHaveLeftCase && !iHaveEnteredCase;

    if (!iAmStillInSameCase) return;

    // eslint-disable-next-line unicorn/consistent-function-scoping
    const isCaseActiveForUser = (caseItem, userId) => caseItem.activeUser && caseItem.userId === userId;

    const iWasActiveUserInCase = currentBusyCaseList.some((caseItem) => isCaseActiveForUser(caseItem, myUserId));
    const iAmActiveUserInCase = newBusyCaseList.some((caseItem) => isCaseActiveForUser(caseItem, myUserId));
    const nothingChanged = iWasActiveUserInCase === iAmActiveUserInCase;

    if (nothingChanged) return;

    const iHaveBeenDemotedToReadingRights = iWasActiveUserInCase && !iAmActiveUserInCase;

    const agentName = '';
    let toastKey = '';

    if (iHaveBeenDemotedToReadingRights) {
        const userWhoDemotedMe = newBusyCaseList.find((caseItem) => caseItem.id === myCaseId && caseItem.activeUser);

        // When socket resubscribes this can be undefined which causes a toast to pop up saying "UNKNOWN IS NOW WORKING IN THIS CASE" even though it's only the user in the case
        if (!userWhoDemotedMe) {
            return;
        }
        toastKey = 'casesStore.demotedToReadingRights';
    } else {
        toastKey = 'casesStore.promotedToWritingRights';
    }

    const toastMessage = i18n.t(toastKey, {
        userName: agentName,
    });
    toastFunction(toastMessage, {
        icon: 'information',
        type: 'info',
    });
}

/**
 * This function devides the filters into two groups
 * Formats the queues by filtering out system queues, adding additional items, and transforming the structure.
 * @param {Array} queues - The array of queues to be formatted.
 * @returns {Array} - The formatted array of queues.
 */
export function formatQueues(queues) {
    return queues
        .filter((item) => !item.IsSystemQueue)
        .map((item) => {
            return {
                name: item.Name,
                ID: item.ID,
                color: 'primary',
                value: item.ID,
                icon: item.Icon || 'mdi-account-multiple',
                brand: item.Brand,
                defaultSenderEmail: item.DefaultSenderEmail,
            };
        });
}

/**
 * Format filter list order
 *
 * @param {Array} mappedFilters
 * @returns {Array}
 * */
/* */

/**
 * Modifies a string by making it camelcase
 * @param {string} value
 */
export function modifyString(value) {
    const stringValue = String(value);
    return `${stringValue.charAt(0).toLowerCase()}${stringValue.slice(1)}`;
}

/**
 * Get translations based on key
 *
 * @param {string} value - the value of the translation for example caseStatus.[value]
 * @param {string} key - the key of the i18n for example [key].status
 */

/**
 * Get translation based on key and value
 */
export function getTranslationByKeyValue(key, value) {
    switch (key) {
        case 'statuses': {
            return i18n.t(`caseStatus.${value}`);
        }
        case 'sortField': {
            return i18n.t(`caseSort.${value}`);
        }
        case 'channels': {
            return i18n.t(`caseTypes.${value === 'email' ? 'singular' : 'plural'}.${value}`);
        }

        default: {
            return i18n.t(`${key}.${value}`);
        }
    }
}

/**
 * Formats the list of filters buttons in a specific order based on headers.
 *
 * @param {object} headersConfig - the headers config
 * @param {object} caseFilters - the case filters object
 */

export function formatFilterListOrder(headersConfig, caseFilters) {
    if (!headersConfig || !caseFilters) {
        return [];
    }

    const filtersToHeaders = {
        statuses: 'status',
        channels: 'internaltype',
        users: 'profilepicture',
        categories: 'category',
        queues: 'group',
        brands: 'brand',
    };

    const newObject = {};

    for (const key in caseFilters) {
        const items = Array.isArray(caseFilters[key]) ? caseFilters[key] : [caseFilters[key]];
        const newArray = buildFilterArray(key, items);

        newObject[key] = {
            data: newArray,
            position: getPosition(key, filtersToHeaders, headersConfig),
        };
    }

    const sortedEntries = sortFilters(newObject);

    return sortedEntries.map(([key, value]) => ({
        key,
        value: value.data,
    }));
}

// This function builds an array of formatted filter data
function buildFilterArray(key, items) {
    if (!items) {
        return [];
    }
    return items.map((item) => {
        const text = getTextForKey(key, item);

        return {
            ...item,
            id: item.value || item.id,
            text,
        };
    });
}
// This function determines the text for a filter based on its key and item
function getTextForKey(key, item) {
    if (['statuses', 'channels'].includes(key)) {
        return getTranslationByKeyValue(key, item.value);
    }
    if (['sortField'].includes(key)) {
        return getTranslationByKeyValue(key, item.value);
    }
    return item.name;
}

// This function determines the position of a filter based on its key
function getPosition(key, filtersToHeaders, headersConfig) {
    const headerKey = filtersToHeaders[key];
    return headersConfig[headerKey] ? headersConfig[headerKey].position : null;
}

// This function sorts filter entries based on their positions
function sortFilters(filters) {
    return Object.entries(filters).sort(([, valueA], [, valueB]) => {
        const positionA = valueA.position;
        const positionB = valueB.position;
        if (positionA === null && positionB !== null) {
            return 1;
        }
        if (positionA !== null && positionB === null) {
            return -1;
        }
        if (positionA === null && positionB === null) {
            return 0;
        }
        return positionA - positionB;
    });
}

export function getDefaultFilters() {
    const defaultValues = {
        statuses: [],
        categories: [],
        channels: [],
        users: [],
        queues: [],
        brands: [],
        searchQuery: '',
        page: 1,
        items: 5,
        showUnansweredOnly: false,
        includeSubCases: false,
        sortField: 'internalType',
        sortDirection: 'ASC',
    };

    return defaultValues;
}

// allows import of whole file, import cases from './cases.helper'
// when we also export every function separately, we can import them like this: import { myFunction } from './cases.helper'
export default {
    getDefaultFilters,
    keysAreEmpty,
    categorizeAndAddAttachments,
    applyDateTimeToAttachments,
    getFilteredHeaders,
    getOptimalHeadersAmount,
    showActiveUserChangedToasts,
    formatQueues,
    formatFilterListOrder,
};
