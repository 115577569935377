import Vue from 'vue';
import VueI18n from 'vue-i18n';

import enLocale from './locales/en.json';
import svLocale from './locales/sv.json';

Vue.use(VueI18n);

function loadLocaleMessages() {
    const locales = {
        './en.json': enLocale,
        './sv.json': svLocale,
    };
    const messages = {};
    for (const key in locales) {
        const matched = key.match(/([\w-]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales[key];
        }
    }
    return messages;
}

export default new VueI18n({
    locale: import.meta.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: import.meta.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessages(),
    silentTranslationWarn: true,
});
