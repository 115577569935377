<template>
    <v-dialog v-if="dialog" v-model="dialog" v-bind="dialogAttrs" class="dialog" @click:outside="handleClickOutside">
        <v-card class="creator-dialog-container" cy-data="creator" :style="elementStyles.container">
            <header class="card-title-container">
                <round-icon-button large color="white" @click="toggleDialog" ref="close-btn">
                    mdi-close
                </round-icon-button>
            </header>

            <section class="header-selector" :style="elementStyles.header">
                <span class="dialog-title">{{ $t('creator.header') }}</span>
                <span class="description">{{ $t('creator.description') }}</span>
            </section>

            <section class="dialog-selector">
                <div class="selector-container">
                    <standard-select
                        :dense="false"
                        :items="creatorFormOptionsMapped"
                        :value="currentFormMapped"
                        :disabled="creatorFormOptionsMapped.length === 1"
                        single
                        @input="setForm"
                    />
                </div>
            </section>

            <v-divider />

            <v-card-text class="form-area" :style="elementStyles.formArea">
                <section ref="form">
                    <article v-if="formSelected" class="dialog-form">
                        <div
                            v-for="item of formItems"
                            :id="item.name"
                            :key="item.id"
                            class="grid-item-dialog-form"
                            :class="{ 'd-none': item.hidden }"
                            :style="item.style"
                        >
                            <label :for="item.name" :style="labelStyle(item)">
                                {{ $t('creator.' + item.label.toLowerCase()) }}
                            </label>
                            <div class="grid-item-dialog-component-container">
                                <component
                                    :is="item.component"
                                    :id="item.name"
                                    :cy-data="item.component"
                                    v-bind="item"
                                    :templateTypes="[item.type]"
                                    :existingContent="item.existingContent"
                                    :dense="false"
                                    placeholder=""
                                    label=""
                                    closeMenuOnClick
                                    :templateLanguage="templateLanguage"
                                    :signature="selectedSignature"
                                    :showSignature="userSettings.texteditor.showSignature.active"
                                    :queueId="selectedQueueId"
                                    :counter="true"
                                    :defaultInlineAttachments="true"
                                    :htmlOnly="item.html"
                                    :html="currentFormData.form.id !== '8'"
                                    @change="(value) => handleChange({ value, item })"
                                    @input="(value) => handleChange({ value, item })"
                                />
                            </div>
                            <div class="error-box">
                                <div :class="errorTextClasses(item)">{{ validationText(item) }}</div>
                            </div>
                        </div>
                    </article>
                    <v-divider v-if="formSelected" />
                    <footer v-if="formSelected" class="dialog-selector">
                        <div class="selector-container">
                            <primary-action-button
                                type="submit"
                                color="var(--v-primary-base)"
                                :hoverEffect="true"
                                fontColor="white"
                                cy-data="send"
                                :disabled="!valid"
                                :loading="loading"
                                :dense="false"
                                @click="post(valid)"
                            >
                                {{ $t(getSubmitKey(currentFormMapped.value)) }}
                            </primary-action-button>
                        </div>
                    </footer>
                </section>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import { creator } from '@/enums/creator.enums';
    import { getRule, getSubmitKey } from '../../../helpers/creator.helper';

    export default {
        name: 'Creator',

        data() {
            return {
                dialogAttrs: {
                    height: 'auto',
                    width: 'auto',
                    persistent: true,
                    scrollable: true,
                    transition: 'dialog-bottom-transition',
                },
                dialog: false,
                valid: false,
                creator,
                loading: false,
                EMAIL_SUBJECT_INPUT_ID: 44,
            };
        },

        computed: {
            ...mapState({
                dialogState: (state) => state.Creator.dialogState,
                creatorFormOptions: (state) => state.Creator.creatorFormOptions,
                currentForm: (state) => state.Creator.currentForm,
                currentFormData: (state) => state.Creator.currentFormData,
                queues: (state) => state.QueueManager.allQueues,
                selectedCase: (state) => state.Cases.selectedCase,
                signatureTemplates: (state) => state.Admin.signatureTemplates,
                userSettings: (state) => state.System.userSettings,
                dirty: (state) => state.Creator.dirty,
                listenForChange: (state) => state.Creator.listenForChange,
                subject: (state) => state.Comments.subject,
            }),

            ...mapGetters({
                isCallingActive: 'Integrations.handler/isCallingActive',
                isSmsActive: 'Integrations.handler/isSmsActive',
            }),

            // This is to make the items appear in the HTML structure in the correct order and therefore tab-index order will be correct
            formItems() {
                const inputs = structuredClone(this.currentFormData?.form?.inputs) || [];
                return inputs.sort((a, b) => {
                    const aStyle = a.style.gridArea.split(' / ')[0] || '99';
                    const bStyle = b.style.gridArea.split(' / ')[0] || '99';
                    return aStyle - bStyle;
                });
            },

            dontShowOtherMenuOptions() {
                return this.currentForm && !this.currentForm.selectable;
            },

            currentFormMapped() {
                if (!this.currentForm) {
                    return null;
                }
                return {
                    ...this.currentForm,
                    text: this.$t(`creator.${this.currentForm.translationKey}`),
                    value: this.currentForm.id,
                };
            },

            creatorFormOptionsMapped() {
                if (!this.creatorFormOptions) {
                    return null;
                }

                if (this.dontShowOtherMenuOptions) {
                    return this.creatorFormOptions
                        .filter((item) => item.id === this.currentForm.id)
                        .map((item) => {
                            return {
                                ...item,
                                text: this.$t(`creator.${item.translationKey}`),
                                value: item.id,
                                selectable: item.selectable,
                            };
                        });
                }

                return this.creatorFormOptions
                    .filter((item) => item.selectable)
                    .filter((item) => {
                        const CREATE_SMS_ID = 7;
                        const CREATE_CALL_ID = 10;
                        switch (item.id) {
                            case CREATE_SMS_ID: {
                                return this.isSmsActive;
                            }
                            case CREATE_CALL_ID: {
                                return this.isCallingActive;
                            }
                            default: {
                                return true;
                            }
                        }
                    })
                    .map((item) => {
                        return {
                            ...item,
                            text: this.$t(`creator.${item.translationKey}`),
                            value: item.id,
                            selectable: item.selectable,
                        };
                    });
            },

            selectedQueueLanguage() {
                if (!Array.isArray(this.currentFormData?.form?.inputs) || !this.queues) {
                    return null;
                }

                let queueId = this.currentFormData.form.inputs.find((input) => input.bind === 'queueId')?.value;
                queueId ??= this.selectedCase?.case?.queueId;

                return this.queues.find((q) => q.ID === queueId)?.Language ?? null;
            },

            formSelected() {
                return this.currentForm !== null;
            },

            elementStyles() {
                return {
                    header: {
                        transition: 'all 0.3s ease-in-out',
                        maxHeight: this.formSelected ? '0px' : '150px',
                        overflow: 'hidden',
                        marginTop: this.formSelected ? '0px' : '48px',
                    },
                    formArea: {
                        transition: 'all 0.3s ease-in-out',
                        backgroundColor: this.formSelected ? 'white' : '',
                        maxHeight: this.formSelected ? '100%' : '0px',
                    },
                    container: {
                        transition: 'all 0.3s ease-in-out',
                        backgroundColor: this.formSelected ? 'white' : '',
                        maxHeight: this.formSelected ? '1000px' : '300px',
                        overflow: 'hidden',
                    },
                };
            },
            signatureSelectInput() {
                return this.currentFormData.form.inputs.find(
                    (item) => item.type === 'SIGNATURE_SELECT' && item.value?.value
                );
            },
            selectedSignature() {
                if (!this.signatureSelectInput) {
                    return {};
                }

                const signature = this.signatureTemplates.find(
                    (template) => template.ID === this.signatureSelectInput.value.value
                );
                return signature || {};
            },

            selectedQueueId() {
                for (const item of this.currentFormData.form.inputs) {
                    const queueSelects = [
                        'EMAIL_QUEUE_SELECT',
                        'SMS_QUEUE_SELECT',
                        'CALL_QUEUE_SELECT',
                        'QUEUE_SELECT',
                    ];
                    if (!queueSelects.includes(item.type)) {
                        continue;
                    }
                    if (!item.value) {
                        continue;
                    }
                    return item.value;
                }
                return null;
            },
            templateLanguage() {
                return this.selectedQueueLanguage ?? null;
            },
        },

        watch: {
            dialogState: {
                handler() {
                    if (this.dialogState === creator.OPEN) {
                        this.valid = false;
                        this.dialog = true;
                    } else {
                        this.dialog = false;
                        this.setCurrentForm(null);
                    }

                    if (this.dialog) {
                        this.getCreatorFormOptions();
                    }
                },
                immediate: true,
            },
        },

        created() {
            this.getQueues();
        },
        mounted() {
            // Add event listener to detect Ctrl + Enter key combination
            document.addEventListener('keydown', this.handleKeyDown);
        },
        beforeDestroy() {
            // Remove event listener when component is destroyed
            document.removeEventListener('keydown', this.handleKeyDown);
        },

        methods: {
            getSubmitKey,
            ...mapActions({
                toggleDialog: 'Creator/toggleDialog',
                setCurrentForm: 'Creator/setCurrentForm',
                getCreatorFormOptions: 'Creator/getCreatorFormOptions',
                handleChangeStore: 'Creator/handleChange',
                postForm: 'Creator/postForm',
                getQueues: 'QueueManager/getAllQueues',
                setDirty: 'Creator/setDirty',
                setListenForChange: 'Creator/setListenForChange',
            }),

            setForm(event) {
                this.setDirty(false);
                this.setListenForChange(false);
                setTimeout(() => {
                    this.setListenForChange(true);
                }, 2000);
                this.setCurrentForm(event);
                const el = this.$refs['close-btn'];
                if (el) {
                    // This is to prevent the element focus from being lost
                    el.$el.focus();
                }
            },

            async handleClickOutside() {
                if (!this.dirty) {
                    this.toggleDialog();
                    return;
                }

                const { confirmed } = await this.$global.dialogs.showConfirmationDialog({
                    title: this.$t('creator.closeDialogHeader'),
                    message: this.$t('creator.closeDialogMessage'),
                    confirmText: this.$t('creator.leaveDialog'),
                    declineText: this.$t('global.btn.cancel'),
                });

                if (!confirmed) {
                    return;
                }
                this.toggleDialog();
            },

            async post(valid) {
                try {
                    this.loading = true;
                    await this.postForm(valid);
                } catch (error) {
                    this.loading = false;
                } finally {
                    this.loading = false;
                }
            },

            labelStyle(item) {
                const { valid } = this.checkValidation(item);

                return { color: valid ? 'var(--v-gray2-base)' : 'var(--v-error-base)' };
            },
            validationText(item) {
                const { valid, text } = this.checkValidation(item);
                if (!valid) {
                    return text || '';
                }
                return '';
            },
            validateForm() {
                for (const item of this.currentFormData.form.inputs) {
                    const { valid } = this.checkValidation(item);
                    if (!valid) {
                        return false;
                    }
                }
                return true;
            },
            handleChange({ value, item }) {
                if (this.listenForChange) {
                    this.setDirty(true);
                }
                // Update forms validation
                this.handleChangeStore({ value, item });

                // Validate the form
                this.valid = this.validateForm();
            },
            // Get all rules for the form item and validate
            checkValidation(item) {
                const ruleFunctions = this.getRule(item);
                const validations = ruleFunctions.map((ruleFunction) => ruleFunction(item.value));

                const falsyValidation = validations.find((validation) => {
                    return validation.valid === false;
                });

                if (falsyValidation) {
                    return falsyValidation;
                }
                return { valid: true };
            },

            errorTextClasses(item) {
                const { valid } = this.checkValidation(item);
                return { 'error-text': true, show: !valid, hide: valid };
            },

            getRule,
            handleKeyDown(event) {
                if (!this.dialog) return;

                if (
                    event.ctrlKey &&
                    event.key === 'Enter' &&
                    this.userSettings.cases.shortCuts.send.active &&
                    this.valid
                ) {
                    // Trigger click event on the button
                    this.post(this.valid);
                }
            },
        },
    };
</script>

<style scoped>
    .dialog-footer {
        display: grid;
        grid-template-columns: 1fr;
    }
    .grid-item-dialog-form {
        display: flex;
        justify-content: stretch;
        flex-direction: column;
        flex: 1;
    }

    .grid-item-dialog-component-container {
        display: flex;
        justify-content: stretch;
        flex-direction: column;
        width: 100%;
    }
    .selector-container {
        display: grid;
        grid-template-columns: 1fr;
        max-width: 300px;
        margin: 0 auto;
        width: 100%;
    }
    .dialog {
        border-radius: 50px;
    }
    .creator-dialog-container {
        max-width: 800px;
        max-height: 90vh;
        width: 90vw;
        height: auto;
    }
    .dialog-form {
        padding-top: 32px;
        padding-bottom: 32px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto;
        font-size: 1rem;
        color: var(--v-gray4-base);
        gap: 32px;
    }

    .dialog-title {
        font-size: 2.8rem;
        font-weight: 400;
        color: var(--v-gray4-base);
        padding: 0px;
        margin: 0px;
        margin-bottom: 16px;
    }

    .header-selector {
        padding: 0px;
        display: flex;
        align-items: center;
        flex-direction: column;
        user-select: none;
    }

    .card-title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;
        position: absolute;
        top: 0px;
        width: 100%;
    }

    .dialog-selector {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        padding: 32px 24px;
        gap: 16px;
    }

    .dialog-information {
        background-color: var(--v-gray3-base);
        color: var(--v-gray2-base);
        font-size: 0.9rem;
        border-radius: 8px;
        padding: 16px;
    }

    strong {
        color: var(--v-gray4-base);
    }

    :deep(.v-dialog) {
        border-radius: 32px;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2) !important;
        border: 1px solid #eee;
        padding: 0px;
        background-color: white;
    }

    .description {
        font-size: 1rem;
        color: var(--v-gray2-base);
    }

    label {
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 1.5;
        color: var(--v-gray2-base);
        margin-bottom: 8px;
        display: block;
        user-select: none;
    }

    .form-area {
        padding-bottom: 0px !important;
    }

    .error-text {
        font-size: 10px;
        color: var(--v-error-base);
        transition: all 0.3s ease;
        height: 10px;
    }

    .error-box {
        height: 10px;
    }
    .show {
        opacity: 1;
    }
    .hide {
        opacity: 0;
    }
</style>
