import { defineStore } from 'pinia';
import * as api from '@/api/forms/forms';

export const formTypes = Object.freeze({
    PUBLISHED: 'published',
    DRAFT: 'draft',
    ARCHIVED: 'archived',
});

export interface IForm extends ICreateForm {
    id: number;
    createdAt: Date;
    updatedAt: Date;
    publishedAt: Date | null;
    uniqueId: string;
}

export interface ICreateForm {
    parent?: number | null;
    type?: (typeof formTypes)[keyof typeof formTypes];
    title: string;
    description: string;
    src?: string | null;
    url: string | null;
    responseHeader?: string | null;
    responseText?: string | null;
    responseButton?: string | null;
    queueId?: number | null;
    primaryColor?: string | null;
    uniqueId?: string;
    isExternal?: number;
}

export const useFormStore = defineStore('form', {
    state: () => ({
        forms: [] as IForm[],
        selectedForm: null as IForm | null,
    }),

    actions: {
        async fetchInternalForms() {
            try {
                const response = await api.getInternalForms();
                this.forms = response.data.data as IForm[];

                if (this.forms.length > 0) {
                    this.selectedForm = this.forms[0];
                }
            } catch (error) {
                console.error(error);
            }
        },

        setSelectedFormById(id: number) {
            this.selectedForm = this.forms.find((form) => form.id === id) || null;
        },
    },
});
