<template>
    <dialog ref="dialog" class="modal-dialog" @close="handleClose">
        <slot name="header">
            <header v-if="header" class="modal-header" :class="{ 'with-return': returnButton }">
                <div class="left">
                    <!-- Return button -->
                    <slot v-if="returnButton" name="return-button">
                        <v-btn icon @click="$emit('return')"><v-icon>mdi-arrow-left</v-icon></v-btn>
                    </slot>
                    <h3>
                        <slot name="header-title"></slot>
                    </h3>
                </div>
                <v-btn icon color="black" @click="closeModal"><v-icon>mdi-close</v-icon></v-btn>
                <!-- Header content -->
            </header>

            <div v-if="headerDivider" class="divider"></div>
        </slot>
        <slot>
            <!-- The main content -->
        </slot>
        <!-- Footer divider -->
        <div v-if="footerDivider" class="divider"></div>
        <!-- Footer content -->
        <slot name="footer">
            <footer v-if="footer" class="modal-footer">
                <slot name="action-buttons"></slot>
            </footer>
        </slot>
    </dialog>
</template>

<script>
    export default {
        name: 'Dialog',
        props: {
            /**
             * Controls the visibility of the modal.
             * Use v-model to bind this prop from the parent component.
             */
            value: {
                type: Boolean,
                default: false,
            },
            header: {
                type: Boolean,
                default: false,
            },
            footer: {
                type: Boolean,
                default: false,
            },
            headerDivider: {
                type: Boolean,
                default: false,
            },
            footerDivider: {
                type: Boolean,
                default: false,
            },
            returnButton: {
                type: Boolean,
                default: false,
            },
        },
        watch: {
            value(newValue) {
                if (newValue) {
                    this.showModal();
                } else {
                    this.closeModal();
                }
            },
        },
        mounted() {
            if (this.value) {
                this.showModal();
            }
        },
        methods: {
            showModal() {
                if (this.$refs.dialog.open) return;
                this.$refs.dialog.showModal();
            },
            closeModal() {
                if (!this.$refs.dialog.open) return;
                this.$refs.dialog.close();
            },
            handleClose() {
                this.$emit('input', false);
            },
        },
    };
</script>

<style scoped>
    .modal-dialog {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 1em;
        border: none;
        overflow-x: hidden;
        box-shadow:
            0px 16px 16px 0px rgba(0, 0, 0, 0.1),
            0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    }

    .modal-header {
        display: flex;
        padding: 8px 8px 8px 24px;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        .left {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        h3 {
            color: black;

            /* Heading/Heading 3 */
            font-family: 'Roboto', sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px; /* 150% */
        }

        &.with-return {
            padding: 8px;
        }
    }

    .close-button {
        background: none;
        border: none;
        font-size: 1.5em;
        cursor: pointer;
    }

    .divider {
        width: 100%;
        height: 1px;
        background-color: var(--v-gray3-darken1);
    }

    .modal-footer {
        display: flex;
        padding: 12px 24px;
        justify-content: flex-end;
        align-items: center;
        gap: 16px;
        align-self: stretch;
    }
</style>
