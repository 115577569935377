<template>
    <section class="line-container">
        <v-tooltip v-if="isBusy || isSelected" right>
            <template #activator="{ on }">
                <article v-if="isSelected || isBusy" class="line" :class="lineClass" v-on="on"></article>
            </template>
            {{ busyText }}
        </v-tooltip>
    </section>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';

    interface CaseItem {
        caseId: string;
    }

    export default defineComponent({
        name: 'BusyLine',

        props: {
            item: {
                type: Object as PropType<CaseItem>,
                required: true,
            },

            isBusy: {
                type: Boolean,
                default: false,
            },

            isSelected: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            busyCaseList() {
                // @ts-ignore <This is going to be converted to Pinia store in the future>
                return this.$store.state.Cases.busyCaseList as { id: string; activeUser: boolean; userName: string }[];
            },

            lineClass() {
                if (this.isSelected) {
                    return 'selected';
                }
                return this.isBusy ? 'busy' : '';
            },

            busyText() {
                return this.$t('timeLine.workingCase', { name: this.working });
            },

            working() {
                const working = this.busyCaseList.find((e) => e.id === this.item.caseId && e.activeUser);
                return working ? working.userName : null;
            },
        },
    });
</script>

<style scoped>
    .line-container {
        position: absolute;
        left: 0;
        height: 50%;
        width: 3px;
        display: flex;
        justify-content: stretch;
        align-items: stretch;
    }

    .line {
        display: flex;
        flex: 1;
        background-color: black;
        width: 3px;
        border-radius: 0px 2px 2px 0px;
    }

    .busy {
        background-color: red;
    }

    .selected {
        background-color: black;
    }
</style>

<i18n lang="json">
{
    "en": {
        "timeLine": {
            "workingCase": "{name} is currently working on this case"
        }
    },
    "sv": {
        "timeLine": {
            "workingCase": "{name} jobbar i detta ärende just nu"
        }
    }
}
</i18n>
