<template>
    <div class="multiple-select-container">
        <section class="info-text">{{ $t('infoText') }}</section>
        <section class="fields">
            <IdentifierRow
                v-for="(identifier, index) in localIdentifiers"
                :key="index"
                v-model="localIdentifiers[index]"
                :identifier="identifier"
                :deleteButton="index > 1"
                @delete="deleteRow(index)"
            />
        </section>
        <button @click="addIdentifier">
            <i class="mdi mdi-plus button-icon" />
            {{ $t('global.add') }}
        </button>
    </div>
</template>
<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import type { WidgetIdentifier } from '@/types/widgets.types';
    import IdentifierRow from './IdentifierRow.vue';

    const DEFAULT_IDENTIFIER = {
        queueId: 0,
        channelIdentifier: '',
        categoryName: '',
    } as WidgetIdentifier;

    export default defineComponent({
        components: { IdentifierRow },
        props: {
            value: {
                type: Array as PropType<WidgetIdentifier[]>,
                required: true,
            },
        },
        computed: {
            localIdentifiers: {
                get(): WidgetIdentifier[] {
                    return this.value;
                },
                set(newVal: WidgetIdentifier[]) {
                    this.$emit('input', newVal);
                },
            },
        },
        created() {
            this.init();
        },
        methods: {
            init() {
                const needed = 2 - this.localIdentifiers.length;
                if (needed > 0) {
                    const newIdentifiers = [...this.localIdentifiers];
                    for (let i = 0; i < needed; i++) {
                        newIdentifiers.push({ ...DEFAULT_IDENTIFIER });
                    }
                    this.localIdentifiers = newIdentifiers;
                }
            },
            deleteRow(index: number): void {
                const updatedIdentifiers = this.localIdentifiers.filter((_, i) => i !== index);
                this.localIdentifiers = updatedIdentifiers;
            },
            addIdentifier() {
                this.localIdentifiers = [...this.localIdentifiers, DEFAULT_IDENTIFIER];
            },
        },
    });
</script>
<style scoped lang="scss">
    .multiple-select-container {
        display: flex;
        padding: 32px 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        align-self: stretch;

        .info-text {
            color: var(--Color-Neutrals-10, #3f3f3f);

            /* Body/Body S 400 */
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
        }

        .fields {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;

            max-height: 400px;
            overflow-y: scroll;
        }

        button {
            display: flex;
            height: 32px;
            min-width: 96px;
            padding: var(--Spacing-8, 8px);
            justify-content: center;
            align-items: center;
            gap: 4px;

            border-radius: 20px;
            border: 1px solid var(--Color-Neutrals-60, #bfbfbf);
            .button-icon {
                font-size: 20px;
            }
        }
    }
</style>
<i18n lang="json">
{
    "en": {
        "infoText": "When multiple dultiple dooplidoo"
    },
    "sv": {
        "infoText": "När flera köer har kopplats kan kunder välja bland kategorier innan de startar en chatt. Bestäm vilka kategorier kunden ska se och vilken kö de ska kopplas till här."
    }
}
</i18n>
