<template>
    <div>
        <!-- The modal-->
        <WidgetChannelRelationsModal v-model="showModal" />
        <!-- The button-->
        <v-btn
            class="mx-2 my-1"
            fab
            elevation="0"
            height="50"
            width="50"
            :color="buttonColor"
            @click="showModal = !showModal"
            icon
        >
            <v-icon>mdi-link-variant</v-icon>
        </v-btn>
    </div>
</template>
<script lang="ts">
    import WidgetChannelRelationsModal from './WidgetChannelRelationsModal/WidgetChannelRelationsModal.vue';
    import { WidgetIdentifier } from '../../types/widgets.types';
    import { defineComponent } from 'vue';

    export default defineComponent({
        components: {
            WidgetChannelRelationsModal,
        },
        data() {
            return {
                showModal: false,
            };
        },
        computed: {
            relations(): WidgetIdentifier[] {
                // @ts-ignore
                const relations = this.$store.state.BuilderMenu.objectToEdit.properties.main.channelRelations;
                return relations as WidgetIdentifier[];
            },
            hasRelation() {
                return this.relations.some((rel: WidgetIdentifier) => rel.channelIdentifier);
            },
            buttonColor() {
                return this.hasRelation ? 'primary' : '';
            },
        },
        mounted() {
            if (!this.hasRelation) {
                this.showModal = true;
            }
        },
    });
</script>
<style scoped></style>
<i18n lang="json">
{
    "en": {},
    "sv": {}
}
</i18n>
