<template>
    <SimpleTooltip bottom :tooltipText="item.status">
        <div class="circle" :style="{ backgroundColor: getStatusColor(item.status) }">
            <v-icon size="16" color="black">{{ statusIcons[item.status] }}</v-icon>
        </div>
    </SimpleTooltip>
</template>

<script>
    export default {
        components: {
            SimpleTooltip: () => import('@/components/Global/SimpleTooltip.vue'),
        },
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        computed: {
            statusIcons() {
                return {
                    Closed: 'mdi-tray-minus',
                    Open: 'mdi-tray',
                    Ongoing: 'mdi-tray-full',
                    Deleted: 'mdi-tray-remove',
                    InQueue: 'mdi-arrow-down',
                };
            },
            colors() {
                return this.$vuetify.theme.themes.light;
            },
        },
        methods: {
            getStatusColor(status) {
                switch (status) {
                    case 'Open': {
                        return this.colors.color4;
                    }
                    case 'Closed': {
                        return this.colors.color2;
                    }
                    case 'Ongoing': {
                        return this.colors.color1;
                    }
                    case 'Deleted': {
                        return this.colors.gray1;
                    }
                    case 'InQueue': {
                        return this.colors.color3;
                    }
                    default: {
                        return this.colors.gray1;
                    }
                }
            },
        },
    };
</script>
<style>
    .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        width: 24px;
        height: 24px;
    }
</style>
