/**
 * Class representing a DedupeRequest.
 *
 * Example:
 * ```js
 * // Callback function to be called when the request is sent.
 * async function callback(state, args, resolve, reject) {
 *   try {
 *     const res = await get(url, args);
 *     resolve(res);
 *   } catch (error) {
 *     reject(error);
 *   }
 * }
 * // Setup a new instance of dedupe request
 * // All dedupe requests are deduped based on the arguments given
 * // Default TTL is 1000ms
 * // TTL is the amount of time to cache a request before we can consider sending a new one, instead of returning the same request
 * const dedupeRequest = new DedupeRequest(callback);
 * // Returns a promise which resolves to whatever the callback function resolves to.
 * const res = await dedupeRequest.send(state, args);
 * console.log(res);
 * ```
 */

// biome-ignore lint/suspicious/noExplicitAny: This should be any since it is a bound for a generic and not a specific type
class DedupeRequest<TArgument extends object, TResult extends Promise<any>> {
    private TTL: number;
    private cb: (arg: TArgument) => TResult;
    private promises: Map<string, TResult>;
    /**
     * Create a DedupeRequest.
     * @param cb - The callback function to be called when the request is sent.
     * @param TTL - The TTL before deleting the request.
     */
    constructor(cb: (arg: TArgument) => TResult, TTL = 1000) {
        // TTL before deleting request
        this.TTL = TTL;
        // Callback for the promise created
        this.cb = cb;
        // Buffer for promises, stores them in the buffer until they are resolved and the TTL has passed
        // Stores each as a buffer object, in the form of { args, promise }
        this.promises = new Map();
    }

    /**
     * Cleans up the promise after a certain delay.
     * @param {Promise} promise - The promise to be cleaned up.
     * @param {string} args - The arguments passed to the promise.
     */
    async cleanupPromise(promise: TResult, args: string) {
        // Catch error an error to prevent uncaught promise errors in console
        // Any other function that uses this promise will catch the error
        // Await promise so we can start it's removal from the map after it was resolved
        try {
            await promise;
            // Remove the promise from the array after a certain delay, which gives us the possibility to dedupe requests
            // Any request sent from first request + dedupe TTL will have the same promise to await
            setTimeout(() => {
                // Find the promise
                const promiseBuffer = this.promises.get(args);
                // If the promise is still the same, delete it
                if (promiseBuffer) {
                    this.promises.delete(args);
                }
            }, this.TTL);
        } catch {
            // Immediately delete upon failure
            this.promises.delete(args);
        }
    }

    /**
     * The function used to send the request with deduplication logic.
     * Returns a promise that is either a new request, or an existing request.
     * Each check is done based on the arguments passed to the function.
     * Identical arguments are considered identical requests.
     * Each request is cached for a certain amount of time, which is the TTL.
     * If a request is sent after the TTL, it will be considered a new request.
     * TTL is only started upon resolution of the promise.
     * Therefore, cache time is request time + TTL.
     * @param {Object} state - The state of the requestee's store.
     * @param {Object} args - The arguments to be passed to the callback function.
     * @returns {Promise} - Response promise, if an existing identical request is found, it will return the same promise. Otherwise it will return a new promise.
     */
    send(args: TArgument): TResult {
        // Strinigify the args to be able to compare them
        const stringifiedArgs = JSON.stringify(args);

        // Check for existing promise, if so, return it so we don't send a duplicate request
        const cachedPromise = this.promises.get(stringifiedArgs);
        if (cachedPromise) {
            // Retrieve the promise
            return cachedPromise;
        }
        // Create a new promise with the cb function
        // This will be the promise that is returned
        // Meant to be used with axios get request
        const promise = this.cb(args);
        // Set the args for promise in the map
        this.promises.set(stringifiedArgs, promise);
        // Cleanup the promise, immediately returns a new promise to not block the return below
        this.cleanupPromise(promise, stringifiedArgs);
        // Return the promise
        return promise;
    }
}

export default DedupeRequest;
