<template>
    <section class="fields-container">
        <ClientField
            v-for="(item, key) in clientSelectedFiltered"
            :key="key"
            :field="key"
            :value="item.value"
            :disabled="isShadow || isReadOnly || !item.editable"
            :client="currentSelectedClient"
        />
    </section>
</template>
<script>
    import { mapState, mapGetters } from 'vuex';
    import ClientField from './ClientField.vue';
    import { getClientFieldsList } from '@/api/client/client.fields';

    export default {
        components: { ClientField },

        data() {
            return {
                clientsFieldsList: [],
            };
        },

        computed: {
            ...mapState({
                currentSelectedClient: (state) => state.Client.currentSelectedClient,
                clientNowOnCard: (state) => state.Cases.clientNowOnCard,
            }),

            ...mapGetters({
                isReadOnly: 'Cases/isReadOnly',
            }),

            isShadow() {
                return this.currentSelectedClient.type === 'shadow';
            },

            clientSelectedFiltered() {
                const currentSelectedClientFiltered = {};

                for (const field of this.clientsFieldsList) {
                    if (!currentSelectedClientFiltered[field.type]) {
                        currentSelectedClientFiltered[field.type] = {};
                    }
                    currentSelectedClientFiltered[field.type].value = this.currentSelectedClient[field.type];
                    currentSelectedClientFiltered[field.type].visible = field.visible;
                    currentSelectedClientFiltered[field.type].editable = field.editable;
                    currentSelectedClientFiltered[field.type].position = field.position;
                }

                return Object.keys(currentSelectedClientFiltered)
                    .filter((key) => currentSelectedClientFiltered[key].visible)
                    .sort(
                        (a, b) => currentSelectedClientFiltered[a].position - currentSelectedClientFiltered[b].position
                    )
                    .reduce((obj, key) => {
                        obj[key] = currentSelectedClientFiltered[key];
                        return obj;
                    }, {});
            },

            clientVisibleFields() {
                return this.clientNowOnCard.visibleFields;
            },
        },

        mounted() {
            this.fetchFields();
        },

        methods: {
            async fetchFields() {
                try {
                    const response = await getClientFieldsList();
                    this.clientsFieldsList = response.data;
                } catch (error) {
                    // No items will be shown if there is an error
                    console.error('Error fetching client fields', error);
                }
            },
        },
    };
</script>
<style lang="scss" scoped>
    .fields-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        grid-gap: 16px;
        padding: 16px;
        font-size: 12px;
        color: var(--v-gray1-base);
    }
</style>
