<template>
    <div :class="{ disabled: clickIsDisabled || disabled }">
        <standard-select :items="clientRelations" outlined searchable :clickableItems="false">
            <template #item-slot="{ item }">
                <div class="relations-item">
                    <div class="d-flex">
                        <div>
                            <v-tooltip top>
                                <template #activator="{ on }">
                                    <v-img :src="getImage(item.source)" width="20" class="mr-2" v-on="on"></v-img>
                                </template>
                                <span class="truncate-external">{{ item.source }}</span>
                            </v-tooltip>
                        </div>
                        <div>{{ item.externalId }}</div>
                    </div>

                    <!-- copy btn -->
                    <v-tooltip top>
                        <template #activator="{ on }">
                            <v-btn icon v-on="on" @click.stop="copyToClipboard(item.externalId)">
                                <v-icon small>mdi-content-copy</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('copy') }}</span>
                    </v-tooltip>
                </div>
            </template>

            <div class="slot-container">
                <secondary-action-button class="secondary-action-button-container">
                    <template>
                        <article v-if="firstItem" class="item-container">
                            <section>
                                <v-tooltip top>
                                    <template #activator="{ on }">
                                        <v-img :src="getImage(firstItem.source)" width="20" v-on="on"></v-img>
                                    </template>
                                    <span>{{ firstItem.source }}</span>
                                </v-tooltip>
                            </section>
                            <div class="truncate-external">
                                {{ firstItem.externalId }}
                            </div>
                            <v-chip v-if="clientRelations.length > 1" small>+{{ extraItems }}</v-chip>
                        </article>
                    </template>
                </secondary-action-button>
            </div>
        </standard-select>
    </div>
</template>
<script lang="ts">
    import { defineComponent } from 'vue';
    import clientApi from '@/api/client/client';
    import { getImage } from '@/utils/Integrations';

    export default defineComponent({
        name: 'ClientExternalIdsField',

        data() {
            return {
                clientRelations: Array<{
                    id: number;
                    source: string;
                    externalId: string;
                    value: string;
                    text: string;
                }>(),
            };
        },
        computed: {
            firstItem() {
                return this.clientRelations[0];
            },
            extraItems() {
                return this.clientRelations.length - 1;
            },
            clickIsDisabled() {
                return this.clientRelations.length <= 1;
            },
        },
        props: {
            clientId: {
                type: String,
                required: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        mounted() {
            this.getClientExternalIds();
        },
        methods: {
            async getClientExternalIds() {
                try {
                    const { data } = await clientApi.getClientRelations(this.clientId);

                    this.clientRelations = data.map(
                        (
                            item: {
                                externalId: string;
                                source: string;
                            },
                            index: number
                        ) => ({
                            ...item,
                            id: index,
                            value: item.externalId,
                            text: item.externalId,
                        })
                    );
                } catch (error) {
                    console.error(error);
                }
            },
            copyToClipboard(text: string) {
                navigator.clipboard.writeText(text);
                this.$toasted.success(this.$t('copiedToClipboard') as string);
            },
            getImage,
        },
    });
</script>

<style scoped>
    .truncate-external {
        max-width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .item-container {
        display: grid;
        grid-template-columns: auto 1fr auto;
        gap: 8px;
        flex: 1;
    }

    .item-container > * {
        align-self: center;
    }

    .slot-container {
        display: flex;
        flex: 1;
        justify-self: stretch;
        justify-content: stretch;
        align-items: stretch;
    }

    .secondary-action-button-container {
        flex: 1;
    }

    .relations-item {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: space-between;
        width: 100%;
    }
</style>
<i18n lang="json">
{
    "en": {
        "copy": "Copy to clipboard",
        "copiedToClipboard": "Copied to clipboard"
    },
    "sv": {
        "copy": "Kopiera till urklipp",
        "copiedToClipboard": "Kopierad till urklipp"
    }
}
</i18n>
