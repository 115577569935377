<template>
    <div class="identifier">
        <WidgetQueueSelect v-model="localIdentifier" />
        <TextInput
            v-model="categoryName"
            :placeholder="$t('categoryPlaceholder')"
            :label="$t('categoryLabel')"
            name="category"
        />
        <standard-button :disabled="!deleteButton" class="delete-btn" text @click="$emit('delete')">
            <v-icon color="red">mdi-delete</v-icon>
        </standard-button>
    </div>
</template>
<script lang="ts">
    import TextInput from '@/views/Statistics/ExportStatistics/Modals/TextInput.vue';
    import WidgetQueueSelect from './WidgetQueueSelect.vue';
    import { defineComponent, PropType } from 'vue';
    import type { WidgetIdentifier, WidgetQueue } from '../../../types/widgets.types';

    export default defineComponent({
        components: { WidgetQueueSelect, TextInput },
        emits: ['input', 'delete'],
        props: {
            value: {
                type: Object as PropType<WidgetIdentifier>,
                required: true,
            },
            deleteButton: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            values: {
                get(): WidgetIdentifier {
                    return this.value;
                },
                set(newVal: WidgetIdentifier) {
                    this.$emit('input', newVal);
                },
            },
            localIdentifier: {
                get() {
                    return this.values.queueId;
                },
                set(queue: WidgetQueue) {
                    this.values = {
                        ...this.values,
                        channelIdentifier: queue.RelationId,
                        queueId: queue.ID,
                    };
                },
            },
            categoryName: {
                get(): string {
                    return this.value.categoryName || '';
                },
                set(value: string) {
                    this.values = {
                        ...this.values,
                        categoryName: value,
                    };
                },
            },
        },
    });
</script>
<style scoped lang="scss">
    .identifier {
        display: flex;
        gap: 8px;
        align-self: stretch;

        > :first-child {
            flex: 1;
        }

        > :nth-child(2) {
            flex: 2;
        }

        .delete-btn {
            align-self: end;
        }
    }
</style>
<i18n lang="json">
{
    "en": {
        "categoryLabel": "Category",
        "categoryPlaceholder": "Name category"
    },
    "sv": {
        "categoryLabel": "Kategori",
        "categoryPlaceholder": "Namnge kategori"
    }
}
</i18n>
