<template>
    <div>
        <label for="widgetQueue">{{ $t('global.queue') }}</label>
        <select v-model="localValue" id="widgetQueue" name="widgetQueue" required>
            <option :value="0" disabled selected>{{ $t('global.select') }}...</option>
            <option v-for="q of widgetQueues" :key="q.ID" :value="q">
                {{ q.Name }}
            </option>
        </select>
    </div>
</template>
<script lang="ts">
    import { WidgetQueue } from '@/types/widgets.types';
    import { defineComponent, PropType } from 'vue';

    export default defineComponent({
        props: {
            value: {
                type: Number, // queueid
                default: null,
            },
        },
        computed: {
            values: {
                get() {
                    return this.widgetQueues.find((q) => q.ID === this.value) || 0;
                },
                set(val: WidgetQueue) {
                    this.$emit('input', val);
                },
            },
            localValue: {
                get() {
                    return this.values;
                },
                set(val: WidgetQueue) {
                    // this.$emit('input', val);
                    this.values = val;
                },
            },
            widgetQueues(): WidgetQueue[] {
                // @ts-ignore <This is going to be converted to Pinia store in the future>
                return this.$store.state.Widget.widgetQueues as WidgetQueue[];
            },
        },
    });
</script>
<style scoped lang="scss">
    input,
    label {
        display: block;
        font-weight: bold;
        color: black;
    }

    label {
        margin-bottom: 4px;
        color: var(--Color-Neutrals-0, #000);

        /* Body/Body S 600 */
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }

    input:disabled {
        opacity: 0.2;
    }

    select:has(option:disabled:checked),
    select option:disabled {
        color: var(--Color-Neutrals-60, #bfbfbf);
    }

    select {
        border-radius: 0.25em;
        border: 1px solid rgba(66, 66, 66, 0.5);
        padding: 0.5em;
        font-size: 12px;
        width: 100%;
        height: 40px;

        overflow: hidden;
        color: var(--Color-Neutrals-0, #000);
        text-overflow: ellipsis;

        /* Body/Body M 400 */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */

        &:focus-visible {
            outline: 1px solid black;
        }

        select,
        option {
            max-width: 50px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: var(--Color-Neutrals-0, #000);
        }

        &:required:invalid {
            color: #7d7d7d;
        }
    }
</style>

<i18n lang="json">
{
    "en": {},
    "sv": {}
}
</i18n>
