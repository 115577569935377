<template>
    <article :class="{ expanded: expanded }">
        <v-icon class="rotate-180">mdi-source-branch</v-icon>
        <span class="count-value">{{ count }}</span>
        <span v-if="expanded" class="text">{{ text }}</span>
    </article>
</template>
<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        props: {
            count: {
                type: Number,
                required: true,
            },
            expanded: {
                type: Boolean,
                required: true,
            },
        },
        computed: {
            text(): string {
                return this.$tc('global.subCase', this.count);
            },
        },
    });
</script>
<style scoped lang="scss">
    article {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;
        align-self: center;

        overflow: hidden;
        white-space: nowrap;
        user-select: none;

        .rotate-180 {
            transform: rotate(180deg) scaleX(-1);
        }

        .text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-transform: lowercase;
        }

        span {
            color: var(--Color-Neutrals-20, #545454);

            /* Body/Body M 400 */
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
        }
    }
</style>
