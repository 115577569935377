<template>
    <section class="mode-select-container">
        <RadioButton
            :title="$tc('widgets.bindQueues', 1)"
            :subTitle="$tc('widgets.bindQueuesInfo', 1)"
            :value="value"
            :radioValue="IdentifierModalStep.SINGLE_SELECT"
            @input="$emit('input', $event)"
            autofocus
        />
        <RadioButton
            :title="$tc('widgets.bindQueues', 2)"
            :subTitle="$tc('widgets.bindQueuesInfo', 2)"
            :value="value"
            :radioValue="IdentifierModalStep.MULTIPLE_SELECT"
            @input="$emit('input', $event)"
        />
    </section>
</template>
<script lang="ts">
    import RadioButton from './RadioButton.vue';
    import { IdentifierModalStep } from '@/types/widgets.types';
    import { defineComponent, PropType } from 'vue';

    export default defineComponent({
        components: {
            RadioButton,
        },
        props: {
            value: {
                type: Number as PropType<IdentifierModalStep>,
                required: true,
            },
        },
        data() {
            return {
                IdentifierModalStep,
            };
        },
    });
</script>
<style scoped>
    section.mode-select-container {
        display: flex;
        padding: 32px 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
    }
</style>
