<template>
    <div id="dialog-container">
        <transition name="fade">
            <div v-if="isVisible" class="overlay" @click="overlayClicked"></div>
        </transition>

        <dialog ref="dialog" :class="dialogClass">
            <section class="dialog-content">
                <div class="dialog-inner-container">
                    <div class="dialog-header">
                        <component v-if="header" :is="header" />
                        <h2 v-else>{{ title }}</h2>
                        <IconButton v-if="closeble" icon="mdi-close" @click="closed" />
                    </div>
                    <div class="dialog-body">
                        <component :is="component" v-bind="props" @close="closed" />
                    </div>
                </div>
            </section>
        </dialog>
    </div>
</template>

<script>
    import IconButton from '@/components/Main/Buttons/Icon.vue';

    export default {
        name: 'DialogContainer',

        components: {
            IconButton,
        },

        props: {
            props: {
                type: Object,
                default: () => ({}),
            },
        },

        data() {
            return {
                dialog: null,
                title: '',
                component: null,
                header: null,
                size: 'medium',
                isVisible: false, // Track overlay visibility
                closeble: true,
            };
        },

        computed: {
            dialogClass() {
                const size = this.size || 'medium';
                return `dialog-container dialog-${size}`;
            },
        },

        mounted() {
            this.dialog = this.$refs.dialog;
        },

        beforeDestroy() {
            this.removeListeners();
        },

        methods: {
            show({ title, component, header, size, closeble = true }) {
                this.populateDialog({ title, component, header, size, closeble });
                this.isVisible = true; // Show the overlay
                this.dialog.show();
                this.addListeners();

                return new Promise((resolve) => {
                    const cleanupAndResolve = (result) => {
                        this.removeListeners();
                        this.isVisible = false;
                        this.dialog.close();
                        resolve(result);
                    };

                    this.$once('closed', () => {
                        const result = { closed: true };
                        cleanupAndResolve(result);
                    });
                });
            },

            populateDialog({ title, component, header, size, closeble }) {
                this.title = title;
                this.component = component;
                this.size = size;
                this.closeble = closeble;
                this.header = header;
            },

            closed() {
                this.isVisible = false;
                this.$emit('closed');
            },

            overlayClicked() {
                if (this.closeble) {
                    this.closed();
                }
            },

            closeOnOutsideClick(event) {
                if (event.target === this.dialog && this.closeble) {
                    this.closed();
                }
            },

            handleEscape(event) {
                if (!this.closeble) return;
                if (event.key === 'Escape' || event.keyCode === 27) {
                    this.closed();
                }
            },

            addListeners() {
                document.addEventListener('mouseup', this.closeOnOutsideClick);
                document.addEventListener('keydown', this.handleEscape);
            },

            removeListeners() {
                document.removeEventListener('mouseup', this.closeOnOutsideClick);
                document.removeEventListener('keydown', this.handleEscape);
            },
        },
    };
</script>

<style scoped>
    .fade {
        transition: opacity 0.2s ease-in-out;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 99999;
    }

    .dialog-body {
        max-height: 70vh;
        overflow: hidden;
    }

    .dialog-container {
        width: 80vw;
        max-width: 560px;
        border: none;
        transform: translate(-50%, -50%);
        border-radius: 16px;
        top: 50%;
        left: 50%;
        box-shadow:
            0 4px 8px rgba(0, 0, 0, 0.1),
            0 2px 4px rgba(0, 0, 0, 0.06);

        border: 1px solid rgba(0, 0, 0, 0.2);
        z-index: 100000;
    }

    .dialog-small {
        width: 40vw;
        max-width: 400px;
    }

    .dialog-medium {
        width: 60vw;
        max-width: 560px;
    }

    .dialog-large {
        width: 80vw;
        max-width: 800px;
    }

    .dialog-full {
        width: 95vw;
        max-width: 1200px;
    }

    .dialog-container[open] {
        animation: show 0.2s ease-in-out normal;
    }

    .dialog-content {
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .dialog-inner-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .dialog-button-outer-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .dialog-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-weight: 400;
        font-size: 20px;
    }

    @keyframes show {
        from {
            transform: translate(-50%, -50%) scale(0.8);
            opacity: 0;
        }
        to {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }
    }
</style>
