import i18n from '@/i18n';
import Vue from 'vue';
import ConfirmationDialog from '@/services/dialogs/components/ConfirmationDialog.vue';
import ContainerDialog from '@/services/dialogs/components/ContainerDialog.vue';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import router from '@/router';

/**
 * Shows a confirmation dialog with the provided options.
 * This function can be called from anywhere in the application, without needing to import the ConfirmationDialog component.
 *
 * @param {object} options
 * @param {string} options.title - The title of the dialog.
 * @param {string} options.message - The message of the dialog.
 * @param {string} options.confirmText - The text of the confirm button.
 * @param {string} options.declineText - The text of the decline button.
 * @param {object[]} options.extraButtons - An array of objects representing extra buttons.
 * @param {string} options.extraButtons.text - The text of the extra button.
 * @param {string} options.extraButtons.class - The class of the extra button.
 * @param {string} options.extraButtons.action - The action to be executed when the extra button is clicked.
 * @returns {Promise<{ confirmed: boolean, declined: boolean, canceled: boolean, confirmedExtra: boolean }>} - Resolves to an object with the confirmation result.
 *
 * @note For refactoring to Vue 3, replace and use the following functions instead: createVNode, render, watch.
 */
export function showConfirmationDialog(options) {
    return new Promise((resolve) => {
        const container = document.createElement('div');
        container.id = 'confirmation-dialog-container';
        document.getElementById('app').append(container);

        const ConfirmationDialogConstructor = Vue.extend({
            i18n,
            extends: ConfirmationDialog,
        });

        const instance = new ConfirmationDialogConstructor();

        instance.$mount(container);
        instance.show(options);

        const remove = () => {
            instance.$destroy();
            const element = document.body.querySelector('#confirmation-dialog-container');
            if (element) element.remove();
        };

        instance.$on('confirmed', () => {
            resolve({ confirmed: true, declined: false, canceled: false, confirmedExtra: false });
            remove();
        });

        instance.$on('declined', () => {
            resolve({ confirmed: false, declined: true, canceled: false, confirmedExtra: false });
            remove();
        });

        instance.$on('canceled', () => {
            resolve({ confirmed: false, declined: false, canceled: true, confirmedExtra: false });
            remove();
        });

        instance.$on('confirmedExtra', () => {
            resolve({ confirmed: false, declined: false, canceled: false, confirmedExtra: true });
            remove();
        });
    });
}

/**
 *
 * @param {object} options - The options to be passed to the dialog.
 * @param {string} options.title - The title of the dialog.
 * @param {string} options.component - The component to be rendered in the dialog.
 * @param {string} options.header - The component to be rendered in the header of the dialog.
 * @param {object} options.size - The size of the dialog. Can be 'small', 'medium', 'large' or 'full'.
 * @param {boolean} options.closeble - Whether the dialog can be closed by clicking outside of it and showing a close button.
 * @param {boolean} options.props - The props to be passed to the component.
 * @returns
 */
export function showDialog(options) {
    return new Promise((resolve) => {
        const container = document.createElement('div');
        container.id = 'dialog-container';
        document.getElementById('app').append(container);

        const ContainerDialogConstructor = Vue.extend({
            router,
            vuetify,
            i18n,
            store,
            extends: ContainerDialog,
        });

        const instance = new ContainerDialogConstructor({
            propsData: {
                title: options.title,
                component: options.component,
                header: options.header,
                size: options.size,
                closeble: options.closeble,
                props: options.props,
            },
        });

        instance.$mount(container);
        instance.show(options);

        const remove = () => {
            instance.$destroy();
            const element = document.body.querySelector('#dialog-container');
            if (element) element.remove();
        };

        instance.$on('closed', () => {
            resolve({ closed: true });
            remove();
        });
    });
}
