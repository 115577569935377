<template>
    <!-- <v-menu v-model="showMenu" offset-x :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }"> -->
    <v-hover v-slot="{ hover }">
        <div class="py-2 px-3 d-flex" id="claim-list-item">
            <v-list-item-content>
                <v-list-item-title>{{ claim.claimObjectName }}</v-list-item-title>
                <v-list-item-subtitle>{{ claim.claimNumber }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <v-tooltip top v-if="hover">
                    <template #activator="{ on }">
                        <v-btn icon x-small @click.stop="openExternal" v-on="on">
                            <v-icon>mdi-open-in-new</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('openInI2i') }}</span>
                </v-tooltip>
            </v-list-item-action>
        </div>
    </v-hover>
    <!-- </template>
        <v-card class="pa-3">
            <v-card-title>{{ $t('claimDetails') }}</v-card-title>
            <v-card-text>
                <v-list dense>
                    <v-list-item>
                        <v-list-item-title>{{ $t('claimId') }}:</v-list-item-title>
                        <v-list-item-subtitle>{{ claim.claimId }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-title>{{ $t('claimNumber') }}:</v-list-item-title>
                        <v-list-item-subtitle>{{ claim.claimNumber }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-title>{{ $t('policyId') }}:</v-list-item-title>
                        <v-list-item-subtitle>{{ claim.policyId }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-title>{{ $t('claimStatus') }}:</v-list-item-title>
                        <v-list-item-subtitle>{{ claim.claimStatus.title }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-title>{{ $t('claimObjectName') }}:</v-list-item-title>
                        <v-list-item-subtitle>{{ claim.claimObjectName }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-title>{{ $t('claimDate') }}:</v-list-item-title>
                        <v-list-item-subtitle>
                            {{ new Date(claim.claimDate).toLocaleDateString() }}
                        </v-list-item-subtitle>
                    </v-list-item>
                </v-list>

                <template v-if="claim.expenses.length">
                    <v-divider class="my-3"></v-divider>
                    <v-card-title>{{ $t('expenseDetails') }}</v-card-title>
                    <v-list dense v-if="currentExpense">
                        <v-list-item>
                            <v-list-item-title>{{ $t('expenseDate') }}:</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ new Date(currentExpense.expenseDate).toLocaleDateString() }}
                            </v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>{{ $t('amount') }}:</v-list-item-title>
                            <v-list-item-subtitle>{{ currentExpense.amount }}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>{{ $t('compensation') }}:</v-list-item-title>
                            <v-list-item-subtitle>{{ currentExpense.compensation }}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>{{ $t('expenseStatus') }}:</v-list-item-title>
                            <v-list-item-subtitle>{{ currentExpense.expenseStatus.title }}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>{{ $t('clinic') }}:</v-list-item-title>
                            <v-list-item-subtitle>{{ currentExpense.clinic }}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>{{ $t('reason') }}:</v-list-item-title>
                            <v-list-item-subtitle>{{ currentExpense.reason }}</v-list-item-subtitle>
                        </v-list-item>
                    </v-list>
                    <template v-if="claim.expenses.length > 1">
                        <v-divider class="my-3"></v-divider>
                        <v-pagination
                            v-model="currentPage"
                            :length="claim.expenses.length"
                            circle
                            class="mt-2"
                        ></v-pagination>
                    </template>
                </template>
                <v-divider class="my-3"></v-divider>

                <v-btn :href="claim.href" target="_blank" color="primary" class="mt-2">{{ $t('viewClaim') }}</v-btn>
            </v-card-text>
        </v-card>
    </v-menu> -->
</template>

<script lang="ts">
    import { Claim } from '@/types/integrations/i2i.types';

    export default {
        props: {
            claim: {
                type: Object as () => Claim,
                required: true,
            },
        },
        data() {
            return {
                showMenu: false,
                currentPage: 1,
            };
        },
        computed: {
            currentExpense() {
                return this.claim.expenses[this.currentPage - 1];
            },
        },
        methods: {
            openExternal() {
                const url = this.claim.href;

                const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

                if (!isChrome) {
                    window.open('microsoft-edge:' + url, '_blank', 'width=10,height=10,left=-100,top=-100');
                } else {
                    window.open(url, '_blank', 'width=1400,height=800,left=100,top=100');
                }
            },
        },
    };
</script>

<style scoped></style>

<i18n lang="json">
{
    "en": {
        "claimDetails": "Claim details",
        "claimId": "Claim ID",
        "claimNumber": "Claim number",
        "policyId": "Policy ID",
        "claimStatus": "Claim status",
        "claimObjectName": "Claim object name",
        "claimDate": "Claim date",
        "expenseDetails": "Expense details",
        "expenseDate": "Expense date",
        "amount": "Amount",
        "compensation": "Compensation",
        "expenseStatus": "Expense status",
        "clinic": "Clinic",
        "reason": "Reason",
        "viewClaim": "View claim",
        "openInI2i": "Open in I2I"
    },
    "sv": {
        "claimDetails": "Skadedetaljer",
        "claimId": "Skade-ID",
        "claimNumber": "Skadenummer",
        "policyId": "Försäkrings-ID",
        "claimStatus": "Skadestatus",
        "claimObjectName": "Skadeobjektnamn",
        "claimDate": "Skadedatum",
        "expenseDetails": "Kostnadsdetaljer",
        "expenseDate": "Kostnadsdatum",
        "amount": "Belopp",
        "compensation": "Ersättning",
        "expenseStatus": "Kostnadsstatus",
        "clinic": "Klinik",
        "reason": "Anledning",
        "viewClaim": "Visa skada",
        "openInI2i": "Öppna i I2I"
    }
}
</i18n>
