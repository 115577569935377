<template>
    <div class="timePickerContainer">
        <div class="time">
            <input
                ref="hours"
                type="number"
                class="timeInput"
                :class="view == 'hours' ? 'selected' : ''"
                :value="hourSelected"
                @click="hourViewButtonClick()"
                @focus="view = 'hours'"
                @blur="updateHoursInput()"
                @input="hourInput"
            />

            <div class="separator">:</div>

            <input
                ref="minutes"
                type="number"
                class="timeInput"
                :class="view == 'minutes' ? 'selected' : ''"
                :value="minuteSelected"
                @click="minuteViewButtonClick"
                @focus="view = 'minutes'"
                @blur="updateMinutesInput()"
                @input="minuteInput"
            />
        </div>

        <div v-if="view == 'hours'" class="hours" cy-data="hourButtons">
            <button
                v-for="hourButton in hourButtonValues"
                :key="hourButton"
                class="timeButton"
                :class="hourButton == hourSelected ? 'disabled' : ''"
                @click="hourButtonClick(hourButton)"
            >
                {{ hourButton }}
            </button>
        </div>
        <div v-if="view == 'minutes'" class="minutes" cy-data="minuteButtons">
            <button
                v-for="minuteButton in minuteIterations"
                :key="minuteButton"
                class="timeButton"
                :class="minuteButton == minuteSelected ? 'disabled' : ''"
                @click="minuteButtonClick(minuteButton)"
            >
                {{ minuteButton }}
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            window: {
                default: 0,
                type: Number,
            },
            value: {
                default: '00:00',
                type: String,
            },
        },

        data() {
            return {
                view: 'hours',
                hourSelected: this.value.slice(0, 2),
                minuteSelected: this.value.slice(-2),
                timeSelected: this.value,
            };
        },

        computed: {
            hourButtonValues() {
                const hours = [];
                for (let i = 0; i < 24; i++) {
                    hours.push((i < 10 ? '0' : '') + i);
                }
                return hours;
            },
            minuteIterations() {
                const minutes = [];
                for (let i = 0; i < 12; i++) {
                    minutes.push((i * 5 < 10 ? '0' : '') + i * 5);
                }
                return minutes;
            },
        },

        watch: {
            window: {
                immediate: true,
                async handler() {
                    await this.$nextTick();
                    this.$refs.hours.blur();
                    await this.$nextTick();
                    this.$refs.hours.focus();
                },
            },
            timeSelected: {
                handler(newVal) {
                    this.$emit('input', newVal);
                },
            },
        },

        methods: {
            hourViewButtonClick() {
                this.updateMinutesInput();
                this.$refs.hours.focus();
            },

            minuteViewButtonClick() {
                this.updateHoursInput();
                this.$refs.minutes.focus();
            },

            hourInput() {
                if (this.$refs.hours.value.length == 2) {
                    this.updateHoursInput();
                    this.$refs.minutes.focus();
                }
                if (this.$refs.hours.value.length > 2) {
                    this.$refs.hours.value = this.$refs.hours.value.slice(-1);
                }
            },

            minuteInput() {
                if (this.$refs.minutes.value.length == 2) {
                    this.updateMinutesInput();
                }
                if (this.$refs.minutes.value.length > 2) {
                    this.$refs.minutes.value = this.$refs.minutes.value.slice(-1);
                }
            },

            hourButtonClick(hour) {
                this.$refs.minutes.focus();
                this.hourSelected = hour;
                this.timeSelected = this.hourSelected + ':' + this.timeSelected.slice(-2);
            },

            minuteButtonClick(minute) {
                this.minuteSelected = minute;
                this.timeSelected = this.timeSelected.slice(0, 2) + ':' + this.minuteSelected;
            },

            updateHoursInput() {
                this.hourSelected = ('0' + Math.max(Math.min(this.$refs.hours.value.slice(0, 2), 23), 0)).slice(-2);
                this.timeSelected = this.hourSelected + ':' + this.timeSelected.slice(-2);
            },

            updateMinutesInput() {
                this.minuteSelected = ('0' + Math.max(Math.min(this.$refs.minutes.value.slice(0, 2), 59), 0)).slice(-2);
                this.timeSelected = this.timeSelected.slice(0, 2) + ':' + this.minuteSelected;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .timePickerContainer {
        transition: all 2s;
    }

    .time {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        padding: 8px;
        background-color: var(--v-primary-base);
        border-radius: 4px;
    }

    .separator {
        color: white;
        font-size: 2.4rem;
        width: 16px;
        padding-bottom: 4px;
        text-align: center;
    }

    .timeInput::-webkit-outer-spin-button,
    .timeInput::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .timeInput {
        height: 64px;
        width: 100%;
        color: #fff;
        border-radius: 4px;
        font-size: 2.4rem;
        text-align: center;
        cursor: pointer;
        opacity: 0.5;
        transition: background-color 0.2s;
    }
    .timeInput.selected {
        opacity: 1;
    }
    .timeInput:hover {
        background-color: var(--v-primary-darken1);
    }
    .timeInput:focus-visible {
        outline: none;
    }
    .timeInput:focus {
        cursor: text;
    }

    .hours {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(6, 60px);
        gap: 4px;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .minutes {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, 60px);
        gap: 4px;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .timeButton {
        justify-self: center;
        align-self: center;
        background-color: var(--v-buttonNotSelected-base);
        border-radius: 4px;
        flex: 1;
        height: 100%;
        width: 100%;
        font-size: 1rem;
        color: var(--v-gray2-base);
        transition: background-color 0.2s;
    }

    .timeButton:hover {
        background-color: var(--v-buttonNotSelectedHover-base);
    }

    .timeButton.disabled {
        background-color: var(--v-primary-darken1);
        color: var(--v-buttonSelectedHover-base);
        cursor: default;
    }
</style>
