<template>
    <section>
        <SubCaseCard
            v-for="(subCase, index) in subCases"
            :item="subCase"
            :style="{ '--card-index': index }"
            @click.native="$emit('goToCase', subCase.caseId)"
        />
    </section>
</template>
<script lang="ts">
    import SubCaseCard from './SubCaseCard.vue';

    import { defineComponent, type PropType } from 'vue';
    import type { Case } from '@/types/cases.types';

    export default defineComponent({
        components: {
            SubCaseCard,
        },
        props: {
            subCases: {
                type: Array as PropType<Case[]>,
                default: () => [],
            },
        },
    });
</script>
<style scoped>
    section {
        position: relative;
        display: flex;
        padding: 2px 8px;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        align-self: stretch;

        overflow-y: scroll;

        /* Hide scrollbar for WebKit browsers */
        &::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for other browsers */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
</style>
