<template>
    <div class="selects-container">
        <template v-if="window === 0">
            <v-date-picker
                :key="1"
                ref="datePickerStart"
                v-model="dateStart"
                :first-day-of-week="1"
                :locale="$t('global.locale')"
                :label="$t('global.date')"
                :textFieldProps="{ dense: true, outlined: true }"
                @input="window = 1"
            />
        </template>

        <template v-else>
            <TimePicker v-model="timeStart" :window="window" />
        </template>

        <footer class="footer-buttons">
            <section class="time-buttons-list">
                <tertiary-button :disabled="previousDisabled" cy-data="dateView" @click="window = 0">
                    {{ $t('global.date') }}
                </tertiary-button>
                <tertiary-button :disabled="nextDisabled" cy-data="timeView" @click="window = 1">
                    {{ $t('global.time') }}
                </tertiary-button>
            </section>
            <primary-button class="save-button" cy-data="SLATimeSave" @click="save">
                {{ $t('global.btn.save') }}
            </primary-button>
        </footer>
    </div>
</template>

<script lang="ts">
    import { format } from 'date-fns';
    import TimePicker from './Time.vue';
    import { defineComponent } from 'vue';
    export default defineComponent({
        name: 'DateAndTimeSelect',

        components: {
            TimePicker,
        },

        props: {
            value: {
                type: Date,
                default: null,
            },
        },

        data() {
            return {
                window: 0,
                localDateTime: null as Date | null,
            };
        },

        computed: {
            dateStart: {
                get() {
                    return format(this.dateTime, 'yyyy-MM-dd');
                },
                set(value: string) {
                    this.dateTime = new Date(`${value}T${this.timeStart}`);
                },
            },
            timeStart: {
                get() {
                    return format(this.dateTime, 'HH:mm');
                },
                set(value: string) {
                    this.dateTime = new Date(`${this.dateStart}T${value}`);
                },
            },
            previousDisabled() {
                return this.window === 0;
            },
            nextDisabled() {
                return this.window === 1;
            },
            dateTime: {
                get() {
                    if (this.localDateTime) {
                        return this.localDateTime;
                    }
                    if (this.value) {
                        return this.value;
                    }
                    return new Date();
                },
                set(value: Date) {
                    this.localDateTime = value;
                },
            },
        },

        methods: {
            save() {
                this.$emit('input', this.dateTime);
            },
        },
    });
</script>

<style scoped>
    .window {
        min-height: 400px;
    }

    .footer-buttons {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .time-buttons-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 4px;
    }

    .save-button {
        flex: 1;
    }

    :focus {
        border: 1px solid var(--v-primary-base) !important;
    }
    .selects-container {
        display: flex;
        flex-direction: column;
        background-color: white;
        min-height: max-content;
    }
</style>

<!-- <i18n lang="json"></i18n> -->
