<template>
    <div ref="list-container" cy-data="caseList">
        <ProgressLoader />
        <!-- title="Tilldela" -->
        <ConfirmationDialog
            v-model="confirmDialog"
            :dialogStatus="confirmModal"
            :title="$t('listView.assign')"
            :body="confirmBody"
            @ConfirmResult="(v) => (v ? addCaseToYouAccept() : (confirmModal = false))"
        />

        <Headers class="mb-2" />

        <div v-if="cases?.length !== 0" class="cases-list">
            <TransitionGroup name="move" tag="div" :css="false">
                <div v-for="(item, index) in cases" :key="item.caseId" class="list-item">
                    <ListItem :item="item" :index="index" />
                </div>
            </TransitionGroup>
        </div>

        <div v-else class="cases-list">
            <article class="no-cases" cy-data="no cases">
                <span>
                    {{ $t('listView.noCasesTitle') }}
                </span>
                <span>
                    {{ $t('listView.noCasesText') }}
                </span>
            </article>
        </div>

        <div class="list-navigation">
            <div class="bottom-item left"><ListSettingsMenu /></div>
            <div class="bottom-item">
                <secondary-action-button v-if="isSearching" class="no-live-updates">
                    {{ $t('listView.noLiveUpdates') }}
                </secondary-action-button>
            </div>
            <div class="bottom-item right"><ListPagination /></div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import { getDateFormat } from '@/utils/Design';
    import ConfirmationDialog from '@/components/Global/ConfirmationDialog.vue';
    import ListPagination from '@/components/Cases/List/ListPagination.vue';
    import Headers from '@/components/Cases/List/Headers.vue';
    import ListSettingsMenu from '@/components/Cases/List/ListSettingsMenu.vue';
    import ProgressLoader from '@/components/Cases/ProgressLoader.vue';
    import ListItem from '@/components/Cases/List/ListItem.vue';

    export default {
        components: {
            ConfirmationDialog,
            ListPagination,
            Headers,
            ListSettingsMenu,
            ProgressLoader,
            ListItem,
        },
        data() {
            return {
                loading: false,
                timeInterval: null,
                caseToAdd: null,
                confirmModal: false,
                confirmDialog: false,
                confirmBody: this.$t('listView.assignCase'),
            };
        },
        computed: {
            ...mapState({
                listAddAnimation: (state) => state.Cases.listAddAnimation,
                showSelect: (state) => state.Cases.showSelect,
                selectedCaseId: (state) => state.Cases.selectedCaseId,
                caseFiltersSelectedItems: (state) => state.Cases.caseFiltersState,
                totalCases: (state) => state.Cases.totalCases,
                listLayout: (state) => state.Cases.listLayout,
                loggedInUser: (state) => state.Auth.userObject,
                cases: (state) => state.Cases.cases,
                userSettings: (state) => state.System.userSettings,
                inQueueTime: (state) => state.Cases.inQueueTime,
            }),

            isSearching() {
                return this.caseFiltersSelectedItems.searchQuery !== '';
            },

            itemHeight() {
                switch (this.listLayout.value) {
                    case 'big': {
                        return '80px';
                    }
                    case 'small': {
                        return '50px';
                    }
                    default: {
                        return '80px';
                    }
                }
            },
        },

        created() {
            this.checkIfQueueTimerIsActive();
        },
        mounted() {
            window.addEventListener('resize', this.resize);
            this.$nextTick(() => {
                this.resize();
            });
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.resize);
        },
        destroyed() {
            clearInterval(this.timeInterval);
        },

        methods: {
            ...mapActions('Cases', {
                updateCasesListHeaders: 'updateCasesListHeaders',
            }),

            async checkIfQueueTimerIsActive() {
                // Special case when we want a timer in the gui
                if (this.userSettings.cases.inQueueTimer.active) {
                    await this.$store.dispatch('Cases/setInQueueTimer');
                }
                const IN_QUEUE_INTERVAL = 1000;
                this.timeInterval = setInterval(() => {
                    this.$store.commit('Cases/INCREASE_IN_QUEUE_TIMER');
                }, IN_QUEUE_INTERVAL);
            },

            getDateFormat,
            dispatchCheckToStore(isChecked, item) {
                this.$store.dispatch('Cases/setCaseCheckbox', { isChecked, item });
            },

            addCaseToYou(item) {
                this.confirmModal = true;
                this.caseToAdd = item;
            },
            addCaseToYouAccept() {
                // this should be moved to the store and be a mutation
                const id = this.caseToAdd.CaseID;
                const allCases = this.cases;
                const caseToAdd = allCases.find((item) => item.CaseID === id);

                const payload = {
                    caseId: id,
                    changed: {
                        userId: this.loggedInUser.userId,
                    },
                };

                this.$store.dispatch('Cases/updateCase', { payload });
                this.confirmModal = false;
                this.goToCase(this.caseToAdd);

                //* Divert conversation AKA cherryPicking straight into queueManager
                if (this.caseToAdd.InternalType === 'call' && caseToAdd.Status === 'InQueue') {
                    const divertPayload = {
                        userId: this.loggedInUser.userId,
                        caseId: id,
                        internalType: this.caseToAdd.InternalType,
                    };
                    this.$store.dispatch('Cases/divertConversation', divertPayload);
                }
            },

            returnDateNow() {
                return new Date();
            },

            goToCase(payload) {
                if (this.showSelect) {
                    this.isChecked = !this.isChecked;
                    return;
                }
                this.storeGoToCase(payload);
            },

            resize() {
                const width = this.$refs['list-container'].offsetWidth;
                if (!width) return;
                this.updateCasesListHeaders(width);
            },
        },
    };
</script>

<style scoped>
    .list-navigation {
        display: flex;
        justify-content: space-between;
        padding-top: 8px;
        padding-bottom: 16px;
    }
    .cases-list {
        min-height: 128px;
    }
    .no-live-updates {
        pointer-events: none;
    }

    .no-cases {
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .no-cases > :first-child {
        font-size: 1.2rem;
        color: var(--v-gray4-base);
        text-align: center;
    }

    .no-cases > :nth-child(2) {
        font-size: 0.9rem;
        color: var(--v-gray2-base);
        text-align: center;
    }

    .bottom-item {
        min-width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bottom-item.left {
        justify-content: flex-start;
    }

    .bottom-item.right {
        justify-content: flex-end;
    }

    [v-cloak] {
        display: none;
    }

    .move-enter-active,
    .move-leave-active,
    .move-move {
        transition:
            opacity 0.5s,
            transform 0.5s;
    }

    .move-enter,
    .move-leave-to {
        opacity: 0;
        transform: translateY(20px);
    }

    .move-move {
        transform: translateY(0);
    }
</style>

<i18n lang="json">
{
    "en": {
        "listView": {
            "noCasesTitle": "There are currently no cases to display with the current filters.",
            "noCasesText": "Please go up to the left to change the filters to see if you can find the case you are looking for.",
            "assign": "Assign",
            "dateTime": "Date / Time",
            "forYou": "For You",
            "noOutcome": "No outcome",
            "assignCase": "Do you want to assign the case to yourself and open it?",
            "couldNotOpen": "Could not open case",
            "noLiveUpdates": "No live updates while searching in the list"
        }
    },
    "sv": {
        "listView": {
            "noCasesTitle": "Det finns för närvarande inga ärenden att visa med de aktuella filtren.",
            "noCasesText": "Gå upp till vänster för att ändra filtren för att se om du kan hitta ärendet du letar efter.",
            "assign": "Tilldela",
            "dateTime": "Datum / Tid",
            "forYou": "Till dig",
            "noOutcome": "Inget utfall",
            "assignCase": "Vill du tilldela ärendet till dig och öppna det?",
            "couldNotOpen": "Kunde inte öppna ärende",
            "noLiveUpdates": "Inga liveuppdateringar vid sökning i listan"
        }
    }
}
</i18n>
