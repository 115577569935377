<template>
    <button @click="toggle" :class="{ hide }">
        <i class="mdi" :class="arrowIcon" />
    </button>
</template>
<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        props: {
            value: {
                type: Boolean,
                required: true,
            },
            hide: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            arrowIcon(): string {
                return this.value ? 'mdi-chevron-right' : 'mdi-chevron-left';
            },
        },
        methods: {
            toggle(): void {
                this.$emit('input', !this.value);
            },
        },
    });
</script>
<style scoped lang="scss">
    button {
        display: flex;
        width: 24px;
        height: 24px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;

        border-radius: var(--Corner-Radius-Corner-Radius-Small, 4px);
        border: 1px solid var(--Color-Neutrals-30, #737373);
        background: var(--Color-Neutrals-100, #fff);

        i {
            font-size: 18px;
        }
    }

    .hide {
        display: none;
    }
</style>
