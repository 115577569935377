import type { TranslateResult from 'vue-i18n';
<template>
    <div>
        <slot name="label">
            <label v-if="label" :for="name" :class="requiredAsterisk && 'asterisk'">{{ label }}</label>
        </slot>
        <input
            :id="name"
            :name="name"
            :value="value"
            :aria-label="label"
            v-bind="$attrs"
            @input="$emit('input', $event.target.value)"
            @focus="$emit('focus', $event)"
            @blur="$emit('blur', $event)"
            @change="$emit('change', $event)"
        />
        <div v-if="validationError" class="validation-error">{{ validationError }}</div>
    </div>
</template>

<script lang="ts">
    import { TranslateResult } from 'vue-i18n';
    import { defineComponent, PropType } from 'vue';

    export default defineComponent({
        inheritAttrs: false,
        props: {
            value: {
                type: String,
                default: '',
            },
            label: {
                type: String as PropType<string | TranslateResult>,
                default: '',
            },
            name: {
                type: String,
                required: true,
            },
            requiredAsterisk: {
                type: Boolean,
                default: false,
            },
            validationError: {
                type: String,
                default: '',
            },
        },
    });
</script>

<style scoped lang="scss">
    input,
    label {
        display: block;
    }

    label {
        color: black;

        /* Body/Body S 600 */
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        margin-bottom: 4px;
    }

    input {
        border-radius: 0.25em;
        border: 1px solid rgba(66, 66, 66, 0.5);
        padding: 0.5em;
        width: 100%;
        height: 40px;

        overflow: hidden;
        // color: var(--Color-Neutrals-60, #bfbfbf);
        text-overflow: ellipsis;

        /* Body/Body M 400 */
        font-family: Roboto;
        font-size: 16px;
        color: var(--Color-Neutrals-0, #000);
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */

        &:focus {
            border: 1px solid black;
        }

        &::placeholder {
            color: var(--Color-Neutrals-60, #bfbfbf);
        }
    }

    input:focus-visible {
        outline: none;
    }

    label.asterisk:has(+ input[required]):after {
        content: ' *';
        color: red;
    }

    input:disabled {
        opacity: 0.5;
    }

    .validation-error {
        margin-top: 8px;
        color: var(--Color-Danger-50, #e61a1a);

        /* Body/Body S 400 */
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }
</style>
