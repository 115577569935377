<template>
    <!-- <v-menu v-model="showMenu" offset-x>
        <template v-slot:activator="{ on, attrs }"> -->
    <v-hover v-slot="{ hover }">
        <div class="py-2 px-3 d-flex" id="quote-list-item">
            <v-list-item-content>
                <v-list-item-title>{{ quote.agreementName }}</v-list-item-title>
                <v-list-item-subtitle>{{ quote.agreementNumber }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <v-tooltip top v-if="hover">
                    <template #activator="{ on }">
                        <v-btn icon x-small @click.stop="openExternal" v-on="on">
                            <v-icon>mdi-open-in-new</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('openInI2i') }}</span>
                </v-tooltip>
            </v-list-item-action>
        </div>
    </v-hover>
    <!-- </template> -->
    <!-- <v-card class="pa-2" style="min-width: 250px">
            <v-card-title class="text-h6 font-weight-bold">{{ $t('quote.details') }}</v-card-title>
            <v-card-text>
                <div>
                    <strong>{{ $t('quote.agreementName') }}:</strong>
                    {{ quote.agreementName }}
                </div>
                <div>
                    <strong>{{ $t('quote.agreementNumber') }}:</strong>
                    {{ quote.agreementNumber }}
                </div>
                <div>
                    <strong>{{ $t('quote.quoteNumber') }}:</strong>
                    {{ quote.quoteNumber }}
                </div>
                <div>
                    <strong>{{ $t('quote.insurancePeriod') }}:</strong>
                    {{ quote.insuredFrom }} - {{ quote.insuredTo || $t('ongoing') }}
                </div>
                <div v-if="quote.annualPremium">
                    <strong>{{ $t('quote.annualPremium') }}:</strong>
                    {{ quote.annualPremium }}
                </div>
                <div v-if="quote.premium">
                    <strong>{{ $t('quote.premium') }}:</strong>
                    {{ quote.premium }}
                </div>
                <v-divider class="my-2"></v-divider>
                <v-card-subtitle class="text-h6 font-weight-bold">{{ $t('quote.insuredAnimal') }}</v-card-subtitle>
                <div>
                    <strong>{{ $t('animal.name') }}:</strong>
                    {{ quote.insured.animalName }}
                </div>
                <div>
                    <strong>{{ $t('animal.animalType') }}:</strong>
                    {{ quote.insured.animalType }}
                </div>
                <div>
                    <strong>{{ $t('animal.type') }}:</strong>
                    {{ quote.insured.type }}
                </div>
                <div>
                    <strong>{{ $t('animal.chipNumber') }}:</strong>
                    {{ quote.insured.chipNumber }}
                </div>
                <div>
                    <strong>{{ $t('animal.registrationNumber') }}:</strong>
                    {{ quote.insured.registrationNumber }}
                </div>
                <div>
                    <strong>{{ $t('animal.dateOfBirth') }}:</strong>
                    {{ quote.insured.birth }}
                </div>
                <v-divider class="my-2"></v-divider>
                <v-card-subtitle class="text-h6 font-weight-bold">{{ $t('quote.quoteStatus') }}</v-card-subtitle>
                <div>
                    <strong>{{ $t('quote.quoteStatus') }}:</strong>
                    {{ quote.quoteStatus.title }} ({{ quote.quoteStatus.value }})
                </div>
                <div v-if="quote.quoteSubstatus">
                    <strong>{{ $t('quote.subStatus') }}:</strong>
                    {{ quote.quoteSubstatus.title }} ({{ quote.quoteSubstatus.value }})
                </div>
            </v-card-text>
        </v-card> -->
    <!-- </v-menu> -->
</template>

<script lang="ts">
    export default {
        components: {},
        props: {
            quote: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                showMenu: false,
            };
        },
        methods: {
            openExternal() {
                const url = this.quote.href;

                const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

                if (!isChrome) {
                    window.open('microsoft-edge:' + url, '_blank', 'width=10,height=10,left=-100,top=-100');
                } else {
                    window.open(url, '_blank', 'width=1400,height=800,left=100,top=100');
                }
            },
        },
    };
</script>

<style scoped></style>

<i18n lang="json">
{
    "en": {
        "openInI2i": "Open in I2I",
        "quote": {
            "details": "Quote details",
            "agreementName": "Agreement name",
            "agreementNumber": "Agreement number",
            "quoteNumber": "Quote number",
            "insurancePeriod": "Insurance period",
            "ongoing": "Ongoing",
            "annualPremium": "Annual premium",
            "premium": "Premium",
            "insuredAnimal": "Insured animal",
            "quoteStatus": "Quote status",
            "subStatus": "Status"
        },
        "animal": {
            "dateOfBirth": "Date of birth",
            "name": "Name",
            "animalType": "Animal type",
            "type": "Type",
            "chipNumber": "Chip number",
            "registrationNumber": "Registration number"
        }
    },
    "sv": {
        "openInI2i": "Öppna i I2I",
        "quote": {
            "details": "Offertinformation",
            "agreementName": "Avtalnamn",
            "agreementNumber": "Avtalsnummer",
            "quoteNumber": "Offertnummer",
            "insurancePeriod": "Försäkringsperiod",
            "ongoing": "Pågående",
            "annualPremium": "Årspremie",
            "premium": "Premie",
            "insuredAnimal": "Försäkrat djur",
            "quoteStatus": "Offertstatus",
            "subStatus": "Status"
        },
        "animal": {
            "dateOfBirth": "Födelsedatum",
            "name": "Namn",
            "animalType": "Djurtyp",
            "type": "Typ",
            "chipNumber": "Chipnummer",
            "registrationNumber": "Registreringsnummer"
        }
    }
}
</i18n>
